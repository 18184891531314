const ImageSize = ({ height, changeHeight, width, changeWidth }) => {
  return (
    <div className="rdw-image-modal-size">
      &nbsp;↕&nbsp;
      <input
        id="height"
        type="number"
        value={height}
        placeholder="Height"
        className="rdw-image-modal-size-input"
        onChange={(e) => changeHeight(e.target.value)}
      />
      <span className="rdw-image-mandatory-sign">*</span>&nbsp;↔&nbsp;
      <input
        id="width"
        type="number"
        value={width}
        placeholder="Width"
        className="rdw-image-modal-size-input"
        onChange={(e) => changeWidth(e.target.value)}
      />
      <span className="rdw-image-mandatory-sign">*</span>
    </div>
  );
};

export default ImageSize;
