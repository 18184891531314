import { convertToHtml } from "../functions/functions";
import { axiosHTTP } from "../Hook/UserAuthorization";

export class TodoData {
  async getUserTodo() {
    const post = await axiosHTTP.get(`/v1/user-todo/`);
    return post.data;
  }

  async getAllTodo({ todoStatus, assignedTo, tags, ...value }) {
    const post = await axiosHTTP.get(`/v1/todo/`, {
      params: {
        tag: tags,
        status: todoStatus,
        search: value.title,
        assigned_to: assignedTo,
        frequency: value.frequency,
        end_date: value.endDate || null,
        start_date: value.startDate || null,
        due_date_null: !!value.includeWithoutDueDate,
      },
    });
    return post.data;
  }

  async getTodoInfo(id) {
    const post = await axiosHTTP.get(`/v1/todo/${id}/`);
    return post.data;
  }

  async deleteTodo(id, type) {
    const post = await axiosHTTP.delete(`/v1/todo-action/`, {
      params: { id: id, delete_type: type || "current" },
    });
    return post.data;
  }

  async postTodo({ recurring, ...value }) {
    const post = await axiosHTTP.post(
      `/v1/todo-action/`,
      JSON.stringify({
        title: value.title,
        remind: value.remindUser,
        notes: value.notes || null,
        assigned_to: value.assignTo,
        due_date: value.dueDate || null,
        due_time: value.dueTime || null,
        priority: value.priority || null,
        template: value.template || undefined,
        reminder_days: value.reminderDays || 0,
        tag: value.tags ? value.tags.split(",") : [],
        recurring: recurringValue(null, recurring) || undefined,
        completed_notification_user: value.completedNotify || null,
        description:
          convertToHtml(value.description).length > 8
            ? convertToHtml(value.description)
            : null,
      })
    );
    return post.data;
  }

  async putTodo(id, values, todo, { user_id }, rec) {
    const { recurring, ...value } = values;
    const { completed_date, company, created_by } = todo;
    const payload = JSON.stringify({
      id: id,
      title: value.title,
      company: company.id,
      remind: value.remindUser,
      created_by: created_by.id,
      notes: value.notes || null,
      assigned_to: value.assignTo,
      due_date: value.dueDate || null,
      due_time: value.dueTime || null,
      priority: value.priority || null,
      reminder_days: value.reminderDays || 0,
      update_type: value.typeUpdate || "current",
      tag: value.tags ? value.tags.split(",") : [],
      ...markComplete(value, completed_date, user_id),
      recurring: recurringValue(rec, recurring) || undefined,
      completed_notification_user: value.completedNotify || null,
      description:
        convertToHtml(value.description).length > 8
          ? convertToHtml(value.description)
          : null,
    });
    const post = await axiosHTTP.put(`/v1/todo-action/`, payload);
    return post.data;
  }

  async addTodoAttachment(id, { file, description }) {
    const formData = new FormData();
    formData.append("todo", id);
    formData.append("file", file);
    formData.append("title", description);

    const put = await axiosHTTP.post(`/v1/todo-attachment/`, formData, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
    });
    return put.data;
  }

  async deleteTodoAttachment(id) {
    const post = await axiosHTTP.delete(`/v1/todo-attachment/${id}/`);
    return post.data;
  }

  async editTodoNote(id, value) {
    const post = await axiosHTTP.patch(
      `/v1/todo/${id}/`,
      JSON.stringify({ notes: value })
    );
    return post.data;
  }

  async completeTodo(id, userId) {
    const post = await axiosHTTP.patch(
      `/v1/todo/${id}/`,
      JSON.stringify({
        completed: true,
        complete_by: userId,
        completed_date: new Date(),
      })
    );
    return post.data;
  }

  async unCompleteTodo(id) {
    const post = await axiosHTTP.patch(
      `/v1/todo/${id}/`,
      JSON.stringify({
        completed: false,
        complete_by: null,
        completed_date: null,
      })
    );
    return post.data;
  }

  //template todo
  async getTemplateTodo(search) {
    const post = await axiosHTTP.get(`/v1/todo-template/`, {
      params: { search: search },
    });
    return post.data;
  }

  async getTodoTemplateInfo(id) {
    const post = await axiosHTTP.get(`/v1/todo-template/${id}/`);
    return post.data;
  }

  async deleteTodoTemplate(id) {
    const post = await axiosHTTP.delete(`/v1/todo-template/${id}/`);
    return post.data;
  }

  async postTodoTemplate({ recurring, ...value }) {
    const splitType = (value) => {
      const type = ["quarterly", "semi-annually", "annually"].includes(value);
      return type ? "-" : ",";
    };
    const post = await axiosHTTP.post(
      `/v1/todo-template/`,
      JSON.stringify({
        title: value.title,
        remind: value.remindUser,
        notes: value.notes || null,
        priority: value.priority || null,
        department: value.department || null,
        reminder_days: value.reminderDays || 0,
        description:
          convertToHtml(value.description).length > 8
            ? convertToHtml(value.description)
            : null,
        recurring: {
          frequency: recurring.frequency || null,
          recurring_due_time: recurring.dueTime || null,
          dates: recurring?.dates
            ? recurring?.dates?.split(splitType(recurring.frequency))
            : null,
        },
      })
    );
    return post.data;
  }

  async putTodoTemplate(id, { recurring, ...value }) {
    const splitType = (value) => {
      const type = ["quarterly", "semi-annually", "annually"].includes(value);
      return type ? "-" : ",";
    };
    const put = await axiosHTTP.put(
      `/v1/todo-template/${id}/`,
      JSON.stringify({
        title: value.title,
        remind: value.remindUser,
        notes: value.notes || null,
        priority: value.priority || null,
        department: value.department || null,
        reminder_days: value.reminderDays || 0,
        description:
          convertToHtml(value.description).length > 8
            ? convertToHtml(value.description)
            : null,
        recurring: {
          frequency: recurring.frequency || null,
          recurring_due_time: recurring.dueTime || null,
          dates: recurring?.dates
            ? recurring?.dates?.split(splitType(recurring.frequency))
            : null,
        },
      })
    );
    return put.data;
  }

  async addTodoTemplateAttachment(id, { file, description }) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("template", id);
    formData.append("title", description);

    const put = await axiosHTTP.post(`/v1/todo-attachment/`, formData, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
    });
    return put.data;
  }
}

const recurringValue = (obj1, obj2) => {
  const splitType = (value) => {
    const type = ["quarterly", "semi-annually", "annually"].includes(value);
    return type ? "-" : ",";
  };
  const values = {
    id: obj1?.id,
    frequency: obj2.frequency,
    recurring_due_time: obj2.dueTime || null,
    dates: obj2?.dates ? obj2?.dates?.split(splitType(obj2.frequency)) : null,
  };

  if (obj1) return !areObjectsEqual(obj2, obj1) && values;
  else return obj2.frequency && values;
};

const areObjectsEqual = (obj1, obj2) => {
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) return false;
  }
  for (const key in obj2) {
    if (obj2[key] !== obj1[key]) return false;
  }
  return true;
};

const markComplete = ({ isComplete }, completed_date, user_id) =>
  isComplete === !!completed_date
    ? {}
    : isComplete
    ? {
        completed: true,
        complete_by: user_id,
        completed_date: new Date(),
      }
    : {
        completed: false,
        complete_by: null,
        completed_date: null,
      };
