import * as Yup from "yup";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  BsFileText,
  BsFillCameraVideoFill,
  BsCheckSquare,
  BsFileEarmark,
  BsUiRadios,
  BsUiChecks,
  BsStar,
} from "react-icons/bs";
import { IoTextOutline } from "react-icons/io5";
import { ImVolumeLow } from "react-icons/im";
import { FiEdit, FiFileText } from "react-icons/fi";
import { RiSurveyLine } from "react-icons/ri";
import { ErrorMessage } from "formik";
import { contentIdForChapter, surveyFieldsTypes } from ".";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { userCurrentDealer, userLocalStorage } from "../storage/local";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  IoNotificationsOffOutline,
  IoNotificationsOutline,
} from "react-icons/io5";

export const parseSeconds = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds / 60) % 60);
  const seconds = totalSeconds % 60;

  const hour = hours.toString().padStart(2, "0");
  const min = minutes.toString().padStart(2, "0");
  const sec = seconds.toString().padStart(2, "0");

  const result = `${hour !== "00" ? hour + "H " : ""}${
    min !== "00" ? min + "M " : ""
  }${sec !== "00" ? sec + "S" : "00S"}`;
  return result;
};

export const format = (number) => {
  return {
    currency: new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(number),
    number: new Intl.NumberFormat("en-us", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number),
    number0: new Intl.NumberFormat("en-us", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number),
    percent: new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number / 100),
  };
};

export const reminderGenerate = (date, time) => date + "T" + time + ":00Z";

export const timeGenerate = (value) => {
  const hours = [...Array(24).keys()].map((hour) =>
    hour < 10 ? `0${hour}` : `${hour}`
  );

  const arrayHours = hours.slice(7, 22).flatMap((hour) => {
    const time1 = moment(`${hour}:00`, "HH:mm");
    const time2 = moment(`${hour}:15`, "HH:mm");
    const time3 = moment(`${hour}:30`, "HH:mm");
    const time4 = moment(`${hour}:45`, "HH:mm");
    return [
      { value: time1.format("HH:mm"), label: time1.format("LT") },
      value && { value: time2.format("HH:mm"), label: time2.format("LT") },
      { value: time3.format("HH:mm"), label: time3.format("LT") },
      value && { value: time4.format("HH:mm"), label: time4.format("LT") },
    ].filter(Boolean);
  });
  value ? arrayHours.splice(arrayHours.length - 3, 3) : arrayHours.pop();
  return arrayHours;
};

export const formatDate = (date, formate) =>
  typeof date === "string"
    ? moment.utc(date).format(formate)
    : date !== null
    ? moment(date[0]).format(formate)
    : null;

export const arrayForSelect = (arr, label, value) => {
  const values = value.split(".");
  const labels = label.split(".");

  return arr.map((e) => ({
    label:
      label === "user.full_name"
        ? userName(e.user)
        : getNestedProperty(e, labels),
    value: getNestedProperty(e, values),
  }));
};

const getNestedProperty = (obj, properties) =>
  properties.reduce((acc, property) => acc[property], obj);

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  if (name === "") return value.split(";");
};

export const openInNewTab = (url) =>
  window.open(url, "_blank", "noopener,noreferrer");

export const userLevelInfo = (value) => {
  const mid = value === 2 || value === 3 ? "#754FFE" : "#DBD8E9";
  const top = value === 3 ? "#754FFE" : "#DBD8E9";
  const statusUser =
    value === 1 ? "Beginner" : value === 2 ? "Intermediate" : "Advance";

  return (
    <OverlayTrigger
      delay={500}
      overlay={<Tooltip placement="top">{statusUser}</Tooltip>}
    >
      <svg className="svg-info-component">
        <rect x="3" y="8" width="2" height="6" rx="1" fill="#754FFE" />
        <rect x="7" y="5" width="2" height="9" rx="1" fill={mid} />
        <rect x="11" y="2" width="2" height="12" rx="1" fill={top} />
      </svg>
    </OverlayTrigger>
  );
};

export const statusTasks = (e) =>
  e.rating_date !== null &&
  (e.rating && e.goal) !== null &&
  Number(e.rating) < Number(e.goal) ? (
    <div className="failed">Failed</div>
  ) : e.rating_date !== null &&
    (e.rating && e.goal) !== null &&
    Number(e.rating) >= Number(e.goal) ? (
    <div className="passed">Passed</div>
  ) : e.completion_date !== null &&
    e.rating_date !== null &&
    (e.rating || e.goal) === null ? (
    <div className="passed">Rated</div>
  ) : e.completion_date === null ? (
    <div className="open">Open</div>
  ) : e.completion_date !== null ? (
    <div className="completed">Completed</div>
  ) : null;

export const iconsToContentCourse = (e) => (
  <div className="mb-1">
    {e === contentIdForChapter.audio ? (
      <ImVolumeLow />
    ) : e === contentIdForChapter.documents ? (
      <BsFileText />
    ) : e === contentIdForChapter.quiz ? (
      <FiEdit />
    ) : e === contentIdForChapter.text ? (
      <FiFileText />
    ) : e === contentIdForChapter.video ? (
      <BsFillCameraVideoFill />
    ) : e === contentIdForChapter.survey ? (
      <RiSurveyLine />
    ) : null}
  </div>
);

export const statusOfChapter = ({ is_published }) => (
  <>
    {is_published ? (
      <div className="content-status bg-success">
        <BsCheckSquare /> <span>Published</span>
      </div>
    ) : (
      <div className="content-status bg-info">
        <BsFileEarmark /> <span>Draft</span>
      </div>
    )}
  </>
);

export const filterUsersAlphabetical = (arr, bucket) =>
  arr
    .filter((e) => (bucket === true ? e : e.user.first_name !== "Bucket:"))
    .sort(
      (a, b) =>
        a.user.first_name.localeCompare(b.user.first_name) ||
        a.user.last_name.localeCompare(b.user.last_name)
    );

export const removeCookie = () => {
  userCurrentDealer.out();
  userLocalStorage.out();
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

export const errorMessageForm = (name) => (
  <div className="text-danger" id={name === "file" ? undefined : name}>
    <ErrorMessage name={name} />
  </div>
);

export const yupValidation = () => {
  return {
    onlyNumber: () =>
      Yup.string().matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "only number"
      ),
    url: (required) =>
      required
        ? Yup.string().matches(
            /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\\/]))?/
          )
        : Yup.string()
            .matches(
              /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\\/]))?/,
              "Enter correct url!"
            )
            .required("Enter video url"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Please fill in field"),
    password: Yup.string()
      .min(3, "Password should be of minimum 3 characters length")
      .required("Please fill in field"),
    confirmPassword: Yup.string().min(3).required("Please fill in this field"),
    phone: Yup.string().matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    ),
    draftHtml: Yup.mixed().test(
      "min-length",
      "This field cant be blank",
      (value) => {
        const rawContentState = convertToRaw(value.getCurrentContent());
        const text = draftToHtml(rawContentState)
          .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
          .trim();
        return text.length >= 1;
      }
    ),
    file: (size, error) =>
      Yup.mixed().test(
        "fileSize",
        `File size too large, Max size ${error}`,
        (value) => !value || value.size <= size
      ),
    fileType: (supportedTypes, errorMessage) =>
      Yup.mixed().test("fileType", errorMessage, (value) => {
        if (typeof value === "string") return true;
        if (!value) return true;
        const fileType = value.type;
        return supportedTypes.some((format) => fileType.startsWith(format));
      }),
    fileExtension: (supportedExtension, errorMessage) =>
      Yup.mixed().test("fileExtension", errorMessage, (value) => {
        if (typeof value === "string") return true;
        if (!value) return true;
        const fileExtension = value.type;
        return supportedExtension.some((format) =>
          fileExtension.endsWith(format)
        );
      }),
  };
};

export const sorting = {
  active: (x, y) => Number(y.is_active) - Number(x.is_active),
  byParams: (e, params) =>
    e.sort((a, b) =>
      getNestedProperty(a, params.split(".")).localeCompare(
        getNestedProperty(b, params.split("."))
      )
    ),
  orderId: (a, b) => a.orderid - b.orderid,
};

export const sort = {
  byActive: (array) =>
    array.sort((a, b) => Number(b.is_active) - Number(a.is_active)),
  byParam: (array, param) => {
    const paramValue = (value) => getNestedProperty(value, param.split("."));
    return array.sort((a, b) => paramValue(a).localeCompare(paramValue(b)));
  },
  byOrder: (array) => array.sort((a, b) => a.orderid - b.orderid),
};

export const activeStatus = (e) =>
  e ? (
    <div className="passed">Active</div>
  ) : (
    <div className="failed">Inactive</div>
  );

export const lockedStatus = (locked, active) =>
  locked ? <div className="failed">Locked</div> : activeStatus(active);

export const maxOrderId = (array) =>
  array.length !== 0
    ? array.map((e) => e.orderid).reduce((a, b) => Math.max(a, b), -Infinity)
    : 0;

export const minOrderId = (array) =>
  array.length !== 0
    ? array.map((e) => e.orderid).reduce((a, b) => Math.min(a, b))
    : 0;

export const arrayIds = (arr) =>
  arr.map((e) => (!e.page ? e.id : e.page.map((x) => x.id))).flat();

export const searchDataReports = (value, type) => {
  if (type === "multi") {
    const sortValue = value.split(",").sort((a, b) => a - b);
    return value ? sortValue.join("") : null;
  } else if (type === "range") {
    const isValidValue = (val) =>
      val === null || val === undefined || val === "";

    return !isValidValue(value.min) || !isValidValue(value.max)
      ? `${value.min}^${value.max}`
      : null;
  } else if (type === "checkbox") return value.map(Number).toString();
  else return value || null;
};

export const findByParamsStructure = (param, arr, id, u) =>
  arr.length
    ? arr.find((e) => e[param] === id) ||
      findByParamsStructure(
        param,
        arr.flatMap((e) => e.children || []),
        id
      )
    : u;

export const getAllObjectsWithCompany = (arr) => {
  const filteredArr = filterBranchWithCompany(arr.flat());
  return getAllObjects(filteredArr);
};

const getAllObjects = (arr) =>
  arr.reduce((acc, obj) => {
    acc.push(obj);
    if (obj.children) {
      acc = acc.concat(getAllObjects(obj.children));
    }
    return acc;
  }, []);

export const filterBranchWithCompany = (arr) =>
  arr.filter((obj) => {
    if (obj.company.length > 0) return true;
    else if (obj.children) {
      const filteredChildren = filterBranchWithCompany(obj.children);
      obj.children = filteredChildren;
      return filteredChildren.length > 0;
    }
    return false;
  });

const getPreviousMonthDates = () => {
  const today = new Date();

  // Get the first day of the current month
  const firstDayCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );

  // Calculate the last day of the previous month
  const lastDayPreviousMonth = new Date(firstDayCurrentMonth);
  lastDayPreviousMonth.setUTCDate(0);
  // Calculate the first day of the previous month
  const firstDayPreviousMonth = new Date(lastDayPreviousMonth);
  firstDayPreviousMonth.setUTCDate(1);

  // Calculate the last day of two months ago
  const lastDayTwoMonthsAgo = new Date(lastDayPreviousMonth);
  lastDayTwoMonthsAgo.setUTCDate(0);
  // Calculate the first day of two months ago
  const firstDayTwoMonth = new Date(lastDayTwoMonthsAgo);
  firstDayTwoMonth.setUTCDate(1);

  const format = (date) => formatDate([date], "YYYY-MM-DD");

  return {
    first: [format(firstDayPreviousMonth), format(lastDayPreviousMonth)],
    second: [format(firstDayTwoMonth), format(lastDayTwoMonthsAgo)],
  };
};

const getFirstDayOfCurrentMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCDate(1);
  return currentDate.toISOString();
};

// export const firstDateMonth = "2023-12-01T00:00:00.000Z";
export const firstDateMonth = getFirstDayOfCurrentMonth();
// new Date().toISOString().slice(0, 8) + "01T00:00:00.000Z";
export const trendReportRange = getPreviousMonthDates();

export const htmlToPlainText = (html) => {
  const tempEl = document.createElement("div");
  tempEl.innerHTML = html;
  return tempEl.textContent || tempEl.innerText || "";
};

export const convertToHtml = (e) =>
  draftToHtml(convertToRaw(e.getCurrentContent()));

export const convertToDraft = (text) => {
  const contentBlock = htmlToDraft(text ? text : "");
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
};

export const sortDefaultReports = (arr, param, by) =>
  arr.sort((a, b) => {
    if (typeof a[param] === "string") return a[param].localeCompare(b[param]);
    else if (typeof a[param] === "number")
      return by ? a[param] - b[param] : b[param] - a[param];
    else return 0;
  });

export const parseScheduleReport = (obj) => {
  return {
    ...obj,
    filters: obj.filters && JSON.parse(obj.filters),
    frequency:
      typeof obj.frequency === "object"
        ? obj.frequency
        : JSON.parse(obj.frequency),
    email_addresses: obj.email_addresses.split(","),
  };
};

export const addDays = (days) => {
  const date = new Date();
  date.setUTCDate(date.getDate() + days);
  return moment(date).format("YYYY-MM-DD");
};

export const folderPermission = {
  parse: (string) => {
    const result = { employee: [], team: [], emptype: [] };

    if (string === "1") return { ...result, emptype: "" };

    const parts = string.split(",");
    parts.forEach((part) => {
      const [prefix, ...idParts] = part.split("_");
      const id = idParts.join("_").trim();

      if (result.hasOwnProperty(prefix)) {
        result[prefix].push(id);
      }
    });

    return { ...result, emptype: result.emptype.join(",") };
  },
  build: (data) => {
    if (!data || typeof data !== "object") return "";
    if (data.employee && data.team) {
      if (
        data.employee.length === 0 &&
        data.team.length === 0 &&
        data.emptype.length === 0
      )
        return "1";
      const parts = [];
      if (data.employee.length > 0)
        parts.push(...data.employee.map((id) => `employee_${id}`));
      if (data.emptype.length > 0)
        parts.push(...data.emptype.split(",").map((id) => `emptype_${id}`));
      if (data.team.length > 0)
        parts.push(...data.team.map((id) => `team_${id}`));
      return parts.join(",");
    }
    return "";
  },
};

export const goalColor = {
  percent: (obj, key) => {
    const goalValue = obj[`goal_${key}`];
    if (goalValue) {
      const currentValue = obj[key];
      if (currentValue - goalValue < -5) return "text-danger";
      if (currentValue < goalValue) return "text-warning";
      if (currentValue >= goalValue) return "text-success";
      else return "";
    } else return "";
  },
  integer: (obj, key) => {
    const goalValue = obj[`goal_${key}`];
    if (goalValue) {
      const currentValue = obj[key];
      const percentValue = (currentValue * 100) / goalValue;
      if (percentValue - 100 < -5) return "text-danger";
      if (percentValue < 100) return "text-warning";
      if (percentValue >= 100) return "text-success";
      else return "";
    } else return "";
  },
};

export const editChapterList = (arr, chapter) => {
  let list;
  if (chapter) {
    const checkChapter = arr.some((e) => e.id === chapter.id);
    const updatedList = checkChapter ? arr : [...arr, chapter];
    list = updatedList;
  } else list = arr;

  const result = [...new Set(list.map((e) => e.course.company))].map((id) => {
    const chapters = list.filter((e) => e.course.company === id);
    const label =
      (chapters[0].company_name === "Dealer Setup" && "Gearhead Training") ||
      chapters[0].company_name;

    const options = chapters
      .map((e) => ({
        value: e.id,
        label: `${e.course_abbreviation || ""}-${e.display}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return { label, options };
  });

  return result.sort((a, b) => a.label.localeCompare(b.label));
};

export const iconsToSurveyTypes = (e) => (
  <div className="mb-1">
    {e === surveyFieldsTypes[1].value ? (
      <BsUiRadios size={17} />
    ) : e === surveyFieldsTypes[2].value ? (
      <BsUiChecks size={17} />
    ) : e === surveyFieldsTypes[0].value ? (
      <BsStar size={17} />
    ) : e === surveyFieldsTypes[3].value ? (
      <IoTextOutline size={17} />
    ) : null}
  </div>
);

export const editScorecardList = (arr, elem) => {
  const filterScorecard = arr.filter((e) => e.is_active);
  if (elem) {
    const checkScorecard = filterScorecard.some((e) => e.id === elem.id);
    const array = checkScorecard ? filterScorecard : [...filterScorecard, elem];
    return sorting.byParams(array, "display");
  } else return sorting.byParams(filterScorecard, "display");
};

export const setStructure = (structure, user) =>
  structure.find((e) => e.id === user.location.split(",")[0])?.id || "";

export const formikNavRequiredFill = (formik, hasHiddenElements) => {
  const err = getAllKeys(formik.errors);
  if (err.length) {
    const fieldName = err[0];
    const input = document.getElementById(fieldName);

    if (input) {
      if (hasHiddenElements) {
        let parentElement = input.parentElement;

        while (parentElement) {
          const styles = window.getComputedStyle(parentElement);

          if (styles.display === "none")
            parentElement.classList.add("show-hidden-content");

          parentElement = parentElement.parentElement;
        }
      }

      // Scroll to the field
      input.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }
};

const getAllKeys = (obj, parentKey = "") => {
  const keys = [];

  for (const key in obj) {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof obj[key] !== "object") {
      keys.push(currentKey);
    }

    if (typeof obj[key] === "object") {
      const subKeys = getAllKeys(obj[key], currentKey);
      keys.push(...subKeys);
    }
  }
  return keys;
};

export const remindIcon = (value) =>
  value ? (
    <IoNotificationsOutline size={20} />
  ) : (
    <IoNotificationsOffOutline size={20} />
  );

export const todoDate = (date, time) =>
  date
    ? time
      ? moment(`${date}T${time}`).format("lll")
      : moment(date).format("MMM DD, YYYY")
    : "No Due Date";

export const errorMessagePage = (code) => {
  const errorTypes = [{ code: 1, message: "Wrong Dealer" }];
  throw errorTypes.find((e) => e.code === code);
};

export const reportAccess = (user) => {
  const { is_admin, is_staff, is_reseller, emptype_id } = user;

  const tech = "f83a5379-b6b7-4545-9eac-202460d891d7";
  const adv = "33486d80-17ca-4fa3-b069-5bc519ace1e1";
  const isAdmin = is_admin || is_staff || is_reseller;

  return {
    advOrTech: emptype_id === tech || emptype_id === adv,
    disAdvOrTech: isAdmin ? false : emptype_id === tech || emptype_id === adv,
    isAdv: emptype_id === adv,
    disAdv: isAdmin ? false : emptype_id === adv,
    isTech: emptype_id === tech,
    disTech: isAdmin ? false : emptype_id === tech,
    admin: isAdmin,
  };
};

export const userName = ({ first_name, last_name }) =>
  !first_name && !last_name ? `No Name` : `${first_name} ${last_name}`;
