import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class TaskData {
  async getTaskAuthUser() {
    const getTask = await axiosHTTP.get("/v1/tasks/user/");
    return getTask.data;
  }

  async getFilterTask({ dateRange, taskStatus, createdFor, assignedTo }) {
    const getTask = await axiosHTTP.get("/v1/tasks/company/", {
      params: {
        date_range: dateRange,
        task_status: taskStatus,
        created_for: createdFor,
        assigned_to: assignedTo,
      },
    });
    return getTask.data;
  }

  async getTaskById(id) {
    const getTask = await axiosHTTP.get(`/v1/tasks/${id}/`);
    return getTask.data;
  }

  async postTask(value) {
    const postTask = await axiosHTTP.post(
      `/v1/task-save/`,
      JSON.stringify({
        rated_by: value.ratedBy,
        chapter: value.chapters,
        goal: value.goal || null,
        scorecard: value.scorecard,
        rating: value.rating || null,
        created_for: value.createdFor,
        assigned_to: value.assignedTo,
        deliverable: value.deliverable,
        due_date: value.dueDate || null,
        rating_date: value.ratingDate || null,
        completion_date: value.completionDate || null,
        points: typeof value.points === "number" ? value.points : null,
        pass_points:
          typeof value.passPoints === "number" ? value.passPoints : null,
      })
    );
    return postTask.data;
  }

  async putTask(id, value, createdby) {
    const postTask = await axiosHTTP.put(
      `/v1/tasks/${id}/`,
      JSON.stringify({
        id,
        createdby,
        chapter: value.chapters,
        rated_by: value.ratedBy,
        goal: value.goal || null,
        scorecard: value.scorecard,
        rating: value.rating || null,
        company: getCookie("company"),
        created_for: value.createdFor,
        assigned_to: value.assignedTo,
        deliverable: value.deliverable,
        due_date: value.dueDate || null,
        rating_date: value.ratingDate || null,
        completion_date: value.completionDate || null,
        points: typeof value.points === "number" ? value.points : null,
        pass_points:
          typeof value.passPoints === "number" ? value.passPoints : null,
      })
    );
    return postTask.data;
  }

  async markCompleted(completionDate, { id }) {
    const postTask = await axiosHTTP.patch(
      `/v1/tasks/${id}/`,
      JSON.stringify({ id, completion_date: completionDate || null })
    );
    return postTask.data;
  }

  async rescheduleTask(dueDate, taskId) {
    const postTask = await axiosHTTP.post(
      `/v1/task-reschedule/`,
      JSON.stringify({ task_id: taskId, due_date: dueDate || null })
    );
    return postTask.data;
  }

  async deleteTask(id) {
    const deleteTask = await axiosHTTP.post(
      `/v1/task-delete/`,
      JSON.stringify({ task_id: id })
    );
    return deleteTask.data;
  }

  async getDetailsToTask(id) {
    const get = await axiosHTTP.get(`/v1/tasks-upload/`, {
      params: { task: id },
    });
    return get.data;
  }

  async getTaskScoreDetails(id) {
    const get = await axiosHTTP.get(`/v1/tasks-score/`, {
      params: { task: id },
    });
    return get.data;
  }

  async rateTask(form) {
    const postTask = await axiosHTTP.post(`/v1/scorecard-rating/`, form);
    return postTask.data;
  }

  async deleteDetailsToTask(id) {
    const get = await axiosHTTP.post(
      `/v1/task-upload-delete/`,
      JSON.stringify({ id: id })
    );
    return get.data;
  }

  async recordPhoneCall(phone1, phone2, taskId) {
    const get = await axiosHTTP.post(
      `/v1/task-ctc/`,
      JSON.stringify({
        task_id: taskId,
        ctc_phone1: phone1,
        ctc_phone2: phone2,
      })
    );
    return get.data;
  }

  async uploadAudioVideoToTask(form, progress) {
    const post = await axiosHTTP.post("/v1/task-upload/", form, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
      onUploadProgress: (e) => progress(Math.round((e.loaded / e.total) * 100)),
    });

    return post.data;
  }
  async tasksSchedulingLinks() {
    const get = await axiosHTTP.get(`/v1/user-scheduler-link/`);
    return get.data;
  }
}
