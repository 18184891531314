import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class PremiumServiceData {
  async getPremiumService(search) {
    const get = await axiosHTTP.get("/v1/data-dms-code-group/", {
      params: { company: getCookie("company"), search: search },
    });
    return get.data;
  }

  async getPremiumServiceInfo(id) {
    const get = await axiosHTTP.get(`/v1/data-dms-code-group/${id}/`);
    return get.data;
  }

  async deletePremiumService(id) {
    const get = await axiosHTTP.delete(
      `/v1/data-dms-code-group/${id}/`,
      JSON.stringify({ id: id })
    );
    return get;
  }

  async postPremiumService(display) {
    const get = await axiosHTTP.post(
      `/v1/data-dms-code-group/`,
      JSON.stringify({
        display: display,
        company: getCookie("company"),
      })
    );
    return get.data;
  }

  async putPremiumService(id, display) {
    const get = await axiosHTTP.put(
      `/v1/data-dms-code-group/${id}/`,
      JSON.stringify({ display: display })
    );
    return get.data;
  }

  async getOpCodeForService(id) {
    const get = await axiosHTTP.get("/v1/data-dms-code/", {
      params: { group: id },
    });
    return get.data;
  }

  async getOpCodeInfo(id) {
    const get = await axiosHTTP.get(`/v1/data-dms-code/${id}/`);
    return get.data;
  }

  async deleteOpCode(id) {
    const get = await axiosHTTP.delete(
      `/v1/data-dms-code/${id}/`,
      JSON.stringify({ id: id })
    );
    return get;
  }

  async postOpCode(groupId, value) {
    const { opCode, description, incentive } = value;
    const get = await axiosHTTP.post(
      `/v1/data-dms-code/`,
      JSON.stringify({
        group: groupId,
        op_code: opCode,
        incentive: incentive,
        company: getCookie("company"),
        op_code_description: description,
      })
    );
    return get.data;
  }

  async putOpCode(id, groupId, value) {
    const { opCode, description, incentive } = value;
    const get = await axiosHTTP.put(
      `/v1/data-dms-code/${id}/`,
      JSON.stringify({
        id: id,
        group: groupId,
        op_code: opCode,
        incentive: incentive,
        op_code_description: description,
      })
    );
    return get.data;
  }
}
