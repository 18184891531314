import { getCookie } from "../functions/functions";
import { axiosHTTP } from "../Hook/UserAuthorization";

export class GoalData {
  async getGoalData(param) {
    const get = await axiosHTTP.get(`/v1/goal/`, { params: JSON.parse(param) });
    return get.data;
  }

  async postGoal(value, id) {
    const get = await axiosHTTP.post(
      `/v1/goal/`,
      JSON.stringify({
        ...value,
        ...JSON.parse(id),
        company: getCookie("company"),
      })
    );
    return get.data;
  }

  async putGoal(value) {
    const get = await axiosHTTP.patch(
      `/v1/goal/${value.id}/`,
      JSON.stringify(value)
    );
    return get.data;
  }

  async deleteGoal(id) {
    const get = await axiosHTTP.delete(`/v1/goal/${id}/`);
    return get.data;
  }

  async copyGoal(value) {
    const get = await axiosHTTP.post(`/v1/goal-copy/`, JSON.stringify(value));
    return get.data;
  }
}
