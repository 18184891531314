import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class TemplateData {
  async getTemplates(search) {
    const get = await axiosHTTP.get(`/v1/email-template/`, {
      params: { search: search },
    });
    return get.data;
  }

  async getTemplateById(id) {
    const get = await axiosHTTP.get(`/v1/email-template/${id}/`);
    return get.data;
  }

  async postTemplate(value, html) {
    const post = await axiosHTTP.post(
      `/v1/email-template/`,
      JSON.stringify({
        html_message: html,
        subject: value.subject,
        is_active: value.active,
        name: value.templateName,
        company: getCookie("company"),
        plain_message: value.plainText,
      })
    );
    return post.data;
  }

  async putTemplate(id, value, html) {
    const post = await axiosHTTP.put(
      `/v1/email-template/${id}/`,
      JSON.stringify({
        html_message: html,
        subject: value.subject,
        is_active: value.active,
        name: value.templateName,
        company: getCookie("company"),
        plain_message: value.plainText,
      })
    );
    return post.data;
  }

  async cloneTemplate(id) {
    const clone = await axiosHTTP.post(
      "/v1/email-template/clone/",
      JSON.stringify({
        email_template_id: id,
        company_id: getCookie("company"),
      })
    );
    return clone.data;
  }

  async deleteTemplate(id) {
    const deleteTask = await axiosHTTP.delete(`/v1/email-template/${id}/`);
    return deleteTask.data;
  }

  async sendTemplate({ toUser, emailTemplate }) {
    const send = await axiosHTTP.post(
      `/v1/email-send/`,
      JSON.stringify({ user_id: toUser, template_id: emailTemplate })
    );
    return send.data;
  }

  async sendWelcomeMessage(email, emailTemplate) {
    const send = await axiosHTTP.post(
      `/v1/email-send/`,
      JSON.stringify({ user_id: email, template_id: emailTemplate })
    );
    return send.data;
  }
}
