import React, { Fragment } from "react";
import { NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FiChevronDown, FiChevronLeft } from "react-icons/fi";
import { dealerSettings, globalSettings } from "../../../functions";
import { arrayIds, openInNewTab } from "../../../functions/functions";

const SettingsNav = ({ expand, permission, navBar }) => {
  const navigate = useNavigate();

  const link = ({ link, newTab }) => {
    newTab ? openInNewTab(link) : navigate(link);
    expand(false);
  };

  const filteredArray = (arr) =>
    arr.map((e) => navBar.includes(e)).includes(true);

  const showSubmenu = (elem) => {
    const hover = document.getElementById(elem);
    const position = hover.getBoundingClientRect();
    const submenu = document.getElementById(`submenu-${elem}`);
    const submenuWidth = submenu.getBoundingClientRect().width;
    submenu.style.top = position.y - 8 + "px";
    submenu.style.left = position.x - submenuWidth + "px";
  };

  const globalSettingsList = ({ is_reseller }) =>
    is_reseller ? globalSettings.filter((e) => e.resellers) : globalSettings;

  return (
    <>
      {permission &&
      (permission.is_staff || permission.is_admin || permission.is_reseller) ? (
        <NavDropdown
          id="Settings"
          draggable={false}
          renderMenuOnMount={true}
          title={
            <>
              Settings
              <FiChevronDown />
            </>
          }
        >
          <div className="pre-scrollable">
            <div className="fs-8 text-muted text-uppercase mx-3">
              dealer settings
            </div>
            {dealerSettings.map((e, index) => (
              <Fragment key={index}>
                {!e.page ? (
                  <>
                    {(navBar.includes(e.id) || e.important) && (
                      <NavDropdown.Item
                        onClick={() => link(e)}
                        draggable={false}
                      >
                        {e.label}
                      </NavDropdown.Item>
                    )}
                  </>
                ) : (
                  <>
                    {filteredArray(arrayIds(e.page)) && (
                      <NavDropdown
                        drop="end"
                        id={e.label}
                        draggable={false}
                        renderMenuOnMount={true}
                        onMouseEnter={() => showSubmenu(e.label)}
                        title={
                          <>
                            <FiChevronLeft />
                            {e.label}
                          </>
                        }
                      >
                        <div className="submenu" id={`submenu-${e.label}`}>
                          {e.page.map((e, index) => (
                            <Fragment key={index}>
                              {navBar.includes(e.id) && (
                                <NavDropdown.Item
                                  draggable={false}
                                  onClick={() => link(e)}
                                >
                                  {e.label}
                                </NavDropdown.Item>
                              )}
                            </Fragment>
                          ))}
                        </div>
                      </NavDropdown>
                    )}
                  </>
                )}
              </Fragment>
            ))}
            {(permission.is_staff || permission.is_reseller) && (
              <NavDropdown.Divider />
            )}
            {(permission.is_staff || permission.is_reseller) && (
              <>
                <div className="fs-8 text-muted text-uppercase mx-3">
                  global settings
                </div>
                {globalSettingsList(permission).map((e, index) => (
                  <Fragment key={index}>
                    {!e.page ? (
                      <NavDropdown.Item
                        draggable={false}
                        onClick={() => link(e)}
                      >
                        {e.label}
                      </NavDropdown.Item>
                    ) : (
                      <NavDropdown
                        drop="end"
                        id={e.label}
                        draggable={false}
                        renderMenuOnMount={true}
                        onMouseEnter={() => showSubmenu(e.label)}
                        title={
                          <>
                            <FiChevronLeft />
                            {e.label}
                          </>
                        }
                      >
                        <div className="submenu" id={`submenu-${e.label}`}>
                          {e.page.map((e, index) => (
                            <Fragment key={index}>
                              <NavDropdown.Item
                                draggable={false}
                                onClick={() => link(e)}
                              >
                                {e.label}
                              </NavDropdown.Item>
                            </Fragment>
                          ))}
                        </div>
                      </NavDropdown>
                    )}
                  </Fragment>
                ))}
              </>
            )}
          </div>
        </NavDropdown>
      ) : null}
    </>
  );
};

export default SettingsNav;
