import { getCookie } from "../functions/functions";
import { axiosHTTP } from "../Hook/UserAuthorization";

export class ReportsScheduleData {
  async getReportSchedule() {
    const get = await axiosHTTP.get("/v1/email-schedule/", {
      params: { company_id: getCookie("company") },
    });
    return get.data;
  }

  async getReportScheduleById(id) {
    const get = await axiosHTTP.get(`/v1/email-schedule/${id}/`);
    return get.data;
  }

  async postReportSchedule(value) {
    const get = await axiosHTTP.post(
      "/v1/email-schedule/",
      JSON.stringify({
        company: value.company,
        is_active: value.active,
        delivery_format: value.deliveryFormat,
        date_range_report: value.dateRangeReport,
        frequency: JSON.stringify(value.frequency),
        email_addresses: value.emailsList.join(","),
        filters: JSON.stringify(value.reportCriteria),
      })
    );
    return get.data;
  }

  async putReportSchedule(id, value) {
    const get = await axiosHTTP.put(
      `/v1/email-schedule/${id}/`,
      JSON.stringify({
        id: id,
        company: value.company,
        is_active: value.active,
        delivery_format: value.deliveryFormat,
        date_range_report: value.dateRangeReport,
        frequency: JSON.stringify(value.frequency),
        email_addresses: value.emailsList.join(","),
        filters: JSON.stringify(value.reportCriteria),
      })
    );
    return get.data;
  }

  async sendReportSchedule(id) {
    const get = await axiosHTTP.post(
      "/v1/testing-email-send/",
      JSON.stringify({ id: id })
    );
    return get.data;
  }
}
