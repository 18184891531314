export const changeValue = (multi, value) => {
  const val = multi
    ? value && value.map((x) => x.value).toString()
    : value && value.value;
  return val === null || val === undefined ? "" : val;
};

export const selectedValue = (properties) => {
  const { formatGroupLabel, isMulti, options, value } = properties;
  if (formatGroupLabel) return valuesByGroup(options, value, isMulti);
  else if (isMulti) return multiValues(value, options);
  else return options.find((e) => e.value === value);
};

const valuesByGroup = (array, values, multi) => {
  const valueSet = new Set(values.split(",").map((value) => value.trim()));
  return array.flatMap((e) =>
    multi
      ? e.options.filter((option) => valueSet.has(option.value))
      : e.options.find((option) => valueSet.has(option.value))
  );
};

const multiValues = (values, arr) => {
  if (values) {
    const splitValues = values.split(",").map((value) => value.trim());
    return arr.filter((e) => splitValues.includes(String(e.value)));
  }
};

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#baa7ff" : "#ced4da",
    "&:hover": {
      color: state.isFocused ? "#baa7ff" : "#ced4da",
    },
    borderRadius: 6,
    minHeight: 47,
    boxShadow: state.isFocused
      ? "0 0 0 0.25rem rgba(117, 79, 254, 0.25)"
      : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: "15px",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
  }),

  option: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "#CCC" : state.isSelected ? "#FFF" : styles.color,
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
    backgroundColor: state.isDisabled
      ? "#FFF"
      : state.isSelected
      ? "#754ffe"
      : styles.color,
    "&:hover": !state.isDisabled && {
      color: "#000",
      backgroundColor: "#e3dcff",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
  }),
};

export const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span style={data.label ? { paddingRight: "5px" } : {}}>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const groupStyles = {
  display: "flex",
  alignItems: "center",
};

const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};
