import Flatpickr from "react-flatpickr";
import { formatDate } from "../../../../functions/functions";
import "flatpickr/dist/flatpickr.css";

const DayAndMonth = ({ id, label, placeholder, onChange, ...props }) => {
  const change = (date) =>
    onChange(id, date.length === 0 ? "" : formatDate(date, props.dateFormat));

  const hideBlock = ({ monthNav }) => monthNav.classList.add("cut-cur-year");

  return (
    <Flatpickr
      id={id}
      name={id}
      value={props.value}
      onReady={props.onReady}
      onChange={(date) => change(date)}
      placeholder={placeholder || label}
      className="form-control flatpickr flatpickr-input active"
      onOpen={(selectedDates, dateStr, instance) => hideBlock(instance)}
      options={{
        disableMobile: true,
        minDate: props.minDate,
        dateFormat: props.optionDateFormat,
      }}
    />
  );
};

export default DayAndMonth;

DayAndMonth.defaultProps = {
  dateFormat: "MM-DD",
  optionDateFormat: "m-d",
};
