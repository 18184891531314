import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index.js";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { formatDate } from "../../../../functions/functions";

const MonthRange = ({ id, label, placeholder, onChange, ...props }) => {
  const change = (date) =>
    onChange(id, date.length === 0 ? "" : formatDate(date, "YYYY-MM"));

  return (
    <Flatpickr
      id={id}
      name={id}
      onReady={props.onReady}
      disabled={props.disabled}
      onChange={(date) => change(date)}
      placeholder={placeholder || label}
      className="flatpickr form-control input"
      value={props.value ? new Date(props.value) : ""}
      options={{
        disableMobile: true,
        disable:
          props.disableDate &&
          props.disableDate.map((e) => ({
            from: e.date,
            to: e.end_date ? e.end_date : "9999",
          })),
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m",
            altFormat: "F Y",
          }),
        ],
      }}
    />
  );
};

export default MonthRange;
