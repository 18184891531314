import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class TeamData {
  async getTeams(search) {
    const get = await axiosHTTP.get("/v1/team/", {
      params: { company: getCookie("company"), search: search },
    });
    return get.data;
  }

  async getTeamInfo(id) {
    const get = await axiosHTTP.get(`/v1/team/${id}/`);
    return get.data;
  }

  async postTeam({ name, active }) {
    const get = await axiosHTTP.post(
      "/v1/team/",
      JSON.stringify({
        name: name,
        is_active: active,
        company: getCookie("company"),
      })
    );
    return get.data;
  }

  async putTeam({ name, active }, id) {
    const get = await axiosHTTP.patch(
      `/v1/team/${id}/`,
      JSON.stringify({
        id: id,
        name: name,
        is_active: active,
      })
    );
    return get.data;
  }
}
