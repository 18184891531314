import moment from "moment";
import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class CourseComplete {
  async getCourseInfo(id) {
    const get = await axiosHTTP.get(`/v1/user-course-link/`, {
      params: { id: id },
    });
    return get.data;
  }

  async getUserCourse(id) {
    const get = await axiosHTTP.get(`/v1/user-course-link/${id}/`);
    return get.data;
  }

  async deleteCourseAssigned(id) {
    const get = await axiosHTTP.delete(`/v1/course-assign-delete/`, {
      params: { user_course_link_id: id },
    });
    return get.data;
  }

  async getCompletedCourse(user, course, status) {
    const get = await axiosHTTP.get(`/v1/user-course-link/`, {
      params: {
        user: user,
        course: course,
        status: status,
        company: getCookie("company"),
      },
    });
    return get.data;
  }

  async getCourseData(id) {
    const get = await axiosHTTP.post(
      `/v1/course-view/`,
      JSON.stringify({ id })
    );
    return get.data;
  }

  async completeContent(courseInfo, contentInfo, user) {
    const get = await axiosHTTP.post(
      `/v1/user-course-completion/`,
      JSON.stringify({
        user: user,
        chapter: contentInfo.ch_id,
        content: contentInfo.ct_id,
        company: getCookie("company"),
        course: courseInfo.course_status.course_id,
        usercourselink: courseInfo.course_status.id,
        completion_date: moment(new Date()).format(),
      })
    );
    return get.data;
  }

  async saveCurrentPosition(position, course) {
    const get = await axiosHTTP.put(
      `/v1/user-course-link/${course.id}/`,
      JSON.stringify({
        id: course.id,
        company: course.company,
        current_chapter: position.chapter,
        current_content: position.content,
        user: course.user.id ? course.user.id : course.user,
        course: course.course.id ? course.course.id : course.course,
      })
    );
    return get.data;
  }

  async getViewedTimeAudioVideo(data, courseId) {
    const get = await axiosHTTP.get(`/v1/user-course-completion-video-audio/`, {
      params: {
        content: data.id,
        usercourselink: courseId,
        chapter: data.chapter.id,
      },
    });
    return get.data;
  }

  async postViewedTimeAudioVideo(courseId, data, time) {
    const get = await axiosHTTP.post(
      `/v1/user-course-completion-video-audio/`,
      JSON.stringify({
        content: data.id,
        chapter: data.ch_id,
        usercourselink: courseId,
        time: Number.parseInt(time),
      })
    );
    return get.data;
  }

  async putViewedTimeAudioVideo({ id }, time) {
    const get = await axiosHTTP.patch(
      `/v1/user-course-completion-video-audio/${id}/`,
      JSON.stringify({ id, time: Number.parseInt(time) })
    );
    return get.data;
  }

  async postQuizAnswers(data) {
    const get = await axiosHTTP.post(
      `/v1/user-quiz-answer/`,
      JSON.stringify(data)
    );
    return get.data;
  }

  async checkScoreQuiz(id) {
    const get = await axiosHTTP.get(`/v1/user-quiz-score/`, {
      params: { ids: id },
    });
    return get.data;
  }

  async userQuizScores(id, value) {
    const get = await axiosHTTP.post(
      `/v1/user-quiz-score-detail/`,
      JSON.stringify({
        usercourselink_id: id,
        content_id: value.ct_id,
        quiz_id: value.ct_quiz_id,
      })
    );
    return get.data;
  }

  async markCourseComplete(course) {
    const mark = await axiosHTTP.put(
      `/v1/user-course-link/${course.id}/`,
      JSON.stringify({
        id: course.id,
        company: course.company,
        completion_date: new Date(),
        user: course.user.id ? course.user.id : course.user,
        course: course.course.id ? course.course.id : course.course,
      })
    );
    return mark.data;
  }
}
