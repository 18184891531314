const ImageButtons = ({ disabled, submit, close }) => {
  return (
    <>
      <span className="rdw-image-modal-btn-section">
        <button
          className="rdw-image-modal-btn"
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          Add
        </button>
        <button
          className="rdw-image-modal-btn"
          onClick={(e) => {
            e.preventDefault();
            close();
          }}
        >
          Cancel
        </button>
      </span>
      <div>&nbsp;</div>
    </>
  );
};

export default ImageButtons;
