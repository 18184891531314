import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { actionsList, reportsList } from "../../../functions";
import SettingsNav from "./SettingsNav";
import DropDownMenu from "./DropDownMenu";
import UserAndNotifyNav from "./UserAndNotifyNav";
import DealerAndCompanies from "./DealerAndCompanie";
import logo from "../../../../src/image/logo/gearhead-2020-whitebg.gif";

const NavBar = (props) => {
  const { navBarSettings, companies, permission, onLogOut, update } = props;
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expanded={expanded} expand="lg" sticky="top" bg="light">
      <Container>
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Brand
          onClick={() => {
            navigate("/accounts/dashboard");
            setExpanded(false);
          }}
        >
          {permission && (
            <div draggable={false} className="nav-bar-logo">
              <img src={permission.logo || logo} alt="logo" />
            </div>
          )}
        </Navbar.Brand>
        {navBarSettings && permission ? (
          <>
            <div className="show-user-notify">
              <UserAndNotifyNav onLogOut={onLogOut} permission={permission} />
            </div>
            <Navbar.Collapse>
              <Nav draggable={false}>
                <DealerAndCompanies
                  update={update}
                  companies={companies}
                  navBar={navBarSettings}
                  expand={() => setExpanded(false)}
                />
                <DropDownMenu
                  display="Actions"
                  array={actionsList}
                  navBar={navBarSettings}
                  expand={() => setExpanded(false)}
                />
                <DropDownMenu
                  display="Reports"
                  array={reportsList}
                  navBar={navBarSettings}
                  expand={() => setExpanded(false)}
                />
                <SettingsNav
                  navBar={navBarSettings}
                  permission={permission}
                  expand={() => setExpanded(false)}
                />
              </Nav>
            </Navbar.Collapse>
            <div className="dont-show-user-notify">
              <UserAndNotifyNav onLogOut={onLogOut} permission={permission} />
            </div>
          </>
        ) : (
          <span className="loader-nav-bar" />
        )}
      </Container>
    </Navbar>
  );
};

export default NavBar;
