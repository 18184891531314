import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, NavDropdown } from "react-bootstrap";
import { FiChevronDown, FiChevronLeft } from "react-icons/fi";
import { arrayIds, openInNewTab } from "../../../functions/functions";

const DropDownMenu = ({ display, array, navBar, expand, badge }) => {
  const navigate = useNavigate();

  const link = ({ link, newTab }) => {
    newTab ? openInNewTab(link) : navigate(link);
    expand(false);
  };

  const filteredArray = (arr) =>
    arr.map((e) => navBar.includes(e)).includes(true);

  const showSubmenu = (elem) => {
    const hover = document.getElementById(elem);
    const position = hover.getBoundingClientRect();
    const submenu = document.getElementById(`submenu-${elem}`);
    const submenuWidth = submenu.getBoundingClientRect().width;
    submenu.style.top = position.y - 8 + "px";
    submenu.style.left = position.x - submenuWidth + "px";
  };

  return (
    <>
      {filteredArray(arrayIds(array)) && (
        <NavDropdown
          draggable={false}
          renderMenuOnMount={true}
          title={
            <div draggable={false}>
              {display}
              <FiChevronDown />
              {badge && badge !== 0 ? <Badge pill>{badge}</Badge> : ""}
            </div>
          }
          id={display}
        >
          <div className="pre-scrollable">
            {array.map((e, index) => (
              <div key={index}>
                {!e.page ? (
                  <>
                    {navBar.includes(e.id) && (
                      <NavDropdown.Item
                        draggable={false}
                        onClick={() => link(e)}
                      >
                        {e.label}
                      </NavDropdown.Item>
                    )}
                  </>
                ) : (
                  <>
                    {filteredArray(arrayIds(e.page)) && (
                      <NavDropdown
                        id={e.label}
                        drop="start"
                        draggable={false}
                        renderMenuOnMount={true}
                        onMouseEnter={() => showSubmenu(e.label)}
                        title={
                          <>
                            <FiChevronLeft />
                            {e.label}
                          </>
                        }
                      >
                        <div className="submenu" id={`submenu-${e.label}`}>
                          {e.page.map((e, index) => (
                            <Fragment key={index}>
                              {navBar.includes(e.id) && (
                                <NavDropdown.Item
                                  draggable={false}
                                  onClick={() => link(e)}
                                >
                                  {e.label}
                                </NavDropdown.Item>
                              )}
                            </Fragment>
                          ))}
                        </div>
                      </NavDropdown>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </NavDropdown>
      )}
    </>
  );
};

export default DropDownMenu;
