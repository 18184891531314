import { Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <div style={{ position: "relative", height: "80vh" }}>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          maxWidth: "520px",
          width: "100%",
          lineHeight: "1.4",
          textAlign: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            height: "200px",
            margin: "0 auto 20px",
          }}
        >
          <Spinner
            animation="grow"
            variant="primary"
            style={{ width: "100px", height: "100px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Loading;
