import React, { useState } from "react";
import { Image } from "../../../../../api";
import {
  Modifier,
  EditorState,
  BlockMapBuilder,
  convertFromHTML,
  SelectionState,
} from "draft-js";
import {
  getCookie,
  convertToDraft,
  convertToHtml,
} from "../../../../../functions/functions";
import ImageSize from "../ImageCustom/ImageSize";
import ImageButtons from "../ImageCustom/ImageButtons";

const ImageUploadCustom = ({ onChange, editorState, imageAdd }) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [open, setOpen] = useState(false);
  const [newImage, setNewImage] = useState();
  const [descriptionImage, setDescriptionImage] = useState("");

  const handleImageUpload = async () => {
    const image = new FormData();
    image.append("photo", newImage);
    image.append("company", getCookie("company"));
    image.append("image_name", descriptionImage);

    await Image.postImage(image).then((e) => addImage(e, height, width));
  };

  const addImage = (img, h, w) => {
    const text = `<img src="${img.photo}" alt="${
      img.image_name
    }" height="${size(h)}" width="${size(w)}">`;

    const htmlContent = convertFromHTML(text);
    const htmlContentMap = BlockMapBuilder.createFromArray(
      htmlContent.contentBlocks
    );
    const contentState = editorState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(
      editorState.getSelection().getStartKey()
    );
    const currentBlockKey = currentBlock.getKey();
    const currentBlockLength = currentBlock.getLength();
    const selection = new SelectionState({
      anchorKey: currentBlockKey,
      anchorOffset: currentBlockLength,
      focusKey: currentBlockKey,
      focusOffset: currentBlockLength,
    });
    const newContent = Modifier.replaceWithFragment(
      contentState,
      selection,
      htmlContentMap
    );
    const newState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    setNewImage();
    setDescriptionImage("");
    setOpen(false);
    onChange(convertToDraft(convertToHtml(newState)));
    imageAdd();
  };

  return (
    <div className="rdw-block-wrapper">
      <div className="rdw-dropdown-wrapper rdw-block-dropdown">
        <div
          className="rdw-dropdown-selectedtext"
          onClick={() => setOpen(!open)}
        >
          Upload Image
        </div>
        <ul
          className={`rdw-dropdown-optionwrapper ${
            open ? "" : "placeholder-ul"
          }`}
          style={{ width: 240 }}
        >
          <div className="m-2 custom-upload">
            <label className="custom-file-upload">
              {newImage ? newImage.name : "Upload Image"}
              <input
                id="file"
                type="file"
                className=""
                onChange={(e) => setNewImage(e.target.files[0])}
                accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
              />
            </label>
          </div>
          <div className="rdw-image-modal-size mx-2">
            <span className="rdw-image-modal-alt-lbl">Description</span>
            <input
              id="description"
              value={descriptionImage}
              placeholder="description"
              className="rdw-image-modal-alt-input"
              onChange={(e) => setDescriptionImage(e.target.value)}
            />
            <span className="rdw-image-mandatory-sign">*</span>
          </div>
          <ImageSize
            width={width || ""}
            height={height || ""}
            changeWidth={(e) => setWidth(e)}
            changeHeight={(e) => setHeight(e)}
          />
          <ImageButtons
            close={() => setOpen(false)}
            disabled={!(descriptionImage && newImage)}
            submit={() => {
              handleImageUpload();
              // addImage(newImage, height, width);
            }}
          />
        </ul>
      </div>
    </div>
  );
};

export default ImageUploadCustom;

const size = (e) => (e ? e + "px" : "auto");
