import React, { useState } from "react";
import { EditorState, Modifier } from "draft-js";
import { emailTemplatesTokens } from "../../../../../functions";

const TokensEmail = ({ onChange, editorState }) => {
  const [open, setOpen] = useState(false);

  const addToken = (placeholder) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    const result = EditorState.push(
      editorState,
      contentState,
      "insert-characters"
    );
    if (onChange) {
      onChange(result);
    }
  };

  return (
    <div onClick={() => setOpen(!open)} className="rdw-block-wrapper">
      <div className="rdw-dropdown-wrapper rdw-block-dropdown">
        <div className="rdw-dropdown-selectedtext">
          <span>Tokens</span>
          <div className={`rdw-dropdown-caretto${open ? "close" : "open"}`} />
        </div>
        <ul
          className={`rdw-dropdown-optionwrapper ${
            open ? "" : "placeholder-ul"
          }`}
          style={{ width: 200 }}
        >
          {emailTemplatesTokens.map((item) => (
            <li
              key={item.value}
              onClick={() => addToken(item.value)}
              className="rdw-dropdownoption-default placeholder-li"
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TokensEmail;
