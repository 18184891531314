import { axiosHTTP } from "../Hook/UserAuthorization";
export class ReportingStructure {
  async getStructure(parent, search) {
    const get = await axiosHTTP.get("/v1/location/", {
      params: { parent_null: parent ? true : undefined, search: search },
    });
    return get.data;
  }

  async getStructureById(id) {
    const get = await axiosHTTP.get(`/v1/location/tree/`, {
      params: { location_id: id },
    });
    return get.data;
  }

  async getStructuresByUserId(id) {
    const get = await axiosHTTP.get(`/v1/location/report/${id}/`);
    return get.data;
  }

  async getStructureByUser(user_id, location) {
    const get = await axiosHTTP.post(
      `/v1/location/tree/user/`,
      JSON.stringify({
        user_id: user_id,
        location_id: location,
      })
    );
    return get.data;
  }

  async postStructure(name, parent, active) {
    const get = await axiosHTTP.post(
      `/v1/location/`,
      JSON.stringify({
        name: name,
        parent: parent,
        is_active: active,
      })
    );
    return get.data;
  }

  async putStructure(id, name, parent, active) {
    const get = await axiosHTTP.put(
      `/v1/location/${id}/`,
      JSON.stringify({
        id: id,
        name: name,
        parent: parent,
        is_active: active,
      })
    );
    return get.data;
  }

  async patchStructure(id, company) {
    const get = await axiosHTTP.patch(
      `/v1/location/${id}/`,
      JSON.stringify({ id: id, company: company })
    );
    return get.data;
  }

  async deleteStructure(id) {
    const get = await axiosHTTP.delete(`/v1/location/${id}/`);
    return get.data;
  }
  //reports

  async getStructurePerformance(params) {
    const get = await axiosHTTP.post(
      `/v1/enterprise/report-svc-adv-performance-closed-ros-report/`,
      params
    );
    return get.data;
  }

  async getStructureSummaryPremium(params) {
    const get = await axiosHTTP.post(
      `/v1/enterprise/report-premium-svcs/`,
      params
    );
    return get.data;
  }

  async getStructureSummaryPremiumAdvisor(params) {
    const get = await axiosHTTP.post(
      `/v1/enterprise/report-premium-service-ranking-summary-report/`,
      params
    );
    return get.data;
  }

  async getStructureSummaryPremiumTechnician(params) {
    const get = await axiosHTTP.post(
      `/v1/enterprise/report-premium-service-ranking-summary-report-by-technician/`,
      params
    );
    return get.data;
  }

  async getStructureGrossPremium(params) {
    const get = await axiosHTTP.post(
      `/v1/enterprise/report-premium-gross-profit-report/`,
      params
    );
    return get.data;
  }

  async getStructurePremiumReport(params) {
    const get = await axiosHTTP.post(
      `/v1/enterprise/report-premium-svcs/`,
      params
    );
    return get.data;
  }
}
