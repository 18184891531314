import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class MessagesData {
  async getMessageGroup() {
    const get = await axiosHTTP.get("/v1/message-group/", {
      params: { company: getCookie("company") },
    });
    return get.data;
  }

  async searchMessageGroup(search) {
    const get = await axiosHTTP.get("/v1/message-group/", {
      params: { company: getCookie("company"), search: search },
    });
    return get.data;
  }

  async postMessageGroup({ channelName, active }) {
    const post = await axiosHTTP.post(
      "/v1/message-group/",
      JSON.stringify({
        is_active: active,
        display: channelName,
        company: getCookie("company"),
      })
    );
    return post.data;
  }

  async putMessageGroup(id, { channelName, active }) {
    const put = await axiosHTTP.put(
      `/v1/message-group/${id}/`,
      JSON.stringify({
        id: id,
        is_active: active,
        display: channelName,
        company: getCookie("company"),
      })
    );
    return put.data;
  }

  async postUserToMessageGroup(id, user) {
    const post = await axiosHTTP.post(
      `/v1/message-group-user/`,
      JSON.stringify({ messagegroup: id, user: Number(user) })
    );
    return post.data;
  }

  async getMessageGroupInfo(id) {
    const get = await axiosHTTP.get(`/v1/message-group/${id}/`);
    return get.data;
  }

  async getMessageGroupUsers(id) {
    const get = await axiosHTTP.get("/v1/message-group-user/", {
      params: { messagegroup: id },
    });
    return get.data;
  }

  async deleteMessageGroupUser(id) {
    const get = await axiosHTTP.delete(
      `/v1/message-group-user/${id}/`,
      JSON.stringify({ id: id })
    );
    return get.data;
  }

  async getMessage(value) {
    const { type, dateRange, messageFrom, messageTo } = value;
    const get = await axiosHTTP.post(
      "/v1/message-center/",
      JSON.stringify({
        mailbox: type,
        date_range: dateRange,
        message_to: messageTo || null,
        message_from: messageFrom || null,
      }),
      {
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
          "Content-Type": "application/json",
        },
      }
    );
    return get.data;
  }

  async getMessageAttachment() {
    const get = await axiosHTTP.get("/v1/message/", {
      params: { company: getCookie("company") },
    });
    return get.data;
  }

  async sendMessage(form) {
    const send = await axiosHTTP.post("/v1/message-sent/", form, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
    });
    return send.data;
  }

  async readMessage() {
    const send = await axiosHTTP.post(
      "/v1/message-read/",
      JSON.stringify({ read: "done" })
    );
    return send.data;
  }
}
