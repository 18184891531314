import React from "react";
import { errorMessageForm } from "../../../functions/functions";
import Field from "../../Field";

const SingleAnswer = ({ questionData, formik, isDisable }) => {
  const handleRadioChange = (selectedOption) =>
    formik.setFieldValue(questionData.id, [selectedOption]);

  return (
    <div className="mb-2">
      <Field.Label>{questionData.question}</Field.Label>
      {questionData.options && questionData.options.length > 0 ? (
        <>
          {questionData.options.map((option, optionIndex) => (
            <div key={optionIndex}>
              <Field.Check
                type="radio"
                value={option}
                label={option}
                disabled={isDisable}
                name={`radio-${questionData.id}`}
                onChange={() => handleRadioChange(option)}
                id={`radio-${questionData.id}-${optionIndex}`}
                checked={
                  formik.values[questionData.id]
                    ? formik.values[questionData.id][0] === option
                    : ""
                }
              />
            </div>
          ))}
          {errorMessageForm(questionData.id)}
        </>
      ) : (
        <div className="text-center">Sorry, something went wrong</div>
      )}
    </div>
  );
};

export default SingleAnswer;
