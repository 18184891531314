/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect, useState } from "react";
import { navBarPages } from "../../functions";
import { ContextProviders } from "../../Context";
import { Company, MenuBar, User } from "../../api";
import { userCurrentDealer } from "../../storage/local";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie, removeCookie, sorting } from "../../functions/functions";
import NavBar from "./NavBar/NavBar";
import Routs from "../../Routing/mainRouting";
import FirstResetPassword from "./FirstResetPassword";
import ButtonHelp from "../../CustomComponents/ButtonHelp";
import Loading from "../../CustomComponents/loading/Loading";

const MainPage = ({ onLogOut, quote }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [update, setUpdate] = useState(true);
  const [firstLogin, setFirstLogin] = useState(false);
  const [showCompanies, setShowCompanies] = useState([]);
  const [userPermission, setUserPermission] = useState();
  const [navBarSettings, setNavBarSettings] = useState();

  useEffect(() => {
    const changeCompanyToDefault = async () => {
      const info = userPermission;
      setUserPermission();
      await Company.changeCompany(defaultCompany);
      setTimeout(() => setUserPermission(info));
    };
    const defaultCompany = userCurrentDealer.get();
    const company = getCookie("company");
    defaultCompany && company !== defaultCompany && changeCompanyToDefault();
    if (
      showCompanies.length > 0 &&
      !showCompanies.some((e) => e.id === company)
    )
      userLogOut();
  }, [location]);

  useEffect(() => {
    const getDataLoggedUser = async () => {
      const permission = await User.getUserPermission();
      if (permission.first_login === "1") {
        setUserPermission(permission);
        setFirstLogin(true);
        return null;
      }
      setFirstLogin(false);
      const companies = await Company.getCompaniesUser();
      if (permission.is_staff === false) {
        const menuSettings = await MenuBar.getNavBarSettings(permission);
        setNavBarSettings(menuSettings);
      } else setNavBarSettings(navBarPages);

      setShowCompanies(companies);
      setUserPermission(permission);
    };

    update &&
      getDataLoggedUser()
        .catch(userLogOut)
        .finally(() => finallyAction(update));
  }, [update]);

  useEffect(() => {
    if (location.pathname === "/") navigate("/accounts/dashboard");
  }, [location.pathname]);

  useEffect(() => {
    if (getCookie("").length <= 3) {
      userLogOut();
    }
  }, [getCookie("")]);

  const finallyAction = (update) => {
    update === "dashboard" &&
      location.pathname !== "/accounts/dashboard" &&
      navigate(`/accounts/dashboard`);
    setUpdate(false);
  };

  const userLogOut = () => {
    onLogOut(null);
    removeCookie();
  };

  return (
    <>
      <NavBar
        onLogOut={userLogOut}
        companies={showCompanies}
        permission={userPermission}
        navBarSettings={navBarSettings}
        update={() => setUpdate("dashboard")}
      />
      <div className="main-container">
        <div className="routes">
          {firstLogin ? (
            <FirstResetPassword
              user={userPermission}
              change={() => setUpdate(true)}
            />
          ) : (
            <>
              <ContextProviders
                permission={userPermission}
                dealerUpdate={(e) => setShowCompanies(e)}
                changeUserInfo={(e) => setUserPermission(e)}
                dealers={sorting.byParams(showCompanies, "company")}
              >
                <Suspense fallback={<Loading />}>
                  {userPermission && (
                    <Routs quote={quote} permission={userPermission} />
                  )}
                </Suspense>
              </ContextProviders>
              {userPermission && <ButtonHelp userPermission={userPermission} />}
            </>
          )}
        </div>
        <footer className="footer">
          © {new Date().getFullYear()} Gearhead Training. All Rights Reserved.
        </footer>
      </div>
    </>
  );
};
export default MainPage;
