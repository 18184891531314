import React, { useState } from "react";
import {
  EditorState,
  Modifier,
  BlockMapBuilder,
  convertFromHTML,
  SelectionState,
} from "draft-js";
import ImageSize from "./ImageSize";
import ImageSelect from "./ImageSelect";
import ImageButtons from "./ImageButtons";
import {
  convertToHtml,
  convertToDraft,
} from "../../../../../functions/functions";

const ImageCustom = ({ onChange, editorState, list }) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [open, setOpen] = useState(false);
  const [imageItem, setImageItem] = useState();

  const addImage = (img, h, w) => {
    const text = `<img src="${img.photo}" alt="${
      img.image_name
    }" height="${size(h)}" width="${size(w)}">`;

    const htmlContent = convertFromHTML(text);
    const htmlContentMap = BlockMapBuilder.createFromArray(
      htmlContent.contentBlocks
    );
    const contentState = editorState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(
      editorState.getSelection().getStartKey()
    );
    const currentBlockKey = currentBlock.getKey();
    const currentBlockLength = currentBlock.getLength();
    const selection = new SelectionState({
      anchorKey: currentBlockKey,
      anchorOffset: currentBlockLength,
      focusKey: currentBlockKey,
      focusOffset: currentBlockLength,
    });
    const newContent = Modifier.replaceWithFragment(
      contentState,
      selection,
      htmlContentMap
    );
    const newState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    setOpen(false);
    setImageItem();
    onChange(convertToDraft(convertToHtml(newState)));
  };

  return (
    <div className="rdw-block-wrapper">
      <div className="rdw-dropdown-wrapper rdw-block-dropdown">
        <div
          className="rdw-dropdown-selectedtext"
          onClick={() => setOpen(!open)}
        >
          Choose Image
        </div>
        <ul
          className={`rdw-dropdown-optionwrapper ${
            open ? "" : "placeholder-ul"
          }`}
          style={{ width: 200 }}
        >
          <ImageSelect
            list={list}
            img={imageItem?.photo}
            selectImage={(e) => setImageItem(e)}
          />
          <ImageSize
            width={width || ""}
            height={height || ""}
            changeWidth={(e) => setWidth(e)}
            changeHeight={(e) => setHeight(e)}
          />
          <ImageButtons
            disabled={!imageItem}
            close={() => setOpen(false)}
            submit={() => addImage(imageItem, height, width)}
          />
        </ul>
      </div>
    </div>
  );
};

export default ImageCustom;

const size = (e) => (e ? e + "px" : "auto");
