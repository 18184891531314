import Field from "..";
import { ErrorMessage } from "formik";
import FieldControl from "./Default";
import NumberRange from "./NumberRange";

const Control = ({ showLabel, labelContent, noError, ...props }) => {
  return (
    <>
      {showLabel && props.label && (
        <Field.Label labelContent={labelContent} htmlFor={props?.id}>
          {props?.label}
        </Field.Label>
      )}
      <FieldControlType {...props} />
      {props.id && !noError && (
        <div className="text-danger">
          <ErrorMessage name={props.id} />
        </div>
      )}
    </>
  );
};

export default Control;

Control.defaultProps = {
  id: null,
  label: "",
  type: "text",
  showLabel: true,
  autoComplete: "off",
};
const FieldControlType = ({ type, ...props }) => (
  <>{typeDateField(type, props)}</>
);

const typeDateField = (type, props) => {
  switch (type) {
    case "numberRange":
      return <NumberRange {...props} />;
    default:
      return <FieldControl {...props} type={type} />;
  }
};
