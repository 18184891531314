import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

const Time = ({ id, placeholder, label, ...props }) => {
  const change = (date) =>
    props.onChange(id, moment(date[0]).format("HH:mm:ss"));

  return (
    <>
      <Flatpickr
        id={id}
        name={id}
        value={props.value}
        onReady={props.onReady}
        onChange={(date) => change(date)}
        placeholder={placeholder || label}
        className="form-control flatpickr flatpickr-input active"
        options={{
          enableTime: true,
          noCalendar: true,
          dateFormat: "G:i K",
          time_24hr: false,
          minuteIncrement: 30,
          disableMobile: true,
        }}
      />
    </>
  );
};

export default Time;
