import React, { useState } from "react";
import { errorMessageForm } from "../../../functions/functions";
import Field from "../../Field";

const StarRating = ({ questionData, formik, isDisable }) => {
  const [rating, setRating] = useState(
    Number(formik.values[questionData.id]) || 0
  );
  const [hoveredRating, setHoveredRating] = useState(0);

  const handleStarClick = (selectedRating) =>
    isDisable && setRating(selectedRating);

  const handleStarHover = (hoveredRating) =>
    isDisable && setHoveredRating(hoveredRating);

  const handleStarLeave = () => isDisable && setHoveredRating(0);

  return (
    <div>
      <Field.Label>{questionData.question}</Field.Label>
      <div>
        <div className="star-rating">
          {[...Array(5)].map((_, index) => {
            const starValue = index + 1;
            return (
              <span
                key={starValue}
                className={`${isDisable ? "star" : "star_disable"} ${
                  starValue <= (hoveredRating || rating) ? "selected" : ""
                }`}
                onClick={() => {
                  handleStarClick(starValue);
                  isDisable && formik.setFieldValue(questionData.id, starValue);
                }}
                onMouseEnter={() => handleStarHover(starValue)}
                onMouseLeave={handleStarLeave}
              >
                ★
              </span>
            );
          })}
        </div>
        {errorMessageForm(questionData.id)}
      </div>
    </div>
  );
};

export default StarRating;
