import { AIData } from "./aiApi";
import { LogData } from "./logApi";
import { Videos } from "./videoApi";
import { Images } from "./imageApi";
import { TodoData } from "./todoApi";
import { GoalData } from "./goalApi";
import { TaskData } from "./taskApi";
import { TeamData } from "./teamApi";
import { FormData } from "./formApi";
import { UsersData } from "./userApi";
import { LoginUser } from "./loginApi";
import { PartsData } from "./partsApi";
import { FolderData } from "./folderApi";
import { SurveyData } from "./surveyApi";
import { BucketData } from "./bucketApi";
import { ListingData } from "./listingApi";
import { Companies } from "./companiesApi";
import { CoursesData } from "./coursesApi";
import { OpCodesData } from "./OpCodesData";
import { MessagesData } from "./messagesApi";
import { Reminder } from "./callReminderApi";
import { ChaptersData } from "./chaptersApi";
import { ScheduleData } from "./scheduleApi";
import { TemplateData } from "./templatesApi";
import { ReportsCompany } from "./reportsApi";
import { ScorecardData } from "./scorecardApi";
import { EventData } from "./eventScheduleApi";
import { CustomizeMenuBar } from "./menuBarApi";
import { PageData } from "./pageDescriptionApi";
import { WhiteLabelData } from "./whiteLabelsApi";
import { NotificationData } from "./notificationsApi";
import { PremiumServiceData } from "./premiumService";
import { CourseComplete } from "./courseCompletionApi";
import { ReportsScheduleData } from "./reportsScheduleApi";
import { ReportingStructure } from "./reportingStructureApi";

export const AI = new AIData();
export const Log = new LogData();
export const Video = new Videos();
export const Image = new Images();
export const Task = new TaskData();
export const Team = new TeamData();
export const Todo = new TodoData();
export const Forms = new FormData();
export const User = new UsersData();
export const Goals = new GoalData();
export const Login = new LoginUser();
export const Parts = new PartsData();
export const Event = new EventData();
export const Bucket = new BucketData();
export const Company = new Companies();
export const Survey = new SurveyData();
export const Folder = new FolderData();
export const PagesData = new PageData();
export const Courses = new CoursesData();
export const Listing = new ListingData();
export const OpCodes = new OpCodesData();
export const Message = new MessagesData();
export const Chapters = new ChaptersData();
export const CallReminder = new Reminder();
export const Schedule = new ScheduleData();
export const Reports = new ReportsCompany();
export const Templates = new TemplateData();
export const Scorecard = new ScorecardData();
export const MenuBar = new CustomizeMenuBar();
export const WhiteLabels = new WhiteLabelData();
export const Structure = new ReportingStructure();
export const CompleteCourse = new CourseComplete();
export const Notification = new NotificationData();
export const PremService = new PremiumServiceData();
export const ReportSchedule = new ReportsScheduleData();
