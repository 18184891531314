import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Row, Col, Modal } from "react-bootstrap";
import StarRating from "./ModuleForm/StarRating";
import Comment from "./ModuleForm/Comment";
import SingleAnswer from "./ModuleForm/SingleAnswer";
import MultiAnswer from "./ModuleForm/MultiAnswer";

const DynamicForm = ({ formData, children, answers, send }) => {
  const validationSchema = Yup.object().shape(
    formData.reduce((schema, field) => {
      if (field.type_field === 1 || field.type_field === 2)
        schema[field.id] = Yup.array().test(
          "required",
          "Select at option",
          (e) =>
            field.options === null || field.options.length === 0
              ? true
              : e.length > 0
        );
      else if (field.type_field === 3) schema[field.id] = Yup.string();
      else if (field.type_field === 4)
        schema[field.id] = Yup.string().required("This field is required");
      return schema;
    }, {})
  );

  const submit = async (values) => send(values);

  return (
    <Formik
      onSubmit={submit}
      validationSchema={validationSchema}
      initialValues={initialValue(formData, answers)}
    >
      {({ handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {formData
              .sort((a, b) => a.ordering - b.ordering)
              .map((field) => (
                <Row className="border rounded mb-1" key={field.id}>
                  <Col>
                    {field.type_field === 1 && (
                      <SingleAnswer
                        formik={formik}
                        questionData={field}
                        isDisable={!!answers}
                      />
                    )}
                    {field.type_field === 2 && (
                      <MultiAnswer
                        formik={formik}
                        questionData={field}
                        isDisable={!!answers}
                      />
                    )}
                    {field.type_field === 3 && (
                      <Comment
                        formik={formik}
                        questionData={field}
                        isDisable={!!answers}
                      />
                    )}
                    {field.type_field === 4 && (
                      <StarRating
                        formik={formik}
                        questionData={field}
                        isDisable={!answers}
                      />
                    )}
                  </Col>
                </Row>
              ))}
          </Modal.Body>
          <>{children}</>
        </Form>
      )}
    </Formik>
  );
};

export default DynamicForm;

const initialValue = (arr, ans) => {
  return arr.reduce((acc, e) => {
    e.type_field === 1 || e.type_field === 2
      ? (acc[e.id] = ans
          ? JSON.parse(
              ans.find((x) => x.question === e.id)?.value.replace(/'/g, '"')
            )
          : [])
      : (acc[e.id] = ans ? ans.find((x) => x.question === e.id)?.value : "");
    return acc;
  }, {});
};
