import React, { useState } from "react";

const ImageSelect = ({ list, img, selectImage }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="rdw-image-modal-size">
        <div className="rdw-block-wrapper" onClick={() => setOpen(!open)}>
          <div className="rdw-dropdown-wrapper rdw-block-dropdown">
            <div className="rdw-dropdown-selectedtext">
              <span>Image List</span>
              <div
                className={`rdw-dropdown-caretto${open ? "close" : "open"}`}
              />
            </div>
            <ul
              className={`rdw-dropdown-optionwrapper ${
                open ? "" : "placeholder-ul"
              }`}
              style={{ width: 190 }}
            >
              {list.map((item) => (
                <li
                  onClick={() => selectImage(item)}
                  key={item.id}
                  className="rdw-dropdownoption-default placeholder-li"
                >
                  {item.image_name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {img && (
        <div className="mx-4 my-2">
          <img src={img} alt="preview" height={75} width={75} />
        </div>
      )}
    </>
  );
};

export default ImageSelect;
