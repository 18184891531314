import Field from "..";
import Select from "react-select";
import { ErrorMessage } from "formik";
import makeAnimated from "react-select/animated";
import {
  changeValue,
  customStyles,
  selectedValue,
  formatGroupLabel,
} from "./selectSettings";

const animatedComponents = makeAnimated();

const SelectComponent = ({ showLabel, labelContent, ...props }) => {
  return (
    <>
      {showLabel && props.label && (
        <Field.Label labelContent={labelContent} htmlFor={props.id}>
          {props.label}
        </Field.Label>
      )}
      <FieldSelect {...props} />
      {props.id && (
        <div className="text-danger">
          <ErrorMessage name={props.id} />
        </div>
      )}
    </>
  );
};

export default SelectComponent;

SelectComponent.defaultProps = {
  id: null,
  label: "",
  options: [],
  isMulti: false,
  showLabel: true,
  isDisabled: false,
  isClearable: false,
  isSearchable: false,
};

const FieldSelect = ({ id, placeholder, label, onChange, ...props }) => {
  const value = selectedValue(props);
  return (
    <Select
      name={id}
      inputId={id}
      minMenuHeight={300}
      value={value || ""}
      menuPlacement="auto"
      styles={customStyles}
      isMulti={props.isMulti}
      options={props.options}
      isDisabled={props.isDisabled}
      isClearable={!props.isClearable}
      isSearchable={!props.isSearchable}
      closeMenuOnSelect={!props.isMulti}
      placeholder={placeholder || label}
      components={!props.noAnimated && animatedComponents}
      onChange={(e) => onChange(id, changeValue(props.isMulti, e))}
      formatGroupLabel={props.formatGroupLabel && formatGroupLabel}
    />
  );
};
