import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Company } from "../../../api";
import { userCurrentDealer } from "../../../storage/local";
import {
  sorting,
  getCookie,
  arrayForSelect,
} from "../../../functions/functions";
import Field from "../../../CustomComponents/Field";

const DealerAndCompanies = ({ companies, expand, navBar, update }) => {
  const changeCompany = async (_, id) =>
    getCookie("company") !== id &&
    (await Company.changeCompany(id).then(() => {
      userCurrentDealer.get() && userCurrentDealer.set(id);
      update();
      expand(false);
    }));

  return (
    <>
      {companies && (
        <div style={{ minWidth: "200px" }}>
          <Field.Select
            isClearable
            isSearchable
            placeholder="Company"
            onChange={changeCompany}
            value={getCookie("company")}
            options={listCompany(companies)}
          />
        </div>
      )}
      <Nav draggable={false}>
        <Nav.Item className="dropdown" draggable={false}>
          <Link
            draggable={false}
            className="link-style"
            to="/accounts/dashboard"
            onClick={() => expand(false)}
          >
            Dashboard
          </Link>
        </Nav.Item>
      </Nav>
      {navBar.includes("message_center") && (
        <Nav>
          <Nav.Item className="dropdown text-nowrap" draggable={false}>
            <Link
              draggable={false}
              to="/message_center"
              className="link-style"
              onClick={() => expand(false)}
            >
              Message Center
            </Link>
          </Nav.Item>
        </Nav>
      )}
    </>
  );
};

export default DealerAndCompanies;

const listCompany = (arr) => {
  const list = sorting.byParams(arr, "company").filter((e) => e.is_active);
  return arrayForSelect(list, "company", "id");
};
