import { formatDate } from "../functions/functions";
import { axiosHTTP } from "../Hook/UserAuthorization";

export class LogData {
  async messageLog(params) {
    const get = await axiosHTTP.get(`/v1/text-message-log/`, {
      params: {
        user: params?.user,
        company: params?.dealer,
        end_date: params
          ? params.endDate
          : formatDate(new Date(), "YYYY-MM-DD"),
        start_date: params
          ? params.startDate
          : formatDate(new Date(), "YYYY-MM-DD"),
      },
    });
    return get.data;
  }
}
