import Flatpickr from "react-flatpickr";
import { formatDate } from "../../../../functions/functions";
import "flatpickr/dist/flatpickr.css";

const DateRange = ({ id, placeholder, label, onChange, ...props }) => {
  const change = (dates) => {
    const formattedDates = dates.map((e) => formatDate([e], props.dateFormat));
    onChange(id, formattedDates);
  };

  return (
    <Flatpickr
      id={id}
      name={id}
      value={props.value}
      onReady={props.onReady}
      onChange={(date) => change(date)}
      placeholder={placeholder || label}
      className="form-control flatpickr flatpickr-input active"
      options={{
        mode: "range",
        disableMobile: true,
        dateFormat: props.optionDateFormat,
      }}
    />
  );
};

export default DateRange;

DateRange.defaultProps = {
  dateFormat: "YYYY-MM-DD",
  optionDateFormat: "Y-m-d",
};
