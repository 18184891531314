import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class ScheduleData {
  async getScheduleData() {
    const get = await axiosHTTP.get("/v1/tasks-schedule/", {
      params: { company: getCookie("company") },
    });
    return get.data;
  }

  async searchSchedule(search) {
    const find = await axiosHTTP.get("/v1/tasks-schedule/", {
      params: { company: getCookie("company"), search: search },
    });
    return find.data;
  }

  async postSchedule({ display, active }) {
    const post = await axiosHTTP.post(
      "/v1/tasks-schedule/",
      JSON.stringify({
        display: display,
        is_active: active,
        company: getCookie("company"),
      })
    );
    return post.data;
  }

  async putSchedule(id, { display, active }) {
    const put = await axiosHTTP.put(
      `/v1/tasks-schedule/${id}/`,
      JSON.stringify({
        id: id,
        display: display,
        is_active: active,
        company: getCookie("company"),
      })
    );
    return put.data;
  }

  async getScheduleInfo(id) {
    const get = await axiosHTTP.get(`/v1/tasks-schedule/${id}/`);
    return get.data;
  }

  async postScheduleDetail(id, orderId, value) {
    const post = await axiosHTTP.post(
      `/v1/tasks-schedule-detail/`,
      JSON.stringify({
        goal: value.goal,
        orderid: orderId,
        taskschedule: id,
        chapter: value.chapters,
        scorecard: value.scorecard,
        deliverable: value.deliverable,
        rated_by: Number(value.ratedBy),
        due_date_days: value.dueDays || null,
        assigned_to: Number(value.assignedTo),
        points: typeof value.points === "number" ? value.points : null,
        pass_points:
          typeof value.passPoints === "number" ? value.passPoints : null,
      })
    );
    return post.data;
  }

  async putScheduleDetail(currentId, id, orderId, value) {
    const put = await axiosHTTP.put(
      `/v1/tasks-schedule-detail/${currentId}/`,
      JSON.stringify({
        taskschedule: id,
        goal: value.goal,
        orderid: orderId,
        chapter: value.chapters,
        scorecard: value.scorecard,
        deliverable: value.deliverable,
        rated_by: Number(value.ratedBy),
        due_date_days: value.dueDays || null,
        assigned_to: Number(value.assignedTo),
        points: typeof value.points === "number" ? value.points : null,
        pass_points:
          typeof value.passPoints === "number" ? value.passPoints : null,
      })
    );
    return put.data;
  }

  async deleteScheduleDetail(id) {
    const get = await axiosHTTP.delete(
      `/v1/tasks-schedule-detail/${id}/`,
      JSON.stringify({ id })
    );
    return get.data;
  }

  async assignedSchedule(user, schedule) {
    const post = await axiosHTTP.post(
      `/v1/tasks-schedule/assign/`,
      JSON.stringify({ created_for: user, task_schedule: schedule })
    );
    return post.data;
  }

  async getScheduleDetail(taskschedule) {
    const get = await axiosHTTP.get(`/v1/tasks-schedule-detail/`, {
      params: { taskschedule },
    });
    return get.data;
  }

  async getScheduleDetailInfo(id) {
    const get = await axiosHTTP.get(`/v1/tasks-schedule-detail/${id}/`);
    return get.data;
  }

  async moveScheduleTask(task_schedule_detail_ids) {
    const post = await axiosHTTP.put(
      `/v1/task-schedule-detail-order-update/`,
      JSON.stringify({ task_schedule_detail_ids })
    );
    return post.data;
  }

  async copyTaskScheduleToCompany(value, schedule) {
    const post = await axiosHTTP.post(
      "/v1/tasks-schedule/company/clone/",
      JSON.stringify({
        schedule_id: schedule,
        company_id: value.dealership,
        copy_scorecards: value.boolean,
      })
    );
    return post.data;
  }
}
