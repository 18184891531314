/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import * as Yup from "yup";
import { Login } from "../../../api";
import { Formik, Form } from "formik";
import { Button, Col } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import { userLocalStorage } from "../../../storage/local";
import { useUserApiCalls } from "../../../Hook/UserApiCalls";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Field from "../../../CustomComponents/Field";
import NewPassword from "../NewPassword/NewPassword";
import ResetPassword from "../ResetPassword/ResetPassword";
import logo from "../../../image/logo/gearhead-2020-whitebg.gif";

const LoginPage = ({ setLogin, quote }) => {
  const { listing } = useUserApiCalls();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  // const [navigateLink, setNavigateLink] = useState("/accounts/dashboard");

  // useEffect(() => {
  //   if (location.pathname === "/message_center") {
  //     setNavigateLink("/message_center");
  //   }
  //   if (
  //     location.pathname === "/password_reset" ||
  //     location.pathname === "/" ||
  //     location.pathname.split("/")[1] === "reset-password"
  //   ) {
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  const handleLogin = async ({ email, password }) => {
    setError(false);
    setDisable(true);
    await Login.csrfToken();
    await Login.loginUser(email.toLowerCase(), password)
      .then(async (response) => {
        if (response.data[0] === "error: No good") setError(true);
        else {
          const getWelcomeMessage = await listing.welcomeMessage();
          quote(getWelcomeMessage);
          userLocalStorage.set(response.data);
          setLogin(true);
          navigate(location.pathname);
        }
      })
      .catch(() => setError(true))
      .finally(() => setDisable(false));
  };

  return (
    <div className="sign-in-form">
      <div className="content">
        <div className="mb-4">
          <Link to="/">
            <img src={logo} alt="Gearhead Training" height="100" width="100%" />
          </Link>
        </div>
        <div className="fs-2">Sign in</div>
        <Formik
          onSubmit={handleLogin}
          initialValues={{ email: "", password: "" }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Invalid email address")
              .required("Required"),
            password: Yup.string()
              .min(3, "Password should be of minimum 3 characters length")
              .required("Password is required"),
          })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Col>
                <Field.Control
                  id="email"
                  type="email"
                  label="Email"
                  autoComplete="on"
                  placeholder="Email address here"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col>
                <Field.Control
                  id="password"
                  type="password"
                  label="Password"
                  autoComplete="on"
                  placeholder="********"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </Col>
              {error && (
                <div className="text-danger py-2">
                  incorrect Email or Password or User is inactive
                </div>
              )}
              <Col className="mt-2">
                <Field.Check
                  id="remember"
                  type="checkbox"
                  label="Remember me"
                />
                <Link to="/password_reset" style={{ textDecoration: "none" }}>
                  Forgot your password?
                </Link>
              </Col>
              <Button className="mt-3" type="submit" disabled={disable}>
                Sign in
              </Button>
            </Form>
          )}
        </Formik>
        <hr />
      </div>
      <Routes>
        <Route path="/password_reset" element={<ResetPassword />} />
        <Route
          path="/reset-password/:userId/:token"
          element={<NewPassword />}
        />
      </Routes>
    </div>
  );
};
export default LoginPage;
