import React, { Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { UserAuthorization } from "./Hook/UserAuthorization";
import MainPage from "./components/MainPage/MainPage";
import ReportsRouting from "./Routing/reportsRouting";

const App = () => {
  const { Component } = UserAuthorization(MainPage);
  return (
    <BrowserRouter basename="/">
      <Suspense>
        <ReportsRouting report={Component.props.report !== undefined}>
          <Route path="*" element={Component} />
        </ReportsRouting>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
