/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import congratsImg from "../../image/points/congratulation-points.png";

const RewardPointsModal = ({ points, onClose, rewardType, user }) => {
  useEffect(() => {
    const timeoutID = setTimeout(onClose, 6000);

    return () => clearTimeout(timeoutID);
  }, []);

  return (
    <Modal className="points-reward" centered show={!!points} onHide={onClose}>
      <Modal.Header closeButton className="border-0" />
      <Modal.Body>
        <div className="reward-points-modal">
          <div>
            <img src={congratsImg} className="congrats" alt="Congratulations" />
          </div>
          <div className="fs-2">Congratulations {user.first_name}!</div>
          <div className="fs-5">
            You have earned <span className="fs-3 fw-bold">+ {points} pts</span>{" "}
            for successfully completing {rewardType || ""}.
          </div>
          <Button className="my-3" onClick={onClose}>
            Nice
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RewardPointsModal;
