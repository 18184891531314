import { axiosHTTP } from "../Hook/UserAuthorization";
import { firstDateMonth, formatDate, getCookie } from "../functions/functions";

export class SurveyData {
  async getSurvey(search) {
    const get = await axiosHTTP.get("/v1/survey/", {
      params: { search: search },
    });
    return get.data;
  }

  async getSurveyInfo(id) {
    const get = await axiosHTTP.get(`/v1/survey/${id}/`);
    return get.data;
  }

  async moveSurveyQuestion(id) {
    const post = await axiosHTTP.put(
      `/v1/survey-question-order_update/`,
      JSON.stringify({ questions_ids: id })
    );
    return post.data;
  }

  async cloneSurvey(survey) {
    const post = await axiosHTTP.post(
      "/v1/survey-clone/",
      JSON.stringify({ survey_id: survey })
    );
    return post.data;
  }

  async postSurvey({ description, display, active }) {
    const post = await axiosHTTP.post(
      "/v1/survey/",
      JSON.stringify({
        display: display,
        is_active: active,
        description: description,
        company: getCookie("company"),
      })
    );
    return post.data;
  }

  async updateSurvey(id, { description, display, active }) {
    const put = await axiosHTTP.patch(
      `/v1/survey/${id}/`,
      JSON.stringify({
        display: display,
        is_active: active,
        description: description,
      })
    );
    return put.data;
  }

  async getSurveyQuestion(id) {
    const post = await axiosHTTP.get("/v1/survey-question/", {
      params: { survey: id },
    });
    return post.data;
  }

  async deleteSurveyQuestion(id) {
    const post = await axiosHTTP.delete(`/v1/survey-question/${id}/`);
    return post.data;
  }

  async postSurveyQuestion({ question, typeField, option }, surveyId) {
    const post = await axiosHTTP.post(
      "/v1/survey-question/",
      JSON.stringify({
        survey: surveyId,
        question: question,
        type_field: typeField,
        options: option ? [option] : null,
      })
    );
    return post.data;
  }
  async editSurveyQuestion(id, question) {
    const post = await axiosHTTP.patch(
      `/v1/survey-question/${id}/`,
      JSON.stringify({ question: question })
    );
    return post.data;
  }

  async editSurveyQuestionOption(id, options) {
    const post = await axiosHTTP.patch(
      `/v1/survey-question/${id}/`,
      JSON.stringify({ options: options.length > 0 ? options : null })
    );
    return post.data;
  }

  async getCompletedSurvey() {
    const get = await axiosHTTP.get("/v1/survey-user/");
    return get.data;
  }

  async searchCompletedSurvey(params) {
    const get = await axiosHTTP.get("/v1/survey-user/", {
      params: {
        survey: params?.survey,
        created_at__lte: params
          ? params.endDate
          : formatDate(new Date(), "YYYY-MM-DD"),
        created_at__gte: params
          ? params.startDate
          : formatDate(firstDateMonth, "YYYY-MM-DD"),
      },
    });
    return get.data;
  }

  async getCompletedSurveyDetail(id) {
    const get = await axiosHTTP.get(`/v1/survey-user/${id}/`);
    return get.data;
  }

  async getCompletedSurveyAnswers(id) {
    const get = await axiosHTTP.get(`/v1/survey-answer/`, {
      params: { user_survey: id },
    });
    return get.data;
  }

  async saveSurveyCourse(data) {
    const post = await axiosHTTP.post(
      `/v1/survey-course/`,
      JSON.stringify(data)
    );
    return post.data;
  }

  async surveyLoginReport(data, content) {
    const post = await axiosHTTP.post(
      `/v1/survey-login-report/`,
      JSON.stringify({
        answers: data,
        survey_id: content.survey,
        activity: content.activity,
        login: content.action === "login",
        report: content.action === "report",
      })
    );
    return post.data;
  }

  async getAssignedSurvey(search) {
    const get = await axiosHTTP.get(`/v1/survey-assignment/`, {
      params: { search: search },
    });
    return get.data;
  }

  async getAssignedSurveyInfo(id) {
    const get = await axiosHTTP.get(`/v1/survey-assignment/${id}/`);
    return get.data;
  }

  async postSurveyAssign(value) {
    const post = await axiosHTTP.post(
      "/v1/survey-assignment/",
      JSON.stringify({
        action: value.action,
        survey: value.survey,
        end_date: value.endDate,
        start_date: value.startDate,
        is_required: value.required,
        threshold_value: Number(value.threshold),
      })
    );
    return post.data;
  }

  async putSurveyAssign(value, id) {
    const post = await axiosHTTP.patch(
      `/v1/survey-assignment/${id}/`,
      JSON.stringify({
        survey: value.survey,
        end_date: value.endDate,
        start_date: value.startDate,
        is_required: value.required,
        threshold_value: Number(value.threshold),
      })
    );
    return post.data;
  }
}
