import { axiosHTTP } from "../Hook/UserAuthorization";

export class PageData {
  async getPageData(search) {
    const get = await axiosHTTP.get("/v1/page-description/", {
      params: { search: search },
    });
    return get.data;
  }

  async getPageDataInfo(id) {
    const get = await axiosHTTP.get(`/v1/page-description/${id}/`);
    return get.data;
  }

  async putPageInfo(id, data) {
    const get = await axiosHTTP.put(`/v1/page-description/${id}/`, data, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
    });
    return get.data;
  }

  async updatePageUrl(id, video) {
    const get = await axiosHTTP.put(
      `/v1/page-description/${id}/`,
      JSON.stringify({ id: id, video_url: video })
    );
    return get.data;
  }

  async deletePageDocument(id, dock, video) {
    const get = await axiosHTTP.put(
      `/v1/page-description/${id}/`,
      JSON.stringify({
        id: id,
        document: dock,
        video_url: video,
      })
    );
    return get.data;
  }
}
