import { axiosHTTP } from "../Hook/UserAuthorization";

export class OpCodesData {
  async getCodes(search) {
    const get = await axiosHTTP.get(`/v1/menu-op-codes/`, {
      params: { search: search },
    });
    return get.data;
  }

  async getCodesInfo(id) {
    const get = await axiosHTTP.get(`/v1/menu-op-codes/${id}/`);
    return get.data;
  }

  async postCode({ code, description }) {
    const post = await axiosHTTP.post(
      `/v1/menu-op-codes/`,
      JSON.stringify({ op_code: code, op_code_description: description })
    );
    return post.data;
  }

  async putCode(id, { code, description }) {
    const patch = await axiosHTTP.patch(
      `/v1/menu-op-codes/${id}/`,
      JSON.stringify({ op_code: code, op_code_description: description })
    );
    return patch.data;
  }

  async deleteCode(id) {
    const del = await axiosHTTP.delete(`/v1/menu-op-codes/${id}/`);
    return del.data;
  }
}
