import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";
import { navBarPages } from "../functions";

export class CustomizeMenuBar {
  async getMenuBar(company, emptype, user) {
    const get = await axiosHTTP.get("v1/menu-for-user/", {
      params: {
        user_id: user,
        emp_type: emptype,
        company_id: company,
      },
    });
    return get.data;
  }

  async getNavBarSettings(user) {
    const get = await axiosHTTP.get("v1/menu-for-user/", {
      params: {
        user_id: user.user_id,
        emp_type: user.emptype_id,
        company_id: getCookie("company"),
      },
    });
    const data = get.data.length !== 0 ? get.data[0].menu_bar : navBarPages;
    return data;
  }

  async getMenuBarData(company, emptype, user) {
    const get = await axiosHTTP.get("v1/nav-bar-access/", {
      params: {
        user_id: user,
        emp_type: emptype,
        company_id: company,
      },
    });
    return get.data;
  }

  async putMenuBarData(data, menuBar) {
    const get = await axiosHTTP.put(
      `v1/nav-bar-access/${data.id}/`,
      JSON.stringify({
        id: data.id,
        user: data.user,
        company: data.company,
        emptype: data.emptype,
        is_active: data.is_active,
        menu_bar: menuBar.length === 0 ? ["dashboard"] : menuBar,
      })
    );
    return get.data;
  }

  async postNewMenuBar(company, emptype, user, menuBar) {
    const get = await axiosHTTP.post(
      "/v1/nav-bar-access/",
      JSON.stringify({
        user: user,
        company: company,
        emptype: emptype,
        menu_bar: menuBar.length === 0 ? ["dashboard"] : menuBar,
      })
    );
    return get.data;
  }
  async getInfoForNavBar(company, emptype, user) {
    const get = await axiosHTTP.post(
      "v1/information-nav-bar-access/",
      JSON.stringify({
        user: user,
        company: company,
        emp_type: emptype,
      })
    );
    return get.data;
  }
}
