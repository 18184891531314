import { Form } from "react-bootstrap";

const NumberRange = (properties) => {
  const { id, minOnChange, maxOnChange, minValue, maxValue, ...props } =
    properties;

  return (
    <div className="form-control p-0 d-flex align-items-center">
      <Form.Control
        {...props}
        value={minValue}
        placeholder="min"
        name={`${id}.min`}
        onChange={minOnChange}
      />
      <span className="mx-1">TO</span>
      <Form.Control
        {...props}
        value={maxValue}
        placeholder="max"
        name={`${id}.max`}
        onChange={maxOnChange}
      />
    </div>
  );
};

export default NumberRange;

NumberRange.defaultProps = {
  step: "1000",
  type: "number",
  autoComplete: "off",
  className: "border-0",
};
