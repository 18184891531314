const Page404 = ({ error }) => {
  return (
    <div style={{ position: "relative", height: "80vh" }}>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          maxWidth: "520px",
          width: "100%",
          lineHeight: "1.4",
          textAlign: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            height: "200px",
            margin: "0 auto 20px",
            zIndex: "-1",
          }}
        >
          <h1
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "236px",
              fontWeight: "200",
              margin: "0",
              textTransform: "uppercase",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            Oops!
          </h1>
          <h2
            style={{
              fontSize: "28px",
              fontWeight: "400",
              fontFamily: "'Montserrat', sans-serif",
              backgroundColor: "#f5f4f8",
              textTransform: "uppercase",
              padding: "10px 5px",
              margin: "auto",
              position: "absolute",
              bottom: "0",
              left: "0",
              right: "0",
            }}
          >
            {error.code ? error.message : "404 - The Page can't be found"}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Page404;
