import { BsCloudUpload } from "react-icons/bs";

const FileInput = ({ id, file, accept, onChange }) => {
  const placeholder = file
    ? file.name?.length > 25
      ? file.name?.slice(0, 25) + "..."
      : file.name
    : "Click or drag and drop file here";

  return (
    <div className="file-style">
      <div>
        <BsCloudUpload size={50} />
        <p>{placeholder}</p>
      </div>
      <input
        type="file"
        accept={accept}
        id={id || "file"}
        className="file-input"
        onChange={(e) => onChange(e.target)}
      />
    </div>
  );
};

export default FileInput;
