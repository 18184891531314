import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class PartsData {
  async getParts(search) {
    const get = await axiosHTTP.get("/v1/data-dms-part-repl-ship/", {
      params: { company: getCookie("company"), search: search },
    });
    return get.data;
  }

  async getPartInfo(id) {
    const get = await axiosHTTP.get(`/v1/data-dms-part-repl-ship/${id}/`);
    return get.data;
  }

  async deletePart(id) {
    const get = await axiosHTTP.delete(
      `/v1/data-dms-part-repl-ship/${id}/`,
      JSON.stringify({ id: id })
    );
    return get.data;
  }

  async postPart({ part, description }) {
    const get = await axiosHTTP.post(
      `/v1/data-dms-part-repl-ship/`,
      JSON.stringify({
        part_no: part,
        part_description: description,
        company: getCookie("company"),
      })
    );
    return get.data;
  }

  async putPart(id, { part, description }) {
    const get = await axiosHTTP.put(
      `/v1/data-dms-part-repl-ship/${id}/`,
      JSON.stringify({
        id: id,
        part_no: part,
        part_description: description,
      })
    );
    return get.data;
  }
}
