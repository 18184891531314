//custom arrays
// eslint-disable-next-line no-extend-native
Array.prototype.sortListAlphabetical = function () {
  const sort = (arr) => arr.sort((a, b) => a.label.localeCompare(b.label));
  sort(this);
  this.forEach((e) => e.page && sort(e.page));
  return this;
};

export const dealerSettings = [
  { id: "new_task", label: "Assign New Task", link: "/tasks/task/new" },
  {
    id: "assign_task_schedule",
    label: "Assign Task Schedule",
    link: "/tasks/task_schedule_assign",
  },
  { id: "bucket_search", label: "Buckets", link: "/tasks/bucket_search" },
  {
    label: "Courses",
    page: [
      {
        id: "course_assign",
        label: "Assign Course",
        link: "/courses/course_assign",
      },
      { id: "course_search", label: "Courses", link: "/courses/course_search" },
      {
        id: "instructor_search",
        label: "Instructors",
        link: "/courses/instructor_search",
      },
      { id: "media_search", label: "Media", link: "/courses/media_search" },
      { id: "quiz_search", label: "Quizzes", link: "/courses/quiz_search" },
    ],
  },
  {
    id: "employee_search",
    label: "Employees",
    link: "/common/employee_search",
  },
  {
    id: "message_group_search",
    label: "Messaging Channels",
    link: "/common/message_group_search",
  },
  {
    id: "parts_repl_search",
    label: "Parts",
    link: "/common/parts_repl_search",
  },
  {
    id: "op_code_group_search",
    label: "Premium Services",
    link: "/common/op_code_group_search",
  },
  {
    id: "scorecard_search",
    label: "Scorecards",
    link: "/tasks/scorecard_search",
  },
  {
    id: "task_schedule_search",
    label: "Task Schedules",
    link: "/tasks/task_schedule_search",
  },
  {
    id: "video_library_search",
    label: "Video Library",
    link: "/video_library_search",
  },
  {
    id: "team_dealer",
    label: "Teams",
    link: "common/teams",
  },
  {
    id: "folders",
    label: "Folders",
    link: "common/folders",
  },
  {
    id: "reports_schedule_list",
    label: "Schedule Reports",
    link: "/common/reports_schedule_list",
  },
  {
    id: "goals",
    label: "Goals Module",
    link: "/common/goals",
  },
  {
    id: "email_templates_search",
    label: "Email Templates",
    link: "/common/email_templates_search",
  },
  {
    id: "menu_op_codes",
    label: "Menu Op Codes",
    link: "/common/menu_op_codes",
  },
].sortListAlphabetical();

export const globalSettings = [
  {
    id: "event_schedules",
    label: "Event Schedules",
    link: "/common/event_schedules",
  },
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    id: "company_search",
    label: "Dealerships",
    link: "/company_search",
    resellers: true,
  },
  { id: "image_search", label: "Image Uploads", link: "/image_search" },
  { id: "listing_search", label: "Lists", link: "/listing_search" },
  {
    id: "notification_search",
    label: "Notifications",
    link: "/notification_search",
  },
  {
    id: "pages_descriptions",
    label: "Pages Descriptions",
    link: "/pages_descriptions",
  },
  {
    id: "reporting_structure",
    label: "Enterprise Reporting Structure",
    link: "/common/reporting_structure",
    // resellers: true,
  },
  {
    id: "general_customize_menu",
    label: "Customize Menu",
    link: "/common/general_customize_menu",
    resellers: true,
  },
  {
    id: "message_log",
    label: "Message Log",
    link: "/log/message",
  },
  {
    id: "white_labels",
    label: "White Label",
    link: "/common/white_labels",
  },
  {
    label: "Survey",
    page: [
      { id: "survey_search", label: "Survey", link: "/survey/survey_search" },
      {
        id: "survey_assign",
        label: "Assign Survey",
        link: "/survey/survey_assign",
      },
      {
        id: "survey_result",
        label: "Survey Result",
        link: "/survey/survey_result",
      },
    ],
  },
  {
    id: "todo_templates",
    label: "ToDo Templates",
    link: "/templates/todo",
  },
].sortListAlphabetical();

export const reportsList = [
  { id: "ros", label: "ROs", link: "/reporting/report_ro_search" },
  {
    newTab: true,
    id: "leaderboard",
    label: "Leaderboard",
    link: "/reporting/leaderboard",
  },
  {
    id: "leaderboard_details",
    label: "Leaderboard Details",
    link: "/reporting/leaderboard_details",
  },
  {
    id: "open_ro_summary",
    label: "Open RO Summary",
    link: "/reporting/report_open_ro_summary",
  },
  {
    id: "op_code_utilization",
    label: "Op Code Utilization",
    link: "/reporting/report_op_codes_utilized",
  },
  {
    id: "parts_replenishment",
    label: "Parts Replenishment Report",
    link: "/reporting/report_parts_replenishment",
  },
  {
    id: "tasks_progress_report",
    label: "Tasks Progress Report",
    link: "/report_progress_report",
  },
  {
    id: "course_completed",
    label: "Courses Assigned",
    link: "/report_courses_assigned",
  },
  {
    newTab: true,
    id: "unmatched_employee_report",
    label: "Unmatched Employee Report- DMS",
    link: "/reporting/unmatched_employee_report",
  },
  {
    label: "Premium",
    page: [
      {
        id: "premium_service_report_by_Advisor",
        label: "Premium Service Report by Advisor",
        link: "/reporting/report_premium_svcs_by_advisor",
      },
      {
        id: "premium_service_report_by_op_code",
        label: "Premium Service Report by Op Code",
        link: "/reporting/report_premium_svcs_by_op_code",
      },
      {
        id: "premium_service_report_by_technician",
        label: "Premium Service Report by Technician",
        link: "/reporting/report_premium_svcs_by_technician",
      },
      {
        id: "premium_service_gross_profit_report",
        label: "Premium Service Gross Profit Report",
        link: "/reporting/report_premium_svcs_gross_profit",
      },
      {
        id: "premium_service_ranking_summary_report",
        label: "Premium Service Ranking Summary Report by Advisor",
        link: "/reporting/report_premium_svcs_ranking_summary",
      },
      {
        id: "premium_service_ranking_summary_report_technician",
        label: "Premium Service Ranking Summary Report by Technician",
        link: "/reporting/report_premium_svcs_ranking_summary_technician",
      },
      {
        id: "premium_service_trend_advisor",
        label: "Premium Service Trend by Advisor",
        link: "/reporting/premium_service_trend_advisor",
      },
    ],
  },
  {
    label: "Performance",
    page: [
      {
        id: "technician_performance_report_closed_ROs",
        label: "Performance Report by Technician",
        link: "/reporting/report_tech_performance_closed_ros",
      },
      {
        id: "service_advisor_performance_report_closed_ROs",
        label: "Performance Report by Advisor",
        link: "/reporting/report_svc_adv_performance_closed_ros",
      },
      {
        id: "performance_report_technician_ranking_summary",
        label: "Performance Report by Technician Ranking Summary",
        link: "/reporting/report_tech_performance_summary",
      },
      {
        id: "performance_trend_report_technician",
        label: "Performance Trend Report by Technician",
        link: "/reporting/performance_trend_report_technician",
      },
    ],
  },
  {
    label: "Enterprise",
    page: [
      {
        id: "enterprise_premium_report",
        label: "Enterprise Premium Report by Dealer",
        link: "/enterprise/premium_report",
      },
      {
        id: "enterprise_premium_summary_report",
        label: "Enterprise Premium Service Ranking Summary Report",
        link: "/enterprise/enterprise_premium_summary",
      },
      {
        id: "enterprise_premium_summary_advisor",
        label: "Enterprise Premium Service Ranking Summary by Advisor Report",
        link: "/enterprise/enterprise_premium_summary_advisor",
      },
      {
        id: "enterprise_premium_summary_technician",
        label:
          "Enterprise Premium Service Ranking Summary by Technician Report",
        link: "/enterprise/enterprise_premium_summary_technician",
      },
      {
        id: "enterprise_premium_gross_profit",
        label: "Enterprise Premium Gross Profit Report",
        link: "/enterprise/enterprise_premium_gross_profit",
      },
      {
        id: "enterprise_performance_report",
        label: "Enterprise Performance Report by Dealer",
        link: "/enterprise/performance_report",
      },
    ],
  },
].sortListAlphabetical();

export const actionsList = [
  { id: "forms", label: "Forms", link: "/common/form_search" },
  { id: "search_task", label: "Search Tasks", link: "/tasks/task_search" },
  { id: "search_todo", label: "Search ToDos", link: "/tasks/todo_search" },
  {
    id: "video_library",
    label: "Video Library",
    link: "/common/video_library_play",
  },
  {
    id: "call_reminder_search",
    label: "Search Reminder Log",
    link: "/common/call_reminder_search",
  },
].sortListAlphabetical();

export const callReminderList = [
  {
    id: "call_reminder",
    label: "Add New Reminder",
    link: "/common/call_reminder/new",
  },
  {
    id: "call_reminder_search",
    label: "View Reminder Log",
    link: "/common/call_reminder_search",
  },
].sortListAlphabetical();

const getIdPage = (arr) =>
  arr.map((e) => (e.page ? e.page.map((page) => page.id) : e.id));

export const navBarPages = [
  "message_center",
  ...getIdPage(actionsList),
  ...getIdPage(reportsList),
  ...getIdPage(dealerSettings),
  ...getIdPage(callReminderList),
].flat();

export const courseStatusArray = [
  { value: 1, label: "Published" },
  { value: 2, label: "Draft" },
  { value: 3, label: "Inactive" },
];

export const courseTypeArray = [
  { value: "Standard", label: "Standard" },
  { value: "Daily Drip", label: "Daily Drip" },
];

export const questionType = [
  { value: "SA", label: "Single Answer" },
  { value: "MA", label: "Multiple Answers" },
];
export const publishedStatus = [
  { value: true, label: "Published" },
  { value: false, label: "Draft" },
];

export const dataRangeArray = [
  { value: 1, label: "Last 30 Days" },
  { value: 2, label: "Last 90 Days" },
  { value: 3, label: "Full History" },
];

export const dataRangeMessage = [
  { value: 1, label: "Last 3 Days" },
  { value: 2, label: "Last 7 Days" },
  { value: 3, label: "Last 30 Days" },
  { value: 4, label: "All Messages" },
];

export const taskStatusArray = [
  { value: 1, label: "All Tasks" },
  { value: 2, label: "Work Not Yet Completed" },
  { value: 7, label: "Work Not Yet Completed with a Due Date Only" },
  { value: 3, label: "Completed But Not Yet Rated" },
  { value: 4, label: "Completed and Rated" },
  { value: 5, label: "Rated and Passed" },
  { value: 6, label: "Rated and Failed" },
];

export const instructions = {
  kpi: "The deliverable for this task requires you to record a video or a phone call so it can be evaluated. Click on the action below to complete your task. Once completed, click the Mark Task Completed.",
  liveDemonstration:
    "  To complete this task, you must perform a live presentation in front of your mentor. Once you have completed your presentation, you may click the Mark Task Completed button.",
  recording:
    "  The deliverable for this task requires you to record a video or a phone call so it can be evaluated. Click on the action below to complete your task. Once completed, click the Mark Task Completed.",
  zoomCall:
    "The deliverable for this tasks requires that you demonstrate a skill via a Zoom call. To schedule the call, click the button below. Once the call has been completed, you can click Mark Task Completed",
  MPIPresentation: "",
};

export const timeZoneArray = [
  { value: -2, label: "Pacific" },
  { value: -1, label: "Mountain" },
  { value: 0, label: "Central" },
  { value: 1, label: "Eastern" },
];

export const surveyAssignEvents = [
  { value: "login", label: "Login" },
  { value: "report", label: "Report" },
];

export const boolArray = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const levelArray = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
];

export const contentIdForChapter = {
  quiz: "fd5ffeaf-e96f-4b88-8588-59715f3a7f41",
  text: "53887c83-b119-4f96-8d0a-7d543807ec61",
  audio: "8e384ef3-1a44-4e0f-9096-49d49b271c95",
  video: "68cddf78-8b44-4e09-9557-333fd5038ca6",
  survey: "3d7f41b7-9e6e-4268-bdb5-7d3d9c01e7a7",
  documents: "02e8785e-470f-4aee-8055-b8d037282745",
};

export const payMethod = [
  { value: "1", label: "CP Only" },
  { value: "3", label: "Internal Only" },
  { value: "2", label: "Warranty Only" },
];

export const scheduledArray = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

export const daysOfWeek = [
  { label: "Sunday", value: "0" },
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
];

export const surveyFieldsTypes = [
  { value: 4, label: "Rating" },
  { value: 1, label: "Single Select" },
  { value: 2, label: "Multi Select" },
  { value: 3, label: "Comment" },
];

export const reportsArray = [
  { value: "report_closed_ro", label: "Closed RO" },
  { value: "report_open_ro", label: "Open RO" },
  { value: "report_open_ro_summary", label: "Open RO Summary" },
  { value: "report_op_code_utilization", label: "OP Code Utilization" },
  { value: "report_parts_replenishment", label: "Parts Replenishment Report" },
  {
    value: "report_premium_service_report_by_advisor",
    label: "Premium Service Report by Advisor",
  },
  {
    value: "report_premium_service_report_by_op_code",
    label: "Premium Service Report by Op Code",
  },
  {
    value: "report_premium_service_report_by_technician",
    label: "Premium Service Report by Technician",
  },
  {
    value: "report_premium_service_gross_profit_report",
    label: "Premium Service Gross Profit Report",
  },
  {
    value: "report_premium_service_ranking_summary_report",
    label: "Premium Service Ranking Summary Report by Advisor",
  },
  {
    value: "report_premium_service_ranking_summary_report_technician",
    label: "Premium Service Ranking Summary Report by Technician",
  },
  {
    value: "service_advisor_performance_report_closed_ros",
    label: "Performance Report by Advisor",
  },
  { value: "report_tasks_progress_report", label: "Tasks Progress Report" },
  {
    value: "technician_performance_report_closed_ros",
    label: "Performance Report by Technician",
  },
  { value: "course_assigned", label: "Courses Assigned" },
  {
    value: "enterprise_performance_report",
    label: "Enterprise Performance Report by Dealer",
  },
  {
    value: "enterprise_premium_svcs_ranking_summary_report",
    label: "Enterprise Premium Service Ranking Summary Report",
  },
  {
    value: "enterprise_premium_service_gross_profit_report",
    label: "Enterprise Premium Service Gross Profit Report",
  },
  {
    value: "enterprise_premium_report",
    label: "Enterprise Premium Report by Dealer",
  },
  {
    value: "enterprise_premium_svcs_ranking_summary_report_adv",
    label: "Enterprise Premium Service Ranking Summary By Advisor Report",
  },
  {
    value: "enterprise_premium_svcs_ranking_summary_report_tech",
    label: "Enterprise Premium Service Ranking Summary By Technician Report",
  },
  {
    value: "performance_report_technician_ranking_summary",
    label: "Performance Report by Technician Ranking Summary",
  },
  {
    value: "premium_service_trend_advisor",
    label: "Premium Service Trend by Advisor",
  },
  {
    value: "performance_trend_report_technician",
    label: "Performance Trend Report by Technician",
  },
  { value: "report_leaderboard_details", label: "Leaderboard Details" },
].sortListAlphabetical();

export const timeRanges = [
  { label: "Yesterday", value: "yesterday" },
  { label: "Today", value: "today" },
  { label: "This Week", value: "this_week" },
  { label: "Last Week", value: "last_week" },
  { label: "Previous Month", value: "previous_month", trend: true },
  { label: "This Month", value: "this_month" },
  { label: "Last Month", value: "last_month" },
  { label: "Previous Year", value: "previous_year", trend: true },
];

export const arrayUrl = [
  { page_id: "dashboard", url: "accounts/dashboard" },
  { page_id: "message_center", url: "message_center" },
  { page_id: "forms", url: "common/form_search" },
  { page_id: "form_upload", url: "common/form" },
  { page_id: "search_task", url: "tasks/task_search" },
  { page_id: "task_view", url: "tasks/task_view" },
  { page_id: "video_library", url: "common/video_library_play" },
  { page_id: "ros", url: "reporting/report_ro_search" },
  { page_id: "report_closed_ro", url: "reporting/report_closed_ros_search" },
  {
    page_id: "report_closed_ro_detail",
    url: "reporting/report_closed_ros_detail",
  },
  { page_id: "report_open_ro", url: "reporting/report-open-ro-search" },
  { page_id: "report_open_ro_detail", url: "reporting/report-open-ro-detail" },
  { page_id: "open_ro_summary", url: "reporting/report_open_ro_summary" },
  {
    page_id: "report_open_ro_summary",
    url: "reporting/report-open-ro-summary",
  },
  { page_id: "op_code_utilization", url: "reporting/report_op_codes_utilized" },
  {
    page_id: "report_op_code_utilization",
    url: "reporting/report_op_codes_utilized_report",
  },
  {
    page_id: "parts_replenishment",
    url: "reporting/report_parts_replenishment",
  },
  {
    page_id: "report_parts_replenishment",
    url: "reporting/report_parts_replenishment_report",
  },
  {
    page_id: "premium_service_report_by_Advisor",
    url: "reporting/report_premium_svcs_by_advisor",
  },
  {
    page_id: "report_premium_service_report_by_Advisor",
    url: "reporting/report_premium_svcs_by_advisor_report",
  },
  {
    page_id: "premium_service_report_by_op_code",
    url: "reporting/report_premium_svcs_by_op_code",
  },
  {
    page_id: "report_premium_service_report_by_op_code",
    url: "reporting/report_premium_svcs_by_op_code_report",
  },
  {
    page_id: "premium_service_report_by_technician",
    url: "reporting/report_premium_svcs_by_technician",
  },
  {
    page_id: "report_premium_service_report_by_technician",
    url: "reporting/report_premium_svcs_by_technician_report",
  },
  {
    page_id: "premium_service_gross_profit_report",
    url: "reporting/report_premium_svcs_gross_profit",
  },
  {
    page_id: "report_premium_service_gross_profit_report",
    url: "reporting/report_premium_svcs_gross_profit_report",
  },
  {
    page_id: "premium_service_ranking_summary_report",
    url: "reporting/report_premium_svcs_ranking_summary",
  },
  {
    page_id: "report_premium_service_ranking_summary_report",
    url: "reporting/report_premium_svcs_ranking_summary_report",
  },
  { page_id: "report_mpi", url: "reporting/report_mpi_report" },
  {
    page_id: "service_advisor_performance_report_closed_ROs",
    url: "reporting/report_svc_adv_performance_closed_ros",
  },
  {
    page_id: "report_service_advisor_performance_report_closed_ROs",
    url: "reporting/report-svc-adv-performance-closed-ros-report",
  },
  {
    page_id: "report_detail_service_advisor_performance_report_closed_ROs",
    url: "reporting/report_svc_adv_performance_closed_ros_drill_down",
  },
  { page_id: "tasks_progress_report", url: "report_progress_report" },
  {
    page_id: "report_tasks_progress_report",
    url: "reporting/report_table_progress",
  },
  {
    page_id: "course_completed",
    url: "report_courses_assigned",
  },
  {
    page_id: "technician_performance_report_closed_ROs",
    url: "reporting/report_tech_performance_closed_ros",
  },
  {
    page_id: "report_technician_performance_report_closed_ROs",
    url: "reporting/report_svc_adv_performance_closed_ros_report",
  },
  {
    page_id: "report_detail_technician_performance_report_closed_ROs",
    url: "reporting/report_tech_performance_closed_ros_drill_down",
  },
  { page_id: "enterprise_premium_report", url: "enterprise/premium_report" },
  { page_id: "report_premium", url: "enterprise/report_premium" },
  {
    page_id: "enterprise_performance_report",
    url: "enterprise/performance_report",
  },
  { page_id: "report_performance", url: "enterprise/report_performance" },
  { page_id: "new_task", url: "tasks/task" },
  { page_id: "assign_task_schedule", url: "tasks/task_schedule_assign" },
  { page_id: "bucket_search", url: "tasks/bucket_search" },
  { page_id: "bucket_detail", url: "tasks/bucket" },
  { page_id: "course_assign", url: "courses/course_assign" },
  { page_id: "course_search", url: "courses/course_search" },
  { page_id: "course_info", url: "courses/course" },
  { page_id: "instructor_search", url: "courses/instructor_search" },
  { page_id: "instructor_detail", url: "courses/instructor" },
  { page_id: "media_search", url: "courses/media_search" },
  { page_id: "media_detail", url: "courses/media" },
  // { page_id: "course_list", url: "courses/course_list" },
  { page_id: "course_view", url: "courses/course_view" },
  { page_id: "quiz_search", url: "courses/quiz_search" },
  { page_id: "quiz_Detail", url: "courses/quiz" },
  { page_id: "employee_search", url: "common/employee_search" },
  { page_id: "employee_add_new", url: "common/employee_new" },
  { page_id: "employee_settings", url: "common/employee" },
  { page_id: "employee_integration", url: "common/employee_integration_edit" },
  {
    page_id: "employee_notification",
    url: "common/employee_notification_edit",
  },
  { page_id: "message_group_search", url: "common/message_group_search" },
  { page_id: "message_group_detail", url: "common/message_group" },
  { page_id: "parts_repl_search", url: "common/parts_repl_search" },
  { page_id: "parts_repl_detail", url: "common/parts" },
  { page_id: "op_code_group_search", url: "common/op_code_group_search" },
  { page_id: "op_code_group_detail", url: "common/op_code_group" },
  { page_id: "op_code_detail", url: "common/op_code" },
  { page_id: "scorecard_search", url: "tasks/scorecard_search" },
  { page_id: "scorecard_info", url: "tasks/scorecard" },
  { page_id: "scorecard_detail_info", url: "tasks/scorecard_detail" },
  { page_id: "scorecard_detail_preview", url: "tasks/scorecard_preview" },
  { page_id: "task_schedule_search", url: "tasks/task_schedule_search" },
  { page_id: "task_schedule_info", url: "tasks/task_schedule" },
  { page_id: "task_schedule_detail_info", url: "tasks/task_schedule_detail" },
  { page_id: "video_library_search", url: "video_library_search" },
  { page_id: "video_library_detail", url: "video_library/new" },
  { page_id: "company_search", url: "company_search" },
  { page_id: "company_detail", url: "common/company" },
  { page_id: "company_integration", url: "common/company_integration" },
  { page_id: "image_search", url: "image_search" },
  { page_id: "image_upload", url: "image_new" },
  { page_id: "email_templates_search", url: "common/email_templates_search" },
  { page_id: "email_templates", url: "common/email_templates" },
  { page_id: "send_email_templates", url: "common/send_email_templates" },
  { page_id: "image_templates", url: "common/image_templates" },
  { page_id: "listing_search", url: "listing_search" },
  { page_id: "listing_detail", url: "common/listing" },
  { page_id: "notification_search", url: "notification_search" },
  { page_id: "notification_detail", url: "common/notification" },
  { page_id: "call_reminder", url: "common/call_reminder" },
  { page_id: "call_reminder_search", url: "common/call_reminder_search" },
  { page_id: "general_customize_menu", url: "common/general_customize_menu" },
  { page_id: "pages_descriptions", url: "pages_descriptions" },
  { page_id: "pages_descriptions_detail", url: "common/pages_descriptions" },
  { page_id: "reports_schedule_list", url: "common/reports_schedule_list" },
  { page_id: "reports_schedule_criteria", url: "common/reports_schedule" },
  {
    page_id: "enterprise_premium_summary_report",
    url: "enterprise/enterprise_premium_summary",
  },
  {
    page_id: "enterprise_premium_svcs_ranking_summary_report",
    url: "enterprise/enterprise_premium_svcs_ranking_summary_report",
  },
  {
    page_id: "enterprise_premium_gross_profit",
    url: "enterprise/enterprise_premium_gross_profit",
  },
  {
    page_id: "enterprise_premium_gross_profit_report",
    url: "enterprise/enterprise_premium_gross_profit_report",
  },
  {
    page_id: "enterprise_premium_summary_advisor",
    url: "enterprise/enterprise_premium_summary_advisor",
  },
  {
    page_id: "enterprise_premium_svcs_ranking_summary_advisor_report",
    url: "enterprise/enterprise_premium_svcs_ranking_summary_advisor_report",
  },
  { page_id: "team_dealer", url: "common/teams" },
  { page_id: "team_dealer_info", url: "common/team_info" },
  { page_id: "event_schedules", url: "common/event_schedules" },
  { page_id: "event_schedules_detail", url: "common/event_schedules_detail" },
  { page_id: "folders", url: "common/folders" },
  { page_id: "folder_info", url: "common/folder_info" },
  { page_id: "folder_default_info", url: "common/folder_default_info" },
  { page_id: "white_labels", url: "common/white_labels" },
  { page_id: "white_label_info", url: "common/white_label_info" },
  { page_id: "goals", url: "common/goals" },
  { page_id: "reporting_structure", url: "common/reporting_structure" },
  { page_id: "structure_info", url: "common/structure" },
  {
    page_id: "report_complete_course_detail",
    url: "report_courses_assigned/detail",
  },
  { page_id: "survey_search", url: "survey/survey_search" },
  { page_id: "survey_detail", url: "survey/survey_detail" },
  { page_id: "survey_assign", url: "survey/survey_assign" },
  { page_id: "survey_assign_detail", url: "survey/survey_assign_detail" },
  { page_id: "survey_result", url: "survey/survey_result" },
  { page_id: "survey_result_detail", url: "survey/survey_result_detail" },
  {
    page_id: "premium_service_ranking_summary_report_technician",
    url: "reporting/report_premium_svcs_ranking_summary_technician",
  },
  {
    page_id: "report_premium_svcs_ranking_summary_report_technician",
    url: "reporting/report_premium_svcs_ranking_summary_report_technician",
  },
  { page_id: "message_log", url: "log/message" },
  {
    page_id: "enterprise_premium_summary_technician",
    url: "enterprise/enterprise_premium_summary_technician",
  },
  {
    page_id: "enterprise_premium_svcs_ranking_summary_technician_report",
    url: "enterprise/enterprise_premium_svcs_ranking_summary_technician_report",
  },
  { page_id: "search_todo", url: "tasks/todo_search" },
  { page_id: "new_todo", url: "tasks/todo" },
  {
    page_id: "performance_report_technician_ranking_summary",
    url: "reporting/report_tech_performance_summary",
  },
  {
    page_id: "report_performance_tech_rank_summary",
    url: "reporting/report_performance_tech_rank_summary",
  },
  {
    page_id: "unmatched_employee_report",
    url: "reporting/unmatched_employee_report",
  },
  {
    page_id: "report_tech_performance_closed_ros_drill_down_by_date",
    url: "reporting/report_tech_performance_closed_ros_drill_down_by_date",
  },
  {
    page_id: "performance_trend_report_technician",
    url: "reporting/performance_trend_report_technician",
  },
  {
    page_id: "report_performance_by_technician_trending",
    url: "reporting/report_performance_by_technician_trending",
  },
  {
    page_id: "premium_service_trend_advisor",
    url: "reporting/premium_service_trend_advisor",
  },
  {
    page_id: "report_premium_svcs_by_advisor_trending",
    url: "reporting/report_premium_svcs_by_advisor_trending",
  },
  { page_id: "todo_templates", url: "templates/todo" },
  { page_id: "todo_templates_settings", url: "templates/todo_edit" },
  { page_id: "menu_op_codes", url: "common/menu_op_codes" },
  { page_id: "menu_op_code_settings", url: "common/menu_op_code_settings" },
  { page_id: "leaderboard", url: "reporting/leaderboard" },
  { page_id: "leaderboard_details", url: "reporting/leaderboard_details" },
  {
    page_id: "leaderboard_details_report",
    url: "reporting/leaderboard_details_report",
  },
];

export const emailTemplatesTokens = [
  { value: "{{firstName}}", label: "Employee First Name" },
  { value: "{{lastName}}", label: "Employee Last Name" },
  { value: "{{email}}", label: "Employee Email Address" },
  { value: "{{password}}", label: "Employee Password" },
  { value: "{{manager}}", label: "Employee Manager" },
  { value: "{{dealershipName}}", label: "Dealership Name" },
  { value: "{{taskCreationDate}}", label: "Task Creation Date" },
  { value: "{{taskDescription}}", label: "Task Description" },
  { value: "{{taskDeliverable}}", label: "Task Deliverable" },
  { value: "{{taskCompletionDate}}", label: "Task Completion Date" },
  // { value: "{{courseTitle}}", label: "Course Title" },
  { value: "{{courseAssignmentDate}}", label: "Course Assignment Date" },
  { value: "{{courseDescription}}", label: "Course Description" },
  { value: "{{courseStatus}}", label: "Course Status" },
  {
    value: "{{courseCompletionPercentage}}",
    label: "Course Completion Percentage",
  },
  { value: "{{courseCompletionDate}}", label: "Course Completion Date" },
];

export const departmentsList = [
  { label: "Body Shop", value: "Body Shop" },
  { label: "Parts", value: "Parts" },
  { label: "Service", value: "Service" },
];

export const pointsTypes = [
  { value: "content", label: "Content Completed" },
  { value: "course", label: "Courses Completed" },
  { value: "login", label: "Logins" },
  { value: "task", label: "Tasks Completed" },
  { value: "task_pass", label: "Tasks Passed" },
];

export const notificationOption = [
  { value: "NONE", label: "None" },
  { value: "TEXT", label: "Text Only" },
  { value: "EMAIL", label: "Email Only" },
  { value: "BOTH", label: "Both" },
];
