const Label = ({ children, htmlFor, labelContent, className }) => {
  const Tag = htmlFor ? "label" : "div";
  return (
    <Tag htmlFor={htmlFor} className={`form-label ${className || ""}`}>
      <>{children}</> {labelContent}
    </Tag>
  );
};

export default Label;

Label.defaultProps = { htmlFor: "", labelContent: null };
