import React from "react";
import { errorMessageForm } from "../../../functions/functions";
import Field from "../../Field";

const MultiAnswer = ({ questionData, formik, isDisable }) => {
  const handleCheckboxChange = (option, isChecked) => {
    const selectedOptions = formik.values[questionData.id] || [];

    isChecked
      ? formik.setFieldValue(questionData.id, [...selectedOptions, option])
      : formik.setFieldValue(
          questionData.id,
          selectedOptions.filter((selectedOption) => selectedOption !== option)
        );
  };

  return (
    <div className="mb-2">
      <Field.Label>{questionData.question}</Field.Label>
      {questionData.options && questionData.options.length > 0 ? (
        <>
          {questionData.options.map((option, optionIndex) => (
            <div key={optionIndex}>
              <Field.Check
                type="checkbox"
                value={option}
                label={option}
                disabled={isDisable}
                name={`checkbox-${questionData.id}`}
                id={`checkbox-${questionData.id}-${optionIndex}`}
                checked={formik.values[questionData.id]?.includes(option)}
                onChange={(e) => handleCheckboxChange(option, e.target.checked)}
              />
            </div>
          ))}
          {errorMessageForm(questionData.id)}
        </>
      ) : (
        <div className="text-center">Sorry, something went wrong</div>
      )}
    </div>
  );
};

export default MultiAnswer;
