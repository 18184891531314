import Field from "../../Field";

const Comment = ({ questionData, formik, isDisable }) => {
  return (
    <div className="mb-2">
      <Field.Control
        id={questionData.id}
        disabled={isDisable}
        placeholder="Comment"
        label={questionData.question}
        value={formik.values[questionData.id]}
        onChange={(e) => formik.setFieldValue(questionData.id, e.target.value)}
      />
    </div>
  );
};

export default Comment;
