import { axiosHTTP } from "../Hook/UserAuthorization";

export class AIData {
  async generateQuizByTranscription(values, media_id) {
    const get = await axiosHTTP.post(
      `/v1/ai-quiz-generate/`,
      JSON.stringify({
        media_id,
        random_question: values.random_question,
        multiple_choice_question: values.multiple,
        true_or_false_question: values.true_or_false,
      })
    );
    return get.data;
  }


  async saveGenerateQuiz(json_data) {
    const get = await axiosHTTP.post(
      `/v1/ai-quiz-save/`,
      JSON.stringify({ json_data })
    );
    return get.data;
  }

  async descriptionGenerate(data) {
    const get = await axiosHTTP.post(
      `/v1/ai-prompt/`,
      JSON.stringify({ ...data })
    );
    return get.data;
  }

  async fileSummarize(download_id) {
    const get = await axiosHTTP.post(
      `/v1/ai-file-summary/`,
      JSON.stringify({ download_id })
    );
    return get.data;
  }
}
