/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef } from "react";
import { Button, Dropdown, Toast, ToastContainer } from "react-bootstrap";
import { BsFileEarmark, BsGrid, BsQuestionLg } from "react-icons/bs";
import { PagesData, Survey, User } from "../../api/index";
import { openInNewTab } from "../../functions/functions";
import { RiQuestionnaireFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { arrayUrl } from "../../functions";
import SurveyReviewsModal from "../SurveyReviewsSystem/SurveyReviewsModal";

const ButtonHelp = ({ userPermission, returnUser }) => {
  const location = useLocation();
  const [page, setPage] = useState();
  const [permission, setPermission] = useState();
  const [pagesData, setPagesData] = useState([]);
  const [description, setDescription] = useState();
  const [showSurvey, setShowSurvey] = useState({ show: false });

  useEffect(() => {
    const getSurvey = async (surveyDetail) => {
      const getSurvey = await Survey.getSurveyQuestion(surveyDetail.survey);
      setShowSurvey({
        show: true,
        survey: getSurvey,
        skipFunc: skipSurvey,
        content: surveyDetail,
        completeFunc: completeSurvey,
        required: surveyDetail.required,
      });
    };
    const userPermissions = async () => {
      const permissionUser = await User.getUserPermission();
      permissionUser.survey?.report && getSurvey(permissionUser.survey?.report);
      returnUser && returnUser(permissionUser);
      setPermission(permissionUser);
    };
    if (userPermission) {
      setPermission(userPermission);
      userPermission.survey?.login && getSurvey(userPermission.survey?.login);
    } else userPermissions();
  }, [userPermission]);

  const completeSurvey = async (data, content) =>
    await Survey.surveyLoginReport(data, content).then(() =>
      setShowSurvey({ show: false })
    );

  const skipSurvey = async (content) =>
    await Survey.surveyLoginReport(undefined, content).then(() =>
      setShowSurvey({ show: false })
    );

  useEffect(() => {
    const pageId = arrayUrl.find(
      (e) => e.url === location.pathname.split("/").slice(1, 3).join("/")
    )?.page_id;
    pageId !== page && setDescription();
    setPage(pageId);
  }, [location.pathname]);

  useEffect(() => {
    const getDescription = async () => {
      const checkData = pagesData.find((e) => e.id === page);
      const data = checkData
        ? checkData
        : await PagesData.getPageDataInfo(page);
      !checkData && setPagesData([...pagesData, data]);
      setDescription(data);
    };
    page && getDescription();
  }, [page]);

  return (
    <>
      {description && permission && (
        <>
          {(description.document ||
            description.video_url ||
            permission.is_staff) && (
            <ToastContainer
              position="bottom-end"
              className="position-fixed shadow-none m-4 no-print"
            >
              <Toast className="w-auto rounded-circle">
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                  <Dropdown.Menu>
                    {description.document && (
                      <Dropdown.Item
                        onClick={() => openInNewTab(description.document)}
                      >
                        <BsFileEarmark /> Document
                      </Dropdown.Item>
                    )}
                    {description.video_url && (
                      <Dropdown.Item
                        onClick={() => openInNewTab(description.video_url)}
                      >
                        <BsQuestionLg /> Help
                      </Dropdown.Item>
                    )}
                    {permission.is_staff && (
                      <Dropdown.Item
                        onClick={() =>
                          openInNewTab(
                            `/common/pages_descriptions/${description.id}`
                          )
                        }
                      >
                        <BsGrid /> Settings
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Toast>
            </ToastContainer>
          )}
        </>
      )}
      <SurveyReviewsModal showModal={showSurvey} />
    </>
  );
};

export default ButtonHelp;

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    variant="success"
    className="rounded-circle fs-6 opacity-50"
  >
    {children}
    <RiQuestionnaireFill />
  </Button>
));
