import Flatpickr from "react-flatpickr";
import { formatDate } from "../../../../functions/functions";
import "flatpickr/dist/flatpickr.css";

const DefaultDate = ({ id, label, placeholder, onChange, ...props }) => {
  const change = (date) =>
    onChange(id, date.length === 0 ? "" : formatDate(date, props.dateFormat));

  return (
    <Flatpickr
      id={id}
      name={id}
      value={props.value}
      onReady={props.onReady}
      onChange={(date) => change(date)}
      placeholder={placeholder || label}
      className="form-control flatpickr flatpickr-input active"
      options={{
        disableMobile: true,
        minDate: props.minDate,
        dateFormat: props.optionDateFormat,
      }}
    />
  );
};

export default DefaultDate;

DefaultDate.defaultProps = {
  dateFormat: "YYYY-MM-DD",
  optionDateFormat: "Y-m-d",
};
