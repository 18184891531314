import { Editor } from "react-draft-wysiwyg";
import { useField } from "formik";

const DefaultEditor = ({ name }) => {
  const [field, , helpers] = useField(name);

  return (
    <div className="editor">
      <Editor
        wrapperId={name}
        editorState={field.value}
        editorClassName="editor-class"
        wrapperClassName="demo-wrapper"
        onBlur={() => helpers.setTouched(true)}
        onEditorStateChange={(value) => helpers.setValue(value)}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "emoji",
            "remove",
            "history",
          ],
          list: { inDropdown: true },
          inline: { inDropdown: true },
          history: { inDropdown: true },
          textAlign: { inDropdown: true },
        }}
      />
    </div>
  );
};

export default DefaultEditor;
