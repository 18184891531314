import { axiosHTTP } from "../Hook/UserAuthorization";

export class WhiteLabelData {
  async getWhiteLabels(search) {
    const get = await axiosHTTP.get(`/v1/company-type/`, {
      params: { search: search },
    });
    return get.data;
  }

  async getWhiteLabelInfo(id) {
    const get = await axiosHTTP.get(`/v1/company-type/${id}/`);
    return get.data;
  }

  async postWhiteLabel({ name, active, logo }) {
    if (typeof logo === "object") {
      const form = new FormData();
      form.append("logo", logo);
      form.append("display", name);
      form.append("is_active", active);
      const post = await axiosHTTP.post("/v1/company-type/", form, {
        headers: {
          "Content-Type":
            "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
        },
      });
      return post.data;
    } else {
      const get = await axiosHTTP.post(
        "/v1/company-type/",
        JSON.stringify({
          display: name,
          is_active: active,
          logo: logo || null,
        })
      );
      return get.data;
    }
  }

  async putWhiteLabel({ name, active, logo }, id) {
    if (typeof logo === "object") {
      const form = new FormData();
      form.append("id", id);
      form.append("logo", logo);
      form.append("display", name);
      form.append("is_active", active);
      const post = await axiosHTTP.put(`/v1/company-type/${id}/`, form, {
        headers: {
          "Content-Type":
            "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
        },
      });
      return post.data;
    } else {
      const get = await axiosHTTP.patch(
        `/v1/company-type/${id}/`,
        JSON.stringify({
          id: id,
          display: name,
          is_active: active,
        })
      );
      return get.data;
    }
  }

  async deleteWhiteLabelImage(id) {
    const get = await axiosHTTP.patch(
      `/v1/company-type/${id}/`,
      JSON.stringify({ id: id, logo: null })
    );
    return get.data;
  }
}
