import { ErrorMessage } from "formik";
import Field from "..";
import Time from "./Time";
import DateRange from "./DateRange";
import MonthRange from "./MonthRange";
import DefaultDate from "./DefaultDate";
import DayAndMonth from "./DayAndMonth";

const Date = ({ showLabel, labelContent, noError, ...props }) => {
  return (
    <>
      {showLabel && (
        <Field.Label labelContent={labelContent}>{props.label}</Field.Label>
      )}
      <FieldDate {...props} />
      {!noError && (
        <div className="text-danger">
          {props.id && <ErrorMessage name={props.id} />}
        </div>
      )}
    </>
  );
};

export default Date;

Date.defaultProps = {
  id: null,
  label: "",
  noError: false,
  type: "default",
  showLabel: true,
  onReady: function () {
    const id = this.input.id;
    if (this.hourElement) this.hourElement.id = `${id}_hour`;
    if (this.minuteElement) this.minuteElement.id = `${id}_minute`;
    if (this.monthElements)
      this.monthElements.map((e, index) => (e.id = `${id}_month_${index}`));
    if (this.currentYearElement) this.currentYearElement.id = `${id}_year`;
  },
};

const FieldDate = ({ type, ...props }) => <>{typeDateField(type, props)}</>;

const typeDateField = (type, props) => {
  switch (type) {
    case "default":
      return <DefaultDate {...props} />;
    case "time":
      return <Time {...props} />;
    case "dayAndMonth":
      return <DayAndMonth {...props} />;
    case "dateRange":
      return <DateRange {...props} />;
    case "monthRange":
      return <MonthRange {...props} />;
    default:
      <></>;
  }
};
