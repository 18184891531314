import * as API from "../api";
import { useContext } from "react";
import { SystemActions } from "../Context";
import {
  sorting,
  filterUsersAlphabetical,
  getAllObjectsWithCompany,
} from "../functions/functions";

export const useUserApiCalls = () => {
  const { userInfo } = useContext(SystemActions);

  const user = {
    structure: async (id) => {
      if (id) {
        const structure = await API.Structure.getStructuresByUserId(id);
        return getAllObjectsWithCompany(structure);
      } else {
        const structure = userInfo.location
          ? await API.Structure.getStructuresByUserId(userInfo.user_id)
          : [];
        return getAllObjectsWithCompany(structure);
      }
    },
  };

  const dealer = {
    staff: dealerStaff,
    activeStaff: async (...args) => {
      const staff = await dealerStaff(...args);
      return staff.filter((e) => e.is_active);
    },
    teams: async (showNoActive) => {
      const teams = await API.Team.getTeams();
      const sorted = sorting.byParams(teams, "name");
      return showNoActive ? sorted : sorted.filter((e) => e.is_active);
    },
    premiumService: async () => {
      const service = await API.PremService.getPremiumService();
      return sorting.byParams(service, "display");
    },
    coursesForAssign: async () => {
      const courses = await API.Courses.getCoursesToAssign();
      return sorting.byParams(courses, "display");
    },
    folders: async () => {
      const folders = await API.Folder.getFolders();
      return sorting.byParams(folders, "display").filter((e) => e.is_active);
    },
  };

  const listing = {
    deliverable: async () => {
      const list = await API.Listing.getListingDeliverable();
      return sorting.byParams(list, "display");
    },
    employeeType: async () => {
      const list = await API.Listing.getListingEmployeeType();
      const withoutBucket = list.filter((e) => e.display !== "Bucket");
      return sorting.byParams(withoutBucket, "display");
    },
    video: async () => {
      const list = await API.Listing.getListingVideo();
      return sorting.byParams(list, "display");
    },
    vendor: async () => {
      const list = await API.Listing.getListingVendor();
      return sorting.byParams(list, "display");
    },
    tags: async () => {
      const list = await API.Listing.getListingTags();
      return sorting.byParams(list, "display");
    },
    disposition: async () => {
      const list = await API.Listing.getListingDisposition();
      return sorting.byParams(list, "display");
    },
    welcomeMessage: async () => {
      const list = await API.Listing.getListingWelcomeMessage();
      const randomIndex = Math.floor(Math.random() * list.length);
      return list[randomIndex].display;
    },
  };

  return { listing, dealer, user };
};

const dealerStaff = async (showBucket, addBuckets) => {
  const staff = addBuckets
    ? await API.User.getUsersWithBuckets()
    : await API.User.getUserDealer();

  const finalStaff = filterUsersAlphabetical(staff, !!showBucket);
  return finalStaff;
};
