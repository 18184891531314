import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const LeaderboardDetailsReport = lazy(() =>
  import(
    "../components/Reports/LeaderboardDetails/Report/LeaderboardDetailsReport"
  )
);
const Leaderboard = lazy(() => import("../components/Reports/Leaderboard"));
const ReportTrendTech = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceTrendTech/Report/ReportTrendTech"
  )
);
const ServiceTrendAdvisorReport = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceTrendAdvisor/Report/ServiceTrendAdvisorReport"
  )
);
const ReportPerfTechSumDrillDown = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceReportTechSummary/Report/ReportPerfTechSumDrillDown"
  )
);
const UnmatchedEmployee = lazy(() =>
  import("../components/Reports/UnmatchedEmployee/UnmatchedEmployee")
);
const ReportPerfTechSummary = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceReportTechSummary/Report/ReportPerfTechSummary"
  )
);
const ReportTechPerformanceClosedRosDrillDown = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceReportTechnician/Report/ReportTechPerformanceClosedRosDrillDown"
  )
);
const ReportTechPerformanceClosedRosReport = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceReportTechnician/Report/ReportTechPerformanceClosedRosReport"
  )
);
const ServiceAdvisorPerformanceReportDrillDown = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceReportAdvisor/Report/ServiceAdvisorPerformanceReportDrillDown"
  )
);
const ServiceAdvisorPerformanceReport = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceReportAdvisor/Report/ServiceAdvisorPerformanceReport"
  )
);
const ReportPremiumSvcsRankingSummaryTechnicianReport = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceRankingSummaryReportByTech/Report/ReportPremiumSvcsRankingSummaryTechnicianReport"
  )
);
const EmailDrillDown = lazy(() =>
  import("../components/Reports/EmailDrillDown/EmailDrillDown")
);
const ClosedROReport = lazy(() =>
  import("../components/Reports/ROsPage/ClosedRO/ClosedROReport")
);
const ClosedRODetails = lazy(() =>
  import("../components/Reports/ROsPage/ClosedRO/ClosedRODetails")
);
const OpenRoSummaryReport = lazy(() =>
  import("../components/Reports/OpenRoSummary/Report/OpenRoSummaryReport")
);
const OpenRoReports = lazy(() =>
  import("../components/Reports/ROsPage/OpenRO/OpenRoReports")
);
const OPenRoDetails = lazy(() =>
  import("../components/Reports/ROsPage/OpenRO/OPenRoDetails")
);
const ReportPartsReplenishmentReport = lazy(() =>
  import(
    "../components/Reports/PartsReplenishmentReport/Report/ReportPartsReplenishmentReport"
  )
);
const ReportPremiumSvcsByAdvisorReport = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceReportByAdvisor/Report/ReportPremiumSvcsByAdvisorReport"
  )
);
const ReportPremiumSvcsByOpCodeReport = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceReportByOpCode/Report/ReportPremiumSvcsByOpCodeReport"
  )
);
const ReportPremiumSvcsGrossProfitReport = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceGrossProfitReport/Report/ReportPremiumSvcsGrossProfitReport"
  )
);
const ReportPremiumSvcsRankingSummaryReport = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceRankingSummaryReport/Report/ReportPremiumSvcsRankingSummaryReport"
  )
);
const ReportMpiReport = lazy(() =>
  import("../components/Reports/ReportMpiReport/ReportMpiReport")
);
const ServiceReportByTechnicianReport = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceReportByTechnician/Report/ServiceReportByTechnicianReport"
  )
);
const OpCodesUtilizationReport = lazy(() =>
  import(
    "../components/Reports/OpCodesUtilization/Report/OpCodesUtilizationReport"
  )
);
const TaskProgressReportTable = lazy(() =>
  import(
    "../components/Reports/TasksProgressReport/report/TaskProgressReportTable"
  )
);
const ReportEnterprisePremium = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePremiumReport/Report/ReportEnterprisePremium"
  )
);

const ReportEnterprisePerformance = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePerformanceReport/Report/ReportEnterprisePerformance"
  )
);
const ReportEnterprisePremiumSummary = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePremiumSummaryReport/Report/ReportEnterprisePremiumSummary"
  )
);

const ReportEnterprisePremiumGross = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePremiumGross/Report/ReportEnterprisePremiumGross"
  )
);
const ReportEnterprisePremSumAdvisor = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePremSumByAdvisorReport/Report/ReportEnterprisePremSumAdvisor"
  )
);
const ReportEnterprisePremSumTechnician = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePremSumByTechnicianReport/Report/ReportEnterprisePremSumTechnician"
  )
);
const ReportsRouting = ({ report, children }) => {
  return (
    <Routes>
      <>{children}</>
      {report && (
        <>
          <Route
            path="/reporting/unmatched_employee_report"
            element={<UnmatchedEmployee />}
          />
          <Route
            path="/drill_down/:dealer/:link"
            element={<EmailDrillDown />}
          />
          <Route
            path="/reporting/report_closed_ros_search/:startDate/:endDate/:advisor/:ro/:service/:code/:technician/:pay/:team/:spLines/:elrRange/:milRange/:lsRange/:lgRange/:checkBoxes"
            element={<ClosedROReport />}
          />
          <Route
            path="/reporting/report_closed_ros_detail/:ro/:advisor"
            element={<ClosedRODetails />}
          />
          <Route
            path="/reporting/report_table_progress/:dateRange/:employee/:team"
            element={<TaskProgressReportTable />}
          />
          <Route
            path="/reporting/report-open-ro-summary/:employee/:team/:checkBoxes"
            element={<OpenRoSummaryReport />}
          />
          <Route
            path="/reporting/report-open-ro-search/:employee/:roNumber/:team/:checkBoxes"
            element={<OpenRoReports />}
          />
          <Route
            path="/reporting/report-open-ro-detail/:roNumber/:advisor"
            element={<OPenRoDetails />}
          />
          <Route
            path="/reporting/report_parts_replenishment_report/:startDate/:endDate"
            element={<ReportPartsReplenishmentReport />}
          />
          <Route
            path="/reporting/report_mpi_report/:employee"
            element={<ReportMpiReport />}
          />
          <Route
            path="/reporting/report_op_codes_utilized_report/:startDate/:endDate"
            element={<OpCodesUtilizationReport />}
          />
          <Route path="/reporting/leaderboard" element={<Leaderboard />} />
          <Route
            path="/reporting/leaderboard_details_report/:startDate/:endDate/:employee/:reason"
            element={<LeaderboardDetailsReport />}
          />
          {/* PERFORMANCE */}
          <>
            <Route
              path="/reporting/report-svc-adv-performance-closed-ros-report/:startDate/:endDate/:employee/:team/:checkBoxes"
              element={<ServiceAdvisorPerformanceReport />}
            />
            <Route
              path="/reporting/report_svc_adv_performance_closed_ros_drill_down/:startDate/:endDate/:employee"
              element={<ServiceAdvisorPerformanceReportDrillDown />}
            />
            <Route
              path="/reporting/report_svc_adv_performance_closed_ros_report/:startDate/:endDate/:employee/:team/:checkBoxes"
              element={<ReportTechPerformanceClosedRosReport />}
            />
            <Route
              path="/reporting/report_tech_performance_closed_ros_drill_down/:startDate/:endDate/:employee"
              element={<ReportTechPerformanceClosedRosDrillDown />}
            />
            <Route
              path="/reporting/report_performance_tech_rank_summary/:startDate/:endDate/:employee/:team/:checkBoxes"
              element={<ReportPerfTechSummary />}
            />
            <Route
              path="/reporting/report_tech_performance_closed_ros_drill_down_by_date/:startDate/:endDate/:technician"
              element={<ReportPerfTechSumDrillDown />}
            />
            <Route
              path="/reporting/report_performance_by_technician_trending/:dateRange/:employee/:team/:checkBoxes"
              element={<ReportTrendTech />}
            />
          </>
          {/* PREMIUM */}
          <>
            <Route
              path="/reporting/report_premium_svcs_by_advisor_report/:startDate/:endDate/:premService/:pay/:team/:checkBoxes"
              element={<ReportPremiumSvcsByAdvisorReport />}
            />
            <Route
              path="/reporting/report_premium_svcs_by_op_code_report/:startDate/:endDate/:adv/:premService/:pay/:tech/:checkBoxes"
              element={<ReportPremiumSvcsByOpCodeReport />}
            />
            <Route
              path="/reporting/report_premium_svcs_gross_profit_report/:startDate/:endDate/:adv/:premService/:pay/:checkBoxes"
              element={<ReportPremiumSvcsGrossProfitReport />}
            />
            <Route
              path="/reporting/report_premium_svcs_ranking_summary_report/:startDate/:endDate/:premService/:pay/:team/:checkBoxes"
              element={<ReportPremiumSvcsRankingSummaryReport />}
            />
            <Route
              path="/reporting/report_premium_svcs_by_technician_report/:startDate/:endDate/:premService/:pay/:team/:checkBoxes"
              element={<ServiceReportByTechnicianReport />}
            />
            <Route
              path="/reporting/report_premium_svcs_ranking_summary_report_technician/:startDate/:endDate/:premService/:pay/:team/:checkBoxes"
              element={<ReportPremiumSvcsRankingSummaryTechnicianReport />}
            />
            <Route
              path="/reporting/report_premium_svcs_by_advisor_trending/:dateRange/:premService/:pay/:team/:checkBoxes"
              element={<ServiceTrendAdvisorReport />}
            />
          </>
          {/* ENTERPRISE */}
          <>
            <Route
              path="/enterprise/report_premium/:startDate/:endDate/:pay/:structure/"
              element={<ReportEnterprisePremium />}
            />
            <Route
              path="/enterprise/report_performance/:startDate/:endDate/:structure/"
              element={<ReportEnterprisePerformance />}
            />
            <Route
              path="/enterprise/enterprise_premium_svcs_ranking_summary_report/:startDate/:endDate/:pay/:structure"
              element={<ReportEnterprisePremiumSummary />}
            />
            <Route
              path="/enterprise/enterprise_premium_gross_profit_report/:startDate/:endDate/:pay/:structure"
              element={<ReportEnterprisePremiumGross />}
            />
            <Route
              path="/enterprise/enterprise_premium_svcs_ranking_summary_advisor_report/:startDate/:endDate/:pay/:structure/:checkBoxes"
              element={<ReportEnterprisePremSumAdvisor />}
            />
            <Route
              path="/enterprise/enterprise_premium_svcs_ranking_summary_technician_report/:startDate/:endDate/:pay/:structure/:checkBoxes"
              element={<ReportEnterprisePremSumTechnician />}
            />
          </>
        </>
      )}
    </Routes>
  );
};

export default ReportsRouting;
