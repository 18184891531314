import File from "./File";
import Date from "./Date";
import Label from "./Label";
import Check from "./Check";
import Select from "./Select";
import Editor from "./Editor";
import Control from "./Control";

const Field = () => <></>;

Field.File = File;
Field.Date = Date;
Field.Check = Check;
Field.Label = Label;
Field.Select = Select;
Field.Editor = Editor;
Field.Control = Control;

export default Field;
