/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Image } from "../../../../api";
import { useField } from "formik";
import { Editor } from "react-draft-wysiwyg";
import { getCookie } from "../../../../functions/functions";
import TokensEmail from "./TokensEmail/TokensEmail";
import ImageCustom from "./ImageCustom/ImageCustom";
import ImageUploadCustom from "./ImageUploadCustom/ImageUploadCustom";
// import "./editor.scss";

const EmailEditor = ({ name }) => {
  const [field, , helpers] = useField(name);
  const [imgList, setImgList] = useState([]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    const getImg = async () => {
      const get = await Image.getImageData(getCookie("company"));
      setImgList(get);
    };
    getImg();
  }, [update]);

  return (
    <div className="editor">
      <Editor
        editorState={field.value}
        onEditorStateChange={(value) => helpers.setValue(value)}
        onBlur={() => helpers.setTouched(true)}
        editorClassName="editor-class"
        wrapperClassName="demo-wrapper"
        toolbarClassName="toolbarClassName"
        toolbarCustomButtons={[
          <TokensEmail />,
          <ImageUploadCustom imageAdd={() => setUpdate((e) => e + 1)} />,
          imgList.length > 0 ? <ImageCustom list={imgList} /> : <></>,
        ]}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "emoji",
            "remove",
            "history",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
        }}
      />
    </div>
  );
};

export default EmailEditor;
