import { Form } from "react-bootstrap";

const FieldControl = (props) => {
  const { id, type, label, placeholder, ...otherProps } = props;
  return (
    <Form.Control
      id={id}
      name={id}
      type={type}
      {...otherProps}
      placeholder={placeholder || label}
      as={type === "textarea" ? "textarea" : undefined}
    />
  );
};
export default FieldControl;
