import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class LoginUser {
  async loginUser(username, password) {
    const get = await axiosHTTP.post(
      "/v1/login/",
      JSON.stringify({
        email: username,
        password: password,
      }),
      {
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
      }
    );
    return get;
  }

  async csrfToken() {
    const get = await axiosHTTP.get("/v1/csrf_cookie/");
    return get;
  }

  async resetPassword(email) {
    const get = await axiosHTTP.post(
      "/v1/password_reset/",
      JSON.stringify({ email: email })
    );
    return get.data;
  }

  async setNewPassword(id, token, value) {
    const get = await axiosHTTP.post(
      `/v1/password_reset_confirm/${id}/${token}/`,
      JSON.stringify({
        new_password: value.password,
        confirm_new_password: value.confirmPassword,
      })
    );
    return get.data;
  }
}
