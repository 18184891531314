import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

const header = { headers: { "X-CSRFToken": getCookie("csrftoken") } };
export class ReportsCompany {
  async reportsClosedRo(params) {
    const get = await axiosHTTP.post("/v1/report-closed-ro/", params, header);
    return get.data;
  }

  async reportsClosedRoDetail(params) {
    const get = await axiosHTTP.post(
      "/v1/report-closed-ro/detail/",
      params,
      header
    );
    return get.data;
  }

  async reportTechPerformance(params) {
    const get = await axiosHTTP.post(
      "/v1/report-tech-performance-closed-ros-report/",
      params,
      header
    );
    return get.data;
  }

  async reportTechPerformanceDrillDown(params) {
    const get = await axiosHTTP.post(
      "/v1/report-tech-performance-closed-ros-drill-down/",
      params,
      header
    );
    return get.data;
  }

  async reportTechPerformanceSumDrillDown(params) {
    const get = await axiosHTTP.post(
      "/v1/report-tech-performance-closed-ros-drill_down_by_date/",
      params,
      header
    );
    return get.data;
  }

  async reportTaskProgress(params) {
    const get = await axiosHTTP.post(
      "/v1/report-task-progress/",
      params,
      header
    );
    return get.data;
  }

  async reportOpenRoSummary(params) {
    const get = await axiosHTTP.post(
      "/v1/report-open-ro-summary/",
      params,
      header
    );
    return get.data;
  }

  async reportOpenRo(params) {
    const get = await axiosHTTP.post(
      "/v1/report-open-ro-search/",
      params,
      header
    );
    return get.data;
  }

  async noteToOpenRo(roNumber, note, userId) {
    const get = await axiosHTTP.post(
      "/v1/data-dms-open-notes/",
      JSON.stringify({
        note: note,
        user: userId,
        ro_number: roNumber,
        company: getCookie("company"),
      }),
      header
    );
    return get.data;
  }

  async reportOpenRoDetail(params) {
    const get = await axiosHTTP.post(
      "/v1/report-open-ro-detail/",
      params,
      header
    );
    return get.data;
  }

  async reportServiceAdvPerformanceReport(params) {
    const get = await axiosHTTP.post(
      "/v1/report-svc-adv-performance-closed-ros-report/",
      params,
      header
    );
    return get.data;
  }

  async reportServiceAdvPerformanceReportDrillDown(params) {
    const get = await axiosHTTP.post(
      "/v1/report_svc_adv_performance_closed_ros_drill_down/",
      params,
      header
    );
    return get.data;
  }

  async reportPartRepl(params) {
    const get = await axiosHTTP.post("/v1/report-part-repl/", params, header);
    return get.data;
  }

  async reportPremiumSvcsByAdvisor(params) {
    const get = await axiosHTTP.post(
      "/v1/report-premium-svcs-by-advisor/",
      params,
      header
    );
    return get.data;
  }

  async reportPremiumSvcsByOpCodeReport(params) {
    const get = await axiosHTTP.post(
      "/v1/report-premium-svcs-by-op-code-report/",
      params,
      header
    );
    return get.data;
  }

  async reportPremiumSvcsGrossProfitReport(params) {
    const get = await axiosHTTP.post(
      "/v1/report-premium-svcs-gross-profit-report/",
      params,
      header
    );
    return get.data;
  }

  async reportPremiumSvcsRankingSummaryReport(params) {
    const get = await axiosHTTP.post(
      "/v1/report-premium-svcs-ranking-summary-report-by-advisor/",
      params,
      header
    );
    return get.data;
  }

  async reportPremiumSvcsRankingSummaryTechnicianReport(params) {
    const get = await axiosHTTP.post(
      "/v1/report-premium-svcs-ranking-summary-report-by-technician/",
      params,
      header
    );
    return get.data;
  }

  async reportMpiReport(params) {
    const get = await axiosHTTP.post("/v1/report-mpi-report/", params);
    return get.data;
  }

  async reportPremiumSvcsByTechnician(params) {
    const get = await axiosHTTP.post(
      "/v1/report-premium-svcs-by-technician/",
      params,
      header
    );
    return get.data;
  }

  async reportUtilization(params) {
    const get = await axiosHTTP.post(
      "/v1/report-op-codes-utilized/",
      params,
      header
    );
    return get.data;
  }

  async reportUnmatchedEmployee() {
    const get = await axiosHTTP.get(
      "/v1/report-unmatched-employee/",
      {},
      header
    );
    return get.data;
  }

  async reportPremiumTrendAdvisor(params) {
    const get = await axiosHTTP.post(
      "/v1/report-premium-svcs-by-advisor-trending/",
      params,
      header
    );
    return get.data;
  }

  async reportPerformanceTrendTech(params) {
    const get = await axiosHTTP.post(
      "/v1/report-performance-by-technician-trending/",
      params,
      header
    );
    return get.data;
  }

  async reportLeaderboardDetails(params) {
    const get = await axiosHTTP.get(
      "/v1/leaderboard-details-report/",
      params,
      header
    );
    return get.data;
  }
}
