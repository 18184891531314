import axios from "axios";
import { useEffect, useState } from "react";
import LoginPage from "../components/LogOutsPages/LoginPage/LoginPage";
import { getCookie } from "../functions/functions";
import { userLocalStorage } from "../storage/local";

export let axiosHTTP = axios.create({
  extended: true,
  withCredentials: true,
  baseURL: "/api",
});
axios.defaults.headers["xsrfHeaderName"] = "X-CSRFToken";
axios.defaults.headers["xsrfCookieName"] = "csrftoken";
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Accept"] = "application/json";

export const UserAuthorization = (Component) => {
  const [quote, setQuote] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = localStorage.getItem("Is.currentUser");
  const userCheck = userLocalStorage.get();

  useEffect(() => {
    if (user !== null) setIsLoggedIn(true);
  }, [user]);

  useEffect(() => {
    axios.defaults.headers.post["X-CSRFToken"] = getCookie("csrftoken");
    axios.defaults.headers.put["X-CSRFToken"] = getCookie("csrftoken");
    axios.defaults.headers.patch["X-CSRFToken"] = getCookie("csrftoken");
    axios.defaults.headers.delete["X-CSRFToken"] = getCookie("csrftoken");
  }, [isLoggedIn]);

  useEffect(() => {
    if (userCheck) axiosHTTP = axios.create({ baseURL: "/api" }); //is login
    else axiosHTTP = axios.create({ baseURL: "/api" }); //not login
  }, [userCheck]);

  return {
    Component:
      user !== null ? (
        <Component onLogOut={setIsLoggedIn} quote={quote} report={true} />
      ) : isLoggedIn ? (
        <Component onLogOut={setIsLoggedIn} quote={quote} report={true} />
      ) : (
        <LoginPage setLogin={setIsLoggedIn} quote={(e) => setQuote(e)} />
      ),
  };
};
