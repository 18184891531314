import { Form } from "react-bootstrap";

const Check = ({ id, name, label, type, ...props }) => {
  return (
    <Form.Check
      id={id}
      {...props}
      type={type}
      label={label}
      name={name || id}
      className="user-select-none"
    />
  );
};

export default Check;
