import React, { useState } from "react";
import * as Yup from "yup";
import { Login } from "../../../api";
import { Formik, Form } from "formik";
import { Button, Col } from "react-bootstrap";
import { yupValidation } from "../../../functions/functions";
import { Link, useNavigate, useParams } from "react-router-dom";
import Field from "../../../CustomComponents/Field";
import logo from "../../../image/logo/gearhead-2020-whitebg.gif";

const NewPassword = () => {
  const { userId, token } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(false);

  const handleResetPassword = async (value) => {
    const { password, confirmPassword } = value;
    setError();
    setDisabled(true);
    if (password === confirmPassword) {
      await Login.setNewPassword(userId, token, value)
        .then(() => navigate("/"))
        .catch((e) => setError({ message: e.response.data.detail, err: true }))
        .finally(() => setDisabled(false));
    } else {
      setError({ message: "Passwords do not match", err: true });
      setDisabled(false);
    }
  };

  return (
    <div className="sign-in-form">
      <div className="content">
        <div className="mb-4">
          <Link to="/">
            <img src={logo} alt="Gearhead Training" height="100" width="100%" />
          </Link>
        </div>
        <div className="fs-2">Enter New Password</div>
        <Formik
          onSubmit={handleResetPassword}
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={Yup.object({
            password: yupValidation().password,
            confirmPassword: yupValidation().confirmPassword,
          })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Col>
                <Field.Control
                  id="password"
                  type="password"
                  autoComplete="on"
                  placeholder="******"
                  label="Enter Your Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col>
                <Field.Control
                  type="password"
                  autoComplete="on"
                  id="confirmPassword"
                  placeholder="******"
                  label="Confirm Your Password"
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                />
              </Col>
              {error && (
                <div
                  className={`${
                    error.err ? "text-danger" : "text-success"
                  } py-2`}
                >
                  {error.message}
                </div>
              )}
              <Button className="mt-2" type="submit" disabled={disabled}>
                Save Password
              </Button>
            </Form>
          )}
        </Formik>
        <hr />
        <Link to="/" style={{ textDecoration: "none" }}>
          Back to Login
        </Link>
      </div>
    </div>
  );
};
export default NewPassword;
