const createStorage = (storeName) => {
  return {
    set: (elem) => localStorage.setItem(storeName, JSON.stringify(elem)),
    get: () => JSON.parse(localStorage.getItem(storeName)),
    out: () => localStorage.removeItem(storeName),
  };
};

export const userCurrentDealer = createStorage("Is.currentDealer");
export const userLocalStorage = createStorage("Is.currentUser");
export const userStyles = createStorage("styles");
