import { axiosHTTP } from "../Hook/UserAuthorization";

export class ListingData {
  async getListingData() {
    const get = await axiosHTTP.get("/v1/listing/");
    return get.data;
  }

  async searchListing(search, file) {
    const find = await axiosHTTP.get("/v1/listing/", {
      params: { search: search, listtype: file },
    });
    return find.data;
  }

  async postListing(listtype, display, active) {
    const post = await axiosHTTP.post(
      "/v1/listing/",
      JSON.stringify({
        display: display,
        is_active: active,
        listtype: listtype,
      })
    );
    return post.data;
  }

  async putListing(id, listtype, display, active) {
    const put = await axiosHTTP.put(
      `/v1/listing/${id}/`,
      JSON.stringify({
        id: id,
        display: display,
        is_active: active,
        listtype: listtype,
      })
    );
    return put.data;
  }

  async getListingDeliverable() {
    const get = await axiosHTTP.get("/v1/listing/", {
      params: { listtype: "f145f736-f418-4679-bc9d-081a3d54dba2" },
    });
    return get.data;
  }

  async getListingWelcomeMessage() {
    const get = await axiosHTTP.get("/v1/listing/", {
      params: { listtype: "047ff55c-c092-4ae7-9058-1f2555094b28" },
    });
    return get.data;
  }

  async getListingDisposition() {
    const get = await axiosHTTP.get("/v1/listing/", {
      params: { listtype: "3dc1846f-3eb5-4b24-9302-5798e21b3bf6" },
    });
    return get.data;
  }

  async getListingVendor() {
    const get = await axiosHTTP.get("/v1/listing/", {
      params: { listtype: "4c9aebc2-3f1b-423d-920e-2c1919398b44" },
    });
    return get.data;
  }

  async getListingVideo() {
    const get = await axiosHTTP.get("/v1/listing/", {
      params: { listtype: "dc059bab-7068-44e0-b5a4-95894ff29ad2" },
    });
    return get.data;
  }

  async getListingTags() {
    const get = await axiosHTTP.get("/v1/listing/", {
      params: { listtype: "f228ae9d-3ccf-4e7d-b5d9-e1e6c5704a13" },
    });
    return get.data;
  }

  async getListingEmployeeType() {
    const get = await axiosHTTP.get("/v1/listing/", {
      params: { listtype: "20fc7190-f5e6-4933-90f1-2c57735ff32c" },
    });
    return get.data;
  }

  async getListingById(id) {
    const get = await axiosHTTP.get(`/v1/listing/${id}/`);
    return get.data;
  }

  async getListtype() {
    const get = await axiosHTTP.get("/v1/listing-type/");
    return get.data;
  }
}
