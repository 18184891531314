/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext } from "react";
import { User } from "../api";
import { useLocation } from "react-router-dom";
import { getCookie, sorting } from "../functions/functions";
import Page404 from "../CustomComponents/Error/Page404";
import NotifyContainer from "../CustomComponents/Notify";
import SurveyReviewsModal from "../CustomComponents/SurveyReviewsSystem/SurveyReviewsModal";

export const ErrorPage = createContext({
  error: false,
  setError: () => {},
});

export const SystemActions = createContext({
  notify: { show: false, err: false },
  setNotify: () => {},
  showSurveyModal: { show: false },
  setShowSurveyModal: () => {},
});

const ErrorContextProvider = ({ children }) => {
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => setError(null), [location.pathname]);

  return (
    <ErrorPage.Provider value={{ error, setError }}>
      {error ? <Page404 error={error} /> : <>{children}</>}
    </ErrorPage.Provider>
  );
};

const SystemDataContextProvider = ({ children, permission, ...props }) => {
  const [notify, setNotify] = useState();
  const [userInfo, setUserInfo] = useState(permission);
  const [dealers, setDealers] = useState(props.dealers);
  const [updateInfo, setUpdateInfo] = useState({ user: false });
  const [showSurveyModal, setShowSurveyModal] = useState({ show: false });

  useEffect(() => setUserInfo(permission), [permission]);

  useEffect(() => props.dealerUpdate(dealers), [dealers]);

  useEffect(() => {
    const updateUserInfo = async () => {
      const getUserInfo = await User.getUserPermission();
      setUserInfo(getUserInfo);
      props.changeUserInfo(getUserInfo);
      setUpdateInfo((prev) => ({ ...prev, user: false }));
    };
    updateInfo.user && updateUserInfo();
  }, [updateInfo]);

  useEffect(() => {
    userInfo && props.changeUserInfo(userInfo);
  }, [userInfo]);

  return (
    <>
      <SystemActions.Provider
        value={{
          notify,
          userInfo,
          setNotify,
          setDealers,
          setUserInfo,
          setUpdateInfo,
          showSurveyModal,
          setShowSurveyModal,
          dealers: listDealers(dealers, permission),
          thisDealer: dealers.find((e) => e.id === getCookie("company")),
        }}
      >
        {<>{children}</>}
        <NotifyContainer
          notify={notify}
          user={userInfo}
          setNotify={() => setNotify()}
        />
        <SurveyReviewsModal showModal={showSurveyModal} />
      </SystemActions.Provider>
    </>
  );
};

export const ContextProviders = ({ children, ...props }) => {
  return (
    <>
      {props.permission && (
        <SystemDataContextProvider {...props}>
          <ErrorContextProvider>{<>{children}</>}</ErrorContextProvider>
        </SystemDataContextProvider>
      )}
    </>
  );
};

const listDealers = (arr, { is_reseller }) => {
  const list = is_reseller
    ? arr.filter(
        (e) => e.parent_dealership === is_reseller || e.id === is_reseller
      )
    : arr;
  return sorting.byParams(list, "company");
};
