import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class EventData {
  async getEvents() {
    const get = await axiosHTTP.get("/v1/event/");
    return get.data;
  }

  async getEventInfo(id) {
    const get = await axiosHTTP.get(`/v1/event/${id}/`);
    return get.data;
  }

  async getEventsSchedules(search) {
    const get = await axiosHTTP.get("/v1/event-schedule/", {
      params: { search: search },
    });
    return get.data;
  }

  async getEventScheduleData(id) {
    const get = await axiosHTTP.get(`/v1/event-schedule/${id}/`);
    return get.data;
  }

  async postEventSchedule({ event, emailTemplate, sendTemplate }) {
    const get = await axiosHTTP.post(
      "/v1/event-schedule/",
      JSON.stringify({
        event: event,
        send_email: sendTemplate,
        company: getCookie("company"),
        email_template: emailTemplate,
      })
    );
    return get.data;
  }

  async putEventSchedule(id, { event, emailTemplate, sendTemplate }) {
    const get = await axiosHTTP.patch(
      `/v1/event-schedule/${id}/`,
      JSON.stringify({
        id: id,
        event: event,
        send_email: sendTemplate,
        email_template: emailTemplate,
      })
    );
    return get.data;
  }
}
