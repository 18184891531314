import { axiosHTTP } from "../Hook/UserAuthorization";
import { addDays, convertToHtml, getCookie } from "../functions/functions";

export class CoursesData {
  async assignCourse(value) {
    const { course, assignTo, taskSchedule, daysToComplete, exactDate } = value;
    const assign = await axiosHTTP.post(
      "/v1/course-assign/",
      JSON.stringify({
        course_id: course,
        assigned_to: assignTo,
        task_schedule: taskSchedule || null,
        due_date: daysToComplete ? addDays(daysToComplete) : exactDate || null,
      })
    );
    return assign.data;
  }
  //courses
  async getCourses(search) {
    const get = await axiosHTTP.get("/v1/course/", {
      params: { company: getCookie("company"), search: search },
    });
    return get.data;
  }

  async copyCourse(id) {
    const post = await axiosHTTP.post(
      "/v1/course-copy/",
      JSON.stringify({ course_id: id })
    );
    return post.data;
  }

  async getCoursesData(id) {
    const get = await axiosHTTP.get("/v1/course/", {
      params: { id: id },
    });
    return get.data;
  }

  async changeCourseContentPrerequisite(course, chapter, type) {
    const get = await axiosHTTP.post(
      "/v1/course-chapter-prerequisite/",
      JSON.stringify({
        value: type,
        course_id: course,
        chapter_id: chapter,
      })
    );
    return get.data;
  }

  async getCoursesToAssign() {
    const get = await axiosHTTP.get("/v1/course/");
    return get.data;
  }

  async getCourseInfo(id) {
    const get = await axiosHTTP.get(`/v1/course/${id}/`);
    return get.data;
  }

  async deleteCourse(id) {
    const get = await axiosHTTP.delete(
      `/v1/course/${id}/`,
      JSON.stringify({ id: id })
    );
    return get.data;
  }

  async putCoursePhoto(value, photo) {
    const formData = new FormData();
    formData.append("image", photo);
    formData.append("display", value.display);
    formData.append("company", value.company);
    formData.append("createdby", value.createdby);
    formData.append("is_active", value.is_active);
    formData.append("abbreviation", value.abbreviation);
    formData.append("is_published", value.is_published);

    const put = await axiosHTTP.put(`/v1/course/${value.id}/`, formData, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
        "X-CSRFToken": getCookie("csrftoken"),
      },
    });
    return put.data;
  }

  async deleteCoursePhoto(id) {
    const get = await axiosHTTP.post(
      `/v1/course-photo-delete/`,
      JSON.stringify({ course_id: id })
    );
    return get.data;
  }

  async postCourse(value) {
    const get = await axiosHTTP.post(
      "/v1/course/",
      JSON.stringify({
        image: null,
        type: value.courseType,
        display: value.courseTitle,
        abbreviation: value.abbreviation,
        instructor: value.instructor || null,
        description: convertToHtml(value.html),
        points: typeof value.points === "number" ? value.points : null,
        is_active:
          value.courseStatus === 1
            ? true
            : value.courseStatus === 2
            ? true
            : false,
        is_published:
          value.courseStatus === 1
            ? true
            : value.courseStatus === 2
            ? false
            : false,
      })
    );
    return get.data;
  }

  async putCourse(id, value) {
    const get = await axiosHTTP.put(
      `/v1/course/${id}/`,
      JSON.stringify({
        display: value.courseTitle,
        abbreviation: value.abbreviation,
        instructor: value.instructor || null,
        description: convertToHtml(value.html),
        points: typeof value.points === "number" ? value.points : null,
        is_active:
          value.courseStatus === 1
            ? true
            : value.courseStatus === 2
            ? true
            : false,
        is_published:
          value.courseStatus === 1
            ? true
            : value.courseStatus === 2
            ? false
            : false,
      })
    );
    return get.data;
  }

  //instructors
  async getInstructors(search) {
    const get = await axiosHTTP.get("/v1/instructor/", {
      params: { company: getCookie("company"), search: search },
    });
    return get.data;
  }

  async getInstructorInfo(id) {
    const get = await axiosHTTP.get(`/v1/instructor/${id}/`);
    return get.data;
  }

  async postInstructor(value, description) {
    const get = await axiosHTTP.post(
      `/v1/instructor/`,
      JSON.stringify({
        bio: description,
        email: value.email,
        title: value.title,
        is_active: value.active,
        last_name: value.lastName,
        first_name: value.firstName,
        company: getCookie("company"),
      })
    );
    return get.data;
  }

  async putInstructor(id, value, description) {
    const get = await axiosHTTP.put(
      `/v1/instructor/${id}/`,
      JSON.stringify({
        bio: description,
        email: value.email,
        title: value.title,
        is_active: value.active,
        last_name: value.lastName,
        first_name: value.firstName,
        company: getCookie("company"),
      })
    );
    return get.data;
  }

  async getCourseCurriculum(id) {
    const get = await axiosHTTP.post(
      `/v1/chapter-content/`,
      JSON.stringify({ course_id: id })
    );
    return get.data;
  }

  async courseChapter({ id }, course_id, display, type) {
    if (id) {
      const put = await axiosHTTP.put(
        `/v1/chapter/${id}/`,
        JSON.stringify({ id, course: course_id, display })
      );
      return put.data;
    } else {
      const post = await axiosHTTP.post(
        `/v1/chapter-save/`,
        JSON.stringify({ display, course_id, is_published: type === "Day" })
      );
      return post.data;
    }
  }

  async deleteChapter(id) {
    const get = await axiosHTTP.post(
      `/v1/chapter-delete/`,
      JSON.stringify({ chapter_id: id })
    );
    return get.data;
  }

  async changeStatusChapter(id, status) {
    const get = await axiosHTTP.post(
      `/v1/chapter-published/`,
      JSON.stringify({ chapter_id: id, published: status })
    );
    return get.data;
  }

  async moveChapter(courseId, { id, orderid }, direction) {
    const get = await axiosHTTP.post(
      `/v1/chapter-move/`,
      JSON.stringify({
        chapter_id: id,
        order_id: orderid,
        course_id: courseId,
        direction: direction,
      })
    );
    return get.data;
  }

  async postContentForChapter(chapterId, display, typeContent) {
    const get = await axiosHTTP.post(
      `/v1/course-content-save-new/`,
      JSON.stringify({
        display: display,
        chapter_id: chapterId,
        content_type_id: typeContent,
      })
    );
    return get.data;
  }

  async postQuizForChapter(info, value) {
    const get = await axiosHTTP.post(
      `/v1/course-content-quiz-save/`,
      JSON.stringify({
        quiz_id: value.quiz,
        display: value.display,
        content_id: info.id || null,
        chapter_id: info.chapter_id,
        is_published: value.publishStatus,
        is_prerequisite: value.prerequisite,
        content_type_id: info.content_type_id,
        task_schedule_id: value.taskSchedule || null,
        points: typeof value.points === "number" ? value.points : null,
      })
    );
    return get.data;
  }

  async postSurveyForChapter(info, value) {
    const get = await axiosHTTP.post(
      `/v1/course-content-survey-save/`,
      JSON.stringify({
        display: value.display,
        survey_id: value.survey,
        chapter_id: info.chapter_id,
        content_id: info?.id || null,
        is_published: value.publishStatus,
        is_prerequisite: value.prerequisite,
        content_type_id: info.content_type_id,
      })
    );
    return get.data;
  }

  async getContentCourse(id) {
    const get = await axiosHTTP.get(`/v1/content/${id}/`);
    return get.data;
  }

  async getContentCourseData({ content }, id) {
    const get = await axiosHTTP.get(`/v1/user-course-content/`, {
      params: { content_id: content, course_id: id },
    });
    return get.data;
  }

  async putContent(info, value) {
    const params = JSON.stringify({
      content_id: info.id,
      display: value.contentTitle,
      chapter_id: info.chapter_id,
      media_id: value.media || null,
      is_published: value.publishStatus,
      is_prerequisite: value.prerequisite,
      content_type_id: info.content_type_id,
      description: convertToHtml(value.html),
      task_schedule_id: value.taskSchedule || null,
      points: typeof value.points === "number" ? value.points : null,
    });
    if (info.id) {
      const put = await axiosHTTP.post(`/v1/course-content-update/`, params);
      return put.data;
    } else {
      const post = await axiosHTTP.post(`/v1/course-content-save-new/`, params);
      return post.data;
    }
  }

  async changeStatusContent(id, status) {
    const get = await axiosHTTP.post(
      `/v1/course-content-save-published/`,
      JSON.stringify({ content_id: id, is_published: status })
    );
    return get.data;
  }

  async moveContent(value, direction) {
    const get = await axiosHTTP.post(
      `/v1/course-content-move/`,
      JSON.stringify({
        content_id: value.id,
        direction: direction,
        order_id: value.orderid,
        chapter_id: value.chapter_id,
      })
    );
    return get.data;
  }

  async newOrderContent(order) {
    const get = await axiosHTTP.put(
      `/v1/content-order-update/`,
      JSON.stringify({ content_ids: order })
    );
    return get.data;
  }

  async newOrderAnswer(order) {
    const get = await axiosHTTP.put(
      `/v1/answer-order-update/`,
      JSON.stringify({ answer_ids: order })
    );
    return get.data;
  }

  async deleteContent(id) {
    const get = await axiosHTTP.post(
      `/v1/course-content-delete/`,
      JSON.stringify({ content_id: id })
    );
    return get.data;
  }

  async downloadForContent(id) {
    const get = await axiosHTTP.get(`/v1/download/`, {
      params: { content: id },
    });
    return get.data;
  }
  async deleteFileContent(id) {
    const get = await axiosHTTP.delete(
      `/v1/download/${id}/`,
      JSON.stringify({ id: id })
    );
    return get.data;
  }
  async postDocumentContent(value, contentId) {
    const formData = new FormData();
    formData.append("content", contentId);
    formData.append("file_name", value.file);
    formData.append("display", value.description);

    const put = await axiosHTTP.post(`/v1/download/`, formData, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
        "X-CSRFToken": getCookie("csrftoken"),
      },
    });
    return put.data;
  }
  //quiz
  async getQuiz(search) {
    const get = await axiosHTTP.get("/v1/quiz/", {
      params: { company: getCookie("company"), search: search },
    });
    return get.data;
  }

  async getQuizInfo(id) {
    const get = await axiosHTTP.get(`/v1/quiz/${id}/`);
    return get.data;
  }

  async postQuiz(value) {
    const get = await axiosHTTP.post(
      `/v1/quiz/`,
      JSON.stringify({
        is_active: value.active,
        display: value.quizTitle,
        company: getCookie("company"),
        passing_score: value.passingScore,
        description: convertToHtml(value.html),
      })
    );
    return get.data;
  }

  async putQuiz(id, value) {
    const get = await axiosHTTP.put(
      `/v1/quiz/${id}/`,
      JSON.stringify({
        is_active: value.active,
        display: value.quizTitle,
        company: getCookie("company"),
        passing_score: value.passingScore,
        description: convertToHtml(value.html),
      })
    );
    return get.data;
  }

  async cloneQuiz(id) {
    const get = await axiosHTTP.post(
      `/v1/quiz-clone/`,
      JSON.stringify({ quiz_id: id })
    );
    return get.data;
  }

  async getQuestionsToQuiz(id) {
    const get = await axiosHTTP.post(
      `/v1/question-answer/`,
      JSON.stringify({ quiz_id: id })
    );
    return get.data;
  }

  async copyQuestionToQuiz(values) {
    const get = await axiosHTTP.post(
      `/v1/copy-question-to-quiz/`,
      JSON.stringify(values)
    );
    return get.data;
  }

  async questionToQuiz({ quiz_id, id, orderid }, value) {
    if (id) {
      const put = await axiosHTTP.put(
        `/v1/question/${id}/`,
        JSON.stringify({
          id,
          orderid,
          quiz: quiz_id,
          question: value.question,
          question_type: value.questionType,
        })
      );
      return put.data;
    } else {
      const post = await axiosHTTP.post(
        `/v1/question-save/`,
        JSON.stringify({
          quiz_id,
          question: value.question,
          question_type: value.questionType,
        })
      );
      return post.data;
    }
  }

  async deleteQuestion(id) {
    const get = await axiosHTTP.post(
      `/v1/question-delete/`,
      JSON.stringify({ question_id: id })
    );
    return get.data;
  }

  async moveQuestion(questionId, quizId, orderId, direction) {
    const get = await axiosHTTP.post(
      `/v1/quiz-question-move/`,
      JSON.stringify({
        quiz_id: quizId,
        orderid: orderId,
        direction: direction,
        question_id: questionId,
      })
    );
    return get.data;
  }

  async answerToQuestion({ question_id, id, orderid }, value) {
    if (id) {
      const put = await axiosHTTP.put(
        `/v1/answer/${id}/`,
        JSON.stringify({
          id,
          orderid,
          answer: value.answer,
          question: question_id,
          is_correct: value.rightAnswer,
        })
      );
      return put.data;
    } else {
      const post = await axiosHTTP.post(
        `/v1/answer-save/`,
        JSON.stringify({
          question_id,
          answer: value.answer,
          is_correct: value.rightAnswer,
        })
      );
      return post.data;
    }
  }

  async deleteAnswer(id) {
    const get = await axiosHTTP.delete(
      `/v1/answer/${id}/`,
      JSON.stringify({ id })
    );
    return get.data;
  }
  ////////////////////////////////
  //media
  //media list
  async getMediaCourses(search) {
    const get = await axiosHTTP.get("/v1/media/", {
      params: { company: getCookie("company"), search: search },
    });
    return get.data;
  }
  //media detail info
  async getMediaInfo(id) {
    const get = await axiosHTTP.get(`/v1/media/${id}/`);
    return get.data;
  }
  //post video to Vimeo
  async postMedia({ file, display, active }, progress) {
    const media = new FormData();
    media.append("file_item", file);
    media.append("display", display);
    media.append("is_active", active);

    const post = await axiosHTTP.post(`/v1/media-upload/`, media, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
      onUploadProgress: (e) => progress(Math.round((e.loaded / e.total) * 100)),
    });
    return post.data;
  }
  //post video Vimeo url
  async postMediaUrl({ url, display, active }) {
    const post = await axiosHTTP.post(
      `/v1/media-upload/`,
      JSON.stringify({
        file_item: null,
        display: display,
        is_active: active,
        url: getVimeoVideoId(url),
      })
    );
    return post.data;
  }
  //post audio to google cloud
  async postMediaAudio({ file, display, active }, progress) {
    const audio = new FormData();
    audio.append("media_type", "A");
    audio.append("cloud_file", file);
    audio.append("is_active", active);
    audio.append("image_name", display);
    audio.append("company", getCookie("company"));

    const post = await axiosHTTP.post(`/v1/media/`, audio, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
      onUploadProgress: (e) => progress(Math.round((e.loaded / e.total) * 100)),
    });
    return post.data;
  }
  //update audio file
  async putMediaAudioFile(id, { file, active, display }, progress) {
    const audio = new FormData();
    audio.append("id", id);
    audio.append("media_type", "A");
    audio.append("cloud_file", file);
    audio.append("is_active", active);
    audio.append("image_name", display);
    audio.append("company", getCookie("company"));

    const post = await axiosHTTP.put(`/v1/media/${id}/`, audio, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
      onUploadProgress: (e) => progress(Math.round((e.loaded / e.total) * 100)),
    });
    return post.data;
  }
  //update video file on vimeo
  async putMediaVideo(id, { file, display, active }, progress) {
    const video = new FormData();
    video.append("media_id", id);
    video.append("file_item", file);
    video.append("display", display);
    video.append("is_active", active);
    const post = await axiosHTTP.put(`/v1/media-upload/`, video, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
      onUploadProgress: (e) => progress(Math.round((e.loaded / e.total) * 100)),
    });
    return post.data;
  }
  //update data audio with file
  async putMediaAudioData(id, { display, active }) {
    const post = await axiosHTTP.put(
      `/v1/media/${id}/`,
      JSON.stringify({
        id: id,
        is_active: active,
        image_name: display,
        company: getCookie("company"),
      })
    );
    return post.data;
  }
  //update data video with file
  async putMediaVideoData(id, { display, active }) {
    const post = await axiosHTTP.put(
      `/v1/media-upload/`,
      JSON.stringify({
        media_id: id,
        file_item: null,
        display: display,
        is_active: active,
      })
    );
    return post.data;
  }

  async putMediaVideoDataUrl(id, { display, url, active }) {
    const post = await axiosHTTP.put(
      `/v1/media-upload/`,
      JSON.stringify({
        media_id: id,
        file_item: null,
        display: display,
        is_active: active,
        url: getVimeoVideoId(url),
      })
    );
    return post.data;
  }
  //delete audio file on google cloud
  async deleteMediaAudio(file) {
    const post = await axiosHTTP.put(
      `/v1/media/${file.id}/`,
      JSON.stringify({
        id: file.id,
        duration: 0,
        file_name: null,
        cloud_file: null,
        company: file.company,
      })
    );
    return post.data;
  }
  //delete video file on vimeo
  async deleteMediaVideo(id) {
    const post = await axiosHTTP.post(
      `/v1/media-delete/`,
      JSON.stringify({ media_id: id })
    );
    return post.data;
  }

  async getUserCourse() {
    const get = await axiosHTTP.get(`/v1/course-list-user/`);
    return get.data;
  }
  async getUserCourseData(id) {
    const get = await axiosHTTP.get(`/v1/course-list-user/`, {
      params: { id: id },
    });
    return get.data;
  }
}

const getVimeoVideoId = (url) => {
  const regex =
    /^(?:https?:\/\/)?(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/|embed\/)?(.+)/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1].replace("?h=", "/");
  }
  return null;
};
