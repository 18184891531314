import React, { useState } from "react";
import * as Yup from "yup";
import { Login } from "../../../api";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { Button, Col } from "react-bootstrap";
import Field from "../../../CustomComponents/Field";
import logo from "../../../image/logo/gearhead-2020-whitebg.gif";

const ResetPassword = () => {
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(false);

  const handleResetPassword = async ({ email }) => {
    setDisabled(true);
    await Login.resetPassword(email)
      .then((e) => setError({ message: e.detail, err: false }))
      .catch((e) => setError({ message: e.response.data.detail, err: true }))
      .finally(() => setDisabled(false));
  };

  return (
    <div className="sign-in-form">
      <div className="content">
        <div className="mb-4">
          <Link to="/">
            <img src={logo} alt="Gearhead Training" height="100" width="100%" />
          </Link>
        </div>
        <div className="fs-2">Reset Password</div>
        <Formik
          onSubmit={handleResetPassword}
          initialValues={{ email: "" }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Invalid email address")
              .required("Enter email"),
          })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Col>
                <Field.Control
                  id="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  label="Enter Your Email Address"
                  placeholder="Email address here"
                />
              </Col>
              {error && (
                <div
                  className={`${
                    error.err ? "text-danger" : "text-success"
                  } py-2`}
                >
                  {error.message}
                </div>
              )}
              <Button className="mt-2" type="submit" disabled={disabled}>
                Reset Password
              </Button>
            </Form>
          )}
        </Formik>
        <hr />
        <Link to="/" style={{ textDecoration: "none" }}>
          Back to Login
        </Link>
      </div>
    </div>
  );
};
export default ResetPassword;
