import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class ScorecardData {
  async getScorecardData() {
    const get = await axiosHTTP.get("/v1/scorecard/", {
      params: { company: getCookie("company") },
    });
    return get.data;
  }

  async postScorecard(value, id) {
    const { display, description, level, active } = value;
    const post = await axiosHTTP.post(
      "/v1/scorecard/",
      JSON.stringify({
        createdby: id,
        levelid: level,
        display: display,
        is_active: active,
        description: description,
        company: getCookie("company"),
      })
    );
    return post.data;
  }

  async cloneScorecardToCompany(dealership, scorecard) {
    const post = await axiosHTTP.post(
      "/v1/scorecard/company/clone/",
      JSON.stringify({ company_id: dealership, scorecard_id: scorecard })
    );
    return post.data;
  }

  async cloneScorecard(scorecard) {
    const post = await axiosHTTP.post(
      "/v1/scorecard/clone/",
      JSON.stringify({ scorecard_id: scorecard })
    );
    return post.data;
  }

  async putScorecard(id, value, createdBy) {
    const { display, description, level, active } = value;
    const put = await axiosHTTP.put(
      `/v1/scorecard/${id}/`,
      JSON.stringify({
        levelid: level,
        display: display,
        is_active: active,
        createdby: createdBy,
        description: description,
        company: getCookie("company"),
      })
    );
    return put.data;
  }

  async searchScorecard(search) {
    const find = await axiosHTTP.get("/v1/scorecard/", {
      params: { company: getCookie("company"), search: search },
    });
    return find.data;
  }

  async getScorecardInfo(id) {
    const get = await axiosHTTP.get(`/v1/scorecard/${id}/`);
    return get.data;
  }

  async getScorecardDetails(id) {
    const get = await axiosHTTP.get("/v1/scorecard-detail/", {
      params: { scorecard: id },
    });
    return get.data;
  }

  async getScorecardDetailsById(id) {
    const get = await axiosHTTP.get(`/v1/scorecard-detail/${id}/`);
    return get.data;
  }

  async postScorecardDetails(scorecard, display, description, order_id) {
    const post = await axiosHTTP.post(
      `/v1/scorecard-detail/`,
      JSON.stringify({
        display: display,
        orderid: order_id,
        scorecard: scorecard,
        description: description.length > 8 ? description : null,
      })
    );
    return post.data;
  }

  async putScorecardDetails(id, scorecard, display, description, order) {
    const put = await axiosHTTP.put(
      `/v1/scorecard-detail/${id}/`,
      JSON.stringify({
        id: id,
        orderid: order,
        display: display,
        scorecard: scorecard,
        description: description.length > 8 ? description : null,
      })
    );
    return put.data;
  }

  async deleteScorecard(id) {
    const get = await axiosHTTP.delete(
      `/v1/scorecard-detail/${id}/`,
      JSON.stringify({ id: id })
    );
    return get.data;
  }

  async potScorecardDetails(id) {
    const get = await axiosHTTP.get(`/v1/scorecard-detail/${id}/`);
    return get.data;
  }

  async moveScorecard(order) {
    const post = await axiosHTTP.put(
      `/v1/scorecard-detail-order-update/`,
      JSON.stringify({ scorecard_detail_ids: order })
    );
    return post.data;
  }
}
