import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class Companies {
  async getCompaniesUser() {
    const getAllCompanies = await axiosHTTP.get("/v1/companies-user/");
    return getAllCompanies.data;
  }

  async postCompany(value) {
    const postCompany = await axiosHTTP.post(
      "/v1/companies/",
      JSON.stringify({
        zipcode: value.zipcode,
        is_active: value.active,
        city: value.city || null,
        timezone: value.timezone,
        company: value.dealership,
        phone: value.phone || null,
        state: value.state || null,
        is_reseller: value.isReseller,
        website: value.website || null,
        address1: value.address1 || null,
        address2: value.address2 || null,
        company_type: value.dealerType || null,
        scheduler_link: value.scheduleLink || null,
        parent_dealership: value.parentDealer || null,
        points: typeof value.points === "number" ? value.points : null,
      })
    );
    return postCompany.data;
  }

  async putCompany(id, value) {
    const put = await axiosHTTP.put(
      `/v1/companies/${id}/`,
      JSON.stringify({
        id,
        zipcode: value.zipcode,
        is_active: value.active,
        city: value.city || null,
        timezone: value.timezone,
        company: value.dealership,
        phone: value.phone || null,
        state: value.state || null,
        is_reseller: value.isReseller,
        website: value.website || null,
        address1: value.address1 || null,
        address2: value.address2 || null,
        company_type: value.dealerType || null,
        scheduler_link: value.scheduleLink || null,
        parent_dealership: value.parentDealer || null,
        points: typeof value.points === "number" ? value.points : null,
      })
    );
    return put.data;
  }

  async getDealerById(id) {
    const get = await axiosHTTP.get(`/v1/companies/${id}/`);
    return get.data;
  }

  async getVendorCompany(id) {
    const get = await axiosHTTP.get("/v1/vendor-dealer/", {
      params: { company: id },
    });
    return get.data;
  }

  async getVendorInfo(id) {
    const get = await axiosHTTP.get(`/v1/vendor-dealer/${id}/`);
    return get.data;
  }

  async postVendorCompany(company, value) {
    const { vendor, code1, code2, username, password } = value;
    const post = await axiosHTTP.post(
      "/v1/vendor-dealer/",
      JSON.stringify({
        vendor: vendor,
        company: company,
        code1: code1 || null,
        code2: code2 || null,
        username: username || null,
        password: password || null,
      })
    );
    return post.data;
  }

  async putVendorCompany(id, company, value) {
    const { vendor, code1, code2, username, password } = value;
    const put = await axiosHTTP.put(
      `/v1/vendor-dealer/${id}/`,
      JSON.stringify({
        id: id,
        vendor: vendor,
        company: company,
        code1: code1 || null,
        code2: code2 || null,
        username: username || null,
        password: password || null,
      })
    );
    return put.data;
  }

  async deleteVendorCompany(id) {
    const deleted = await axiosHTTP.delete(
      `/v1/vendor-dealer/${id}/`,
      JSON.stringify({ id: id })
    );
    return deleted;
  }

  async changeCompany(id) {
    const change = await axiosHTTP.post(
      `/v1/change-company/`,
      JSON.stringify({ changecompany: id }),
      {
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
          "Content-Type": "application/json",
        },
      }
    );
    return change.data;
  }

  async mpiCompany() {
    const get = await axiosHTTP.get(`/v1/dashboard/`);
    return get.data;
  }

  async leaderboardDealer() {
    const get = await axiosHTTP.get(`/v1/leaderboard/`);
    return get.data;
  }
}
