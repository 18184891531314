import React, { useState } from "react";
import * as Yup from "yup";
import { User } from "../../api";
import { Formik, Form } from "formik";
import { Button, Col } from "react-bootstrap";
import { yupValidation } from "../../functions/functions";
import Field from "../../CustomComponents/Field";

const FirstResetPassword = ({ change, user }) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState();

  const handleNewPassword = async ({ password, confirmPassword }) => {
    setDisabled(true);
    setError();
    if (password === confirmPassword) {
      await User.changeUserPassword(user.user_id, password, confirmPassword)
        .then(() => change())
        .catch(() => setError("Something wrong"))
        .finally(() => setDisabled(false));
    } else {
      setError("passwords do not match");
      setDisabled(false);
    }
  };

  return (
    <div className="container_content">
      <div className="content">
        <div className="fs-5 mb-2">
          As part of our commitment to ensuring the utmost security for our
          users, all new accounts must change their password after initial
          login.
        </div>
        <Formik
          onSubmit={handleNewPassword}
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={Yup.object({
            password: yupValidation().password,
            confirmPassword: yupValidation().confirmPassword,
          })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="set-first-password">
                <Col>
                  <Field.Control
                    id="password"
                    type="password"
                    placeholder="******"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    label="Enter Your New Password"
                  />
                </Col>
                <Col>
                  <Field.Control
                    type="password"
                    placeholder="******"
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    label="Confirm Your New Password"
                    value={formik.values.confirmPassword}
                  />
                </Col>
              </div>
              {error && <div className="text-danger mt-1">{error}</div>}
              <div className="fs-5 my-2">
                Remember to keep your password confidential and refrain from
                sharing it with anyone. Regularly changing your password is an
                important security practice that helps safeguard your personal
                data and prevents unauthorized access to your account.
              </div>
              <div className="fs-5">
                If you encounter any difficulties or require further assistance,
                please don't hesitate to contact our{" "}
                <a
                  href="mailto: support@gearheadtraining.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  customer support team
                </a>
                . We are here to help you through the process and address any
                concerns you may have.
              </div>
              <Button className="mt-2" type="submit" disabled={disabled}>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FirstResetPassword;
