/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { MdDone, MdOutlineDangerous } from "react-icons/md";
import moment from "moment";
import RewardPointsModal from "./RewardPointsModal";
import logoSmall from "../../image/logo/logoSmall.png";
import congratsImg from "../../image/points/congratulation-points.png";

const NotifyContainer = ({ notify, setNotify, user }) => {
  const [toasts, setToasts] = useState([]);
  const [modals, setModals] = useState([]);

  useEffect(() => {
    notify && showToast(notify);
  }, [notify]);

  useEffect(() => {
    toasts.length === 0 && setNotify();
    modals.length === 0 && setNotify();
  }, [toasts, modals]);

  const handleToastClose = (closedToast) => setToasts([]);
  // setToasts((prev) => prev.filter((toast) => toast !== closedToast)); // more than 1 system notify

  const showToast = ({ show, err, points, toastPoints, ...settings }) => {
    if (points) {
      const close = () => setModals([]);
      const newModal = (
        <Fragment key={Date.now()}>
          {points && (
            <RewardPointsModal
              user={user}
              points={points}
              onClose={close}
              rewardType={settings.rewardType}
            />
          )}
        </Fragment>
      );
      setModals([newModal]);
    }
    if (err || show || toastPoints) {
      const close = () => handleToastClose(newToast);
      const newToast = (
        <Fragment key={Date.now()}>
          {err && <ErrorNotify onClose={close} />}
          {show && <SuccessNotify onClose={close} />}
          {toastPoints && (
            <PointsRewardNotify
              user={user}
              onClose={close}
              points={toastPoints}
            />
          )}
        </Fragment>
      );
      setToasts([newToast]);
      // setToasts((prevToasts) => [...prevToasts, newToast]); // more than 1 system notify
    }
  };

  return (
    <>
      <ToastContainer
        position="top-end"
        className="position-fixed"
        style={{ margin: "90px 10px" }}
      >
        {toasts}
      </ToastContainer>
      {modals}
    </>
  );
};

export default NotifyContainer;

const SuccessNotify = ({ onClose }) => (
  <Toast autohide bg="success" delay={1500} onClose={onClose}>
    <Header />
    <Toast.Body>
      <div className="fs-4 text-white">
        <MdDone size={30} className="mb-1 mx-2" />
        Done
      </div>
    </Toast.Body>
  </Toast>
);

const ErrorNotify = ({ onClose }) => (
  <Toast autohide bg="danger" delay={1500} onClose={onClose}>
    <Header />
    <Toast.Body>
      <div className="fs-4 text-white">
        <MdOutlineDangerous size={30} className="mb-1 mx-2" />
        Something wrong
      </div>
    </Toast.Body>
  </Toast>
);

const PointsRewardNotify = ({ onClose, points, rewardType, user }) => (
  <Toast
    autohide
    delay={6000}
    onClose={onClose}
    className="toast-points-reward"
  >
    <Header title="Congratulations" />
    <Toast.Body>
      <div className="reward-points-modal">
        <div>
          <img src={congratsImg} className="congrats" alt="Congratulations" />
        </div>
        <div className="fs-2">Congratulations {user.first_name}!</div>
        <div className="fs-5">
          You have earned <span className="fs-3 fw-bold">+ {points} pts</span>{" "}
          for successfully completing {rewardType || "Content"}.
        </div>
      </div>
    </Toast.Body>
  </Toast>
);

const Header = ({ title }) => (
  <Toast.Header>
    <img
      alt=""
      width="25"
      height="25"
      src={logoSmall}
      className="rounded me-2"
    />
    <strong className="me-auto">{title || "Notify"}</strong>
    <small>{moment(new Date()).format("LTS")}</small>
  </Toast.Header>
);
