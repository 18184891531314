import { axiosHTTP } from "../Hook/UserAuthorization";

export class FormData {
  async getFormData() {
    const get = await axiosHTTP.get(`/v1/form/`);
    return get.data;
  }

  async getFormInfo(id) {
    const get = await axiosHTTP.get(`/v1/form/${id}/`);
    return get.data;
  }

  async updateForm(id, { description, folder }) {
    const get = await axiosHTTP.patch(
      `/v1/form/${id}/`,
      JSON.stringify({ display: description, folder: folder })
    );
    return get.data;
  }

  async uploadForm(form) {
    const post = await axiosHTTP.post("/v1/form/", form, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
    });
    return post.data;
  }

  async deleteForm(id) {
    const del = await axiosHTTP.delete(
      `/v1/form/${id}/`,
      JSON.stringify({ id: id })
    );
    return del.data;
  }

  async searchForm(search, folder) {
    const find = await axiosHTTP.get(`/v1/form/`, {
      params: { search: search, folder: null },
    });
    return find.data;
  }
}
