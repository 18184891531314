import Field from "..";
import { ErrorMessage } from "formik";
import DefaultEditor from "./DefaultEditor";
import EmailEditor from "./EmailEditor";

const Editor = ({ showLabel, labelContent, ...props }) => {
  return (
    <>
      {showLabel && (
        <Field.Label labelContent={labelContent}>{props.label}</Field.Label>
      )}
      <FieldEditor {...props} />
      <div className="text-danger">
        {props.id && <ErrorMessage name={props.id} />}
      </div>
    </>
  );
};

export default Editor;

Editor.defaultProps = {
  id: null,
  label: "",
  type: "default",
  showLabel: true,
};

const FieldEditor = ({ id, type }) => <>{typeEditor(type, id)}</>;

const typeEditor = (type, id) => {
  switch (type) {
    case "default":
      return <DefaultEditor name={id} />;
    case "email":
      return <EmailEditor name={id} />;
    default:
      <></>;
  }
};
