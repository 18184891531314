import { axiosHTTP } from "../Hook/UserAuthorization";

export class Images {
  async getImageData(company) {
    const get = await axiosHTTP.get("/v1/image/", {
      params: { company_id: company },
    });
    return get.data;
  }

  async postImage(file) {
    const post = await axiosHTTP.post("/v1/image/", file, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
      },
    });
    return post.data;
  }

  async deleteImage(id) {
    const del = await axiosHTTP.delete(
      `/v1/image/${id}/`,
      JSON.stringify({ id: id })
    );
    return del.data;
  }

  async searchImage(search) {
    const find = await axiosHTTP.get("/v1/image/", {
      params: { search: search },
    });
    return find.data;
  }
}
