import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class NotificationData {
  async getNotificationData() {
    const get = await axiosHTTP.get("/v1/notification/");
    return get.data;
  }

  async postNotification({ display, type }) {
    const post = await axiosHTTP.post(
      "/v1/notification/",
      JSON.stringify({
        is_active: true,
        display: display,
        message_type: type,
      })
    );
    return post.data;
  }

  async putNotification(id, { display, type }) {
    const put = await axiosHTTP.put(
      `/v1/notification/${id}/`,
      JSON.stringify({
        id: id,
        is_active: true,
        display: display,
        message_type: type,
      })
    );
    return put.data;
  }

  async searchNotification(search) {
    const get = await axiosHTTP.get("/v1/notification/", {
      params: { search: search },
    });
    return get.data;
  }

  async getNotificationUser(id) {
    const get = await axiosHTTP.get("/v1/notification-user/", {
      params: { employee: id },
    });
    return get.data;
  }

  async saveNotificationsUser(data) {
    const post = await axiosHTTP.post(
      "/v1/notification-user-update/",
      JSON.stringify(data)
    );
    return post.data;
  }

  async getNotificationInfo(id) {
    const get = await axiosHTTP.get(`/v1/notification-user/${id}/`);
    return get.data;
  }

  async getNotifyInfo(id) {
    const get = await axiosHTTP.get(`/v1/notification/${id}/`);
    return get.data;
  }

  async postNotificationUser(empId, notify) {
    const post = await axiosHTTP.post(
      `/v1/notification-user/`,
      JSON.stringify({
        employee: empId,
        notification: notify,
        company: getCookie("company"),
      })
    );
    return post.data;
  }

  async putNotificationUser(id, empId, notify) {
    const put = await axiosHTTP.put(
      `/v1/notification-user/${id}/`,
      JSON.stringify({
        id: id,
        employee: empId,
        notification: notify,
        company: getCookie("company"),
      })
    );
    return put.data;
  }

  async deleteNotificationUser(id) {
    const del = await axiosHTTP.delete(
      `/v1/notification-user/${id}/`,
      JSON.stringify({ id: id })
    );
    return del.data;
  }
}
