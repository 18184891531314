import { folderPermission, getCookie } from "../functions/functions";
import { axiosHTTP } from "../Hook/UserAuthorization";

export class FolderData {
  async getFolders(search) {
    const get = await axiosHTTP.get(`/v1/folder/`, {
      params: { search: search },
    });
    return get.data;
  }

  async getFolderInfo(id, default_folder) {
    const get = await axiosHTTP.get(`/v1/folder/${id}/`, {
      params: { default_folder },
    });
    return get.data;
  }

  async postFolder({ active, name, permissions }) {
    const post = await axiosHTTP.post(
      `/v1/folder/`,
      JSON.stringify({
        display: name,
        is_active: active,
        company: getCookie("company"),
        ...folderPermission.parse(permissions),
      })
    );
    return post.data;
  }

  async putFolder(id, { active, name, permissions }) {
    const post = await axiosHTTP.patch(
      `/v1/folder/${id}/`,
      JSON.stringify({
        display: name,
        is_active: active,
        ...folderPermission.parse(permissions),
      })
    );
    return post.data;
  }
  async deleteFolder(id) {
    const del = await axiosHTTP.delete(`/v1/folder-custom-permission/${id}/`);
    return del.data;
  }

  async postCustomFolderPermissions(id, permissions) {
    const get = await axiosHTTP.post(
      `/v1/folder-custom-permission/`,
      JSON.stringify({
        folder: id,
        is_active: true,
        company: getCookie("company"),
        ...folderPermission.parse(permissions),
      })
    );
    return get.data;
  }

  async putCustomFolderPermissions(id, permissions) {
    const get = await axiosHTTP.patch(
      `/v1/folder-custom-permission/${id}/`,
      JSON.stringify({ ...folderPermission.parse(permissions) })
    );
    return get.data;
  }
}
