import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class UsersData {
  async getUser() {
    const getUser = await axiosHTTP.get(`/v1/user-login/`);
    return getUser.data;
  }

  async checkUser(company) {
    const getUser = await axiosHTTP.get(`/v1/user-check-access/`, {
      params: { company: company },
    });
    return getUser.data;
  }

  async getUserPermission() {
    const getUser = await axiosHTTP.get(`/v1/users-info-security/`);
    return getUser.data;
  }

  async getUserByGlobal(id) {
    const getUser = await axiosHTTP.get(`/v1/user-dealer-link/`, {
      params: { ids: id },
    });
    return getUser.data;
  }
  async logout() {
    const logout = await axiosHTTP.post(
      `/v1/logout/`,
      {},
      {
        headers: {
          "X-CSRFToken": getCookie("csrftoken"),
        },
      }
    );
    return logout;
  }

  async getUserById(id) {
    const getUser = await axiosHTTP.get("/v1/users/", { params: { user: id } });
    return getUser.data;
  }

  async createUser(data) {
    const post = await axiosHTTP.post(`/v1/create/user/`, JSON.stringify(data));
    return post.data;
  }

  async uploadFileWithUsers(file, progress) {
    const formData = new FormData();
    formData.append("file", file);
    const post = await axiosHTTP.post(`/v1/create/user/file/`, formData, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      onUploadProgress: (e) => progress(Math.round((e.loaded / e.total) * 100)),
    });
    return post.data;
  }

  async assignUserToDealer({ user, employee_type }) {
    const post = await axiosHTTP.post(
      `/v1/add-user-dealership/`,
      JSON.stringify({ user_id: user[0].id, emp_type: employee_type })
    );
    return post.data;
  }

  async putUser({ id, user }, { structure, cellPhone, workPhone }) {
    const put = await axiosHTTP.put(
      `/v1/users/${id}/`,
      JSON.stringify({
        id: id,
        user: user.id,
        phone_cell: cellPhone || null,
        phone_business: workPhone || null,
        location: structure ? structure.split(",") : [],
      })
    );
    return put.data;
  }

  async putUserPhoto(id, photo, user) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("user", user);
    formData.append("photo", photo);

    const put = await axiosHTTP.put(`/v1/users/${id}/`, formData, {
      headers: {
        "Content-Type":
          "multipart/form-data;boundary=----WebKitFormBoundary8iMGQp8rji9rphaS",
        "X-CSRFToken": getCookie("csrftoken"),
      },
    });
    return put.data;
  }

  async deleteUserPhoto(user) {
    const put = await axiosHTTP.put(
      `/v1/users/${user.id}/`,
      JSON.stringify({ id: user.id, photo: null, user: user.user.id })
    );
    return put.data;
  }

  async changeUserEmail(id, email) {
    const post = await axiosHTTP.post(
      `/v1/email-change/`,
      JSON.stringify({ user_id: id, email: email })
    );
    return post.data;
  }

  async changeUserPassword(id, password, confirmPassword) {
    const post = await axiosHTTP.post(
      `/v1/password-change/`,
      JSON.stringify({
        user_id: id,
        new_password: password,
        confirm_password: confirmPassword,
      })
    );
    return post.data;
  }

  async putUserDealer(id, value) {
    const put = await axiosHTTP.patch(
      `/v1/user-dealer-link/${id}/`,
      JSON.stringify({
        id: id,
        user: {
          last_name: value.lastName,
          first_name: value.firstName,
        },
        is_active: value.active,
        team: value.team || null,
        emptype: value.employeeType,
        is_admin: value.dealerAdmin,
        is_default: value.defaultDealer,
        manager: Number(value.reportsTo),
        ctc_phone1: value.ctcPhone1 || null,
        ctc_phone2: value.ctcPhone2 || null,
        scheduler_link: value.scheduleLink || null,
      })
    );
    return put.data;
  }

  async getUserDealer(search) {
    const get = await axiosHTTP.get("/v1/user-dealer-link/", {
      params: { search: search, company: getCookie("company") },
    });
    return get.data;
  }

  async getUsersWithBuckets() {
    const get = await axiosHTTP.get("/v1/user-dealer-link-reseller/");
    return get.data;
  }

  async getUsersByDealer(company) {
    const get = await axiosHTTP.get("/v1/user-dealer-link/", {
      params: { company: company },
    });
    return get.data;
  }

  async getUsers(id) {
    const get = await axiosHTTP.get("/v1/users/", { params: { user__id: id } });
    return get.data;
  }

  async getUserCompanies(user) {
    const get = await axiosHTTP.get("/v1/user-dealer-link/", {
      params: { user: user },
    });
    return get.data;
  }

  async getUserDealerInfo(id) {
    const get = await axiosHTTP.get(`/v1/user-dealer-link/${id}/`);
    return get.data;
  }

  async getUserInfoDealer(id) {
    const get = await axiosHTTP.get(`/v1/user-dealer-link/`, {
      params: { user: id },
    });
    return get.data;
  }

  async getVendorById(id) {
    const get = await axiosHTTP.get(`/v1/vendor-user/${id}/`);
    return get.data;
  }

  async getVendorInfo(id) {
    const get = await axiosHTTP.get(`/v1/vendor-user/`, {
      params: { employee: id },
    });
    return get.data;
  }

  async postUserVendor(employee, value) {
    const { vendor, code1, code2, code3, code4 } = value;
    const post = await axiosHTTP.post(
      "/v1/vendor-user/",
      JSON.stringify({
        code1: code1,
        code2: code2,
        code3: code3,
        code4: code4,
        vendor: vendor,
        employee: employee,
        company: getCookie("company"),
      })
    );
    return post.data;
  }

  async putUserVendor(id, employee, value) {
    const { vendor, code1, code2, code3, code4 } = value;
    const put = await axiosHTTP.put(
      `/v1/vendor-user/${id}/`,
      JSON.stringify({
        id: id,
        code1: code1,
        code2: code2,
        code3: code3,
        code4: code4,
        vendor: vendor,
        employee: employee,
        company: getCookie("company"),
      })
    );
    return put.data;
  }

  async deleteUserVendor(id) {
    const edit = await axiosHTTP.delete(
      `/v1/vendor-user/${id}/`,
      JSON.stringify({ id: id })
    );
    return edit.data;
  }
}
