import Field from "..";
import { ErrorMessage } from "formik";
import FileInput from "./FileInput";

const File = ({ showLabel, labelContent, ...props }) => {
  return (
    <>
      {showLabel && props.label && (
        <Field.Label labelContent={labelContent} htmlFor={props.id}>
          {props.label}
        </Field.Label>
      )}
      <FileInput {...props} />
      {props.id && (
        <div className="text-danger">
          <ErrorMessage name={props.id} />
        </div>
      )}
    </>
  );
};

export default File;

File.defaultProps = {
  id: null,
  label: "",
  showLabel: true,
};
