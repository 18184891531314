import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import DynamicForm from "./DynamicForm";
import { SystemActions } from "../../Context";

const SurveyReviewsModal = ({ showModal }) => {
  const { setShowSurveyModal } = useContext(SystemActions);
  const [show, setShow] = useState(false);
  const [error, setError] = useState();

  useEffect(() => setShow(showModal.show), [showModal]);

  const handleClose = () => {
    if (showModal.skipFunc) showModal.skipFunc(showModal.content);
    else {
      setShow(false);
      setShowSurveyModal({ show: false });
    }
  };

  const sendSurvey = async (values) => {
    setError();
    showModal.completeFunc
      ? showModal.completeFunc(values, showModal.content)
      : setShowSurveyModal({ show: false });
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        keyboard={false}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header closeButton={!showModal.required}>
          <Modal.Title>Survey</Modal.Title>
        </Modal.Header>
        {showModal.survey && (
          <DynamicForm send={(e) => sendSurvey(e)} formData={showModal.survey}>
            <>
              {error && <div className="mx-2 text-danger">{error}</div>}
              <Modal.Footer>
                {!showModal.required && (
                  <Button onClick={handleClose} variant="secondary">
                    Maybe Later
                  </Button>
                )}
                <Button type="submit">Save</Button>
              </Modal.Footer>
            </>
          </DynamicForm>
        )}
      </Modal>
    </>
  );
};

export default SurveyReviewsModal;
