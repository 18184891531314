import { axiosHTTP } from "../Hook/UserAuthorization";
import {
  getCookie,
  formatDate,
  reminderGenerate,
} from "../functions/functions";

export class Reminder {
  async getCall(startDate, endDate, emp) {
    const get = await axiosHTTP.post(
      "/v1/call-reminder-search/",
      JSON.stringify({
        employee: emp,
        end_date: endDate,
        call_date: startDate,
        company: getCookie("company"),
      })
    );
    return get.data;
  }

  async getCallById(id) {
    const get = await axiosHTTP.get(`/v1/call-reminder/${id}/`, {
      params: { company: getCookie("company") },
    });
    return get.data;
  }

  async postCall(value) {
    const { scheduleFor, roTag, customerName, notes, callDate, callTime } =
      value;
    const post = await axiosHTTP.post(
      "/v1/call-reminder/",
      JSON.stringify({
        note: notes,
        disposition: "",
        user: scheduleFor,
        ronum_tagnum: roTag,
        customer: customerName,
        company: getCookie("company"),
        reminder: reminderGenerate(callDate, callTime),
      })
    );
    return post.data;
  }

  async putCall(id, value) {
    const { scheduleFor, roTag, customerName, notes, callDate, callTime } =
      value;
    const put = await axiosHTTP.put(
      `/v1/call-reminder/${id}/`,
      JSON.stringify({
        id: id,
        note: notes,
        disposition: "",
        user: scheduleFor,
        ronum_tagnum: roTag,
        customer: customerName,
        company: getCookie("company"),
        reminder: reminderGenerate(callDate, callTime),
      })
    );
    return put.data;
  }

  async deleteCall(id) {
    const post = await axiosHTTP.delete(
      `/v1/call-reminder/${id}/`,
      JSON.stringify({ id: id })
    );
    return post.data;
  }

  async snoozeCall(id, user) {
    const post = await axiosHTTP.put(
      `/v1/call-reminder/${id}/`,
      JSON.stringify({
        id: id,
        user: user,
        notification_sent: false,
        company: getCookie("company"),
      })
    );
    return post.data;
  }

  async completeCall({ id, reschedule, disposition, newData }) {
    const post = await axiosHTTP.post(
      `/v1/call-reminder-disp/`,
      JSON.stringify({
        mc_id: id,
        call_id: reschedule,
        disposition: disposition,
        employee: newData.user_id,
        note: newData.note || null,
        call_time: newData.reminder_time,
        customer: newData.customer || null,
        ronum_tagnum: newData.ronum_tagnum,
        call_date: formatDate(newData.reminder, "YYYY-MM-DD"),
      })
    );
    return post.data;
  }
}
