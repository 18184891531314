import { User } from "../../../api";
import { useNavigate } from "react-router-dom";
import { FiLogOut, FiUser } from "react-icons/fi";
import { NavDropdown, Stack } from "react-bootstrap";
import avatar from "../../../image/avatar/generic-avatar.jpg";
import { userName } from "../../../functions/functions";

const UserAndNotifyNav = ({ permission, onLogOut }) => {
  const navigate = useNavigate();

  const logOut = () => {
    User.logout();
    onLogOut();
    navigate("/");
  };
  if (permission)
    return (
      <div className="d-flex gap-1">
        <NavDropdown
          align="end"
          draggable={false}
          renderMenuOnMount={true}
          title={
            <div className="new-content-nav-bar">
              <span>+</span>
            </div>
          }
        >
          <NavDropdown.Item
            onClick={() => navigate("/common/call_reminder/new")}
          >
            Add New Call Reminder
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate("/tasks/todo/new")}>
            Add New ToDo
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown
          align="end"
          draggable={false}
          renderMenuOnMount={true}
          title={
            <div className="avatar-indicators avatar-online">
              <img
                alt="Avatar"
                src={permission.photo || avatar}
                className="rounded-circle w-100 avatar-md"
              />
            </div>
          }
        >
          <Stack direction="horizontal" className="px-3 pb-1">
            <div className="avatar avatar-md avatar-indicators avatar-online">
              <img
                alt="Avatar"
                src={permission.photo || avatar}
                className="rounded-circle w-100 avatar-md"
              />
            </div>
            <div className="mx-2 fs-7">
              <div>{userName(permission)}</div>
              <div className="text-muted">{permission.email}</div>
            </div>
          </Stack>
          {permission.id && (
            <NavDropdown.Item
              draggable={false}
              onClick={() => navigate(`/common/employee/${permission.id}/info`)}
            >
              <FiUser /> Profile
            </NavDropdown.Item>
          )}
          <NavDropdown.Item
            onClick={logOut}
            draggable={false}
            className="text-danger"
          >
            <FiLogOut /> Sign Out
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    );
};

export default UserAndNotifyNav;
