import { axiosHTTP } from "../Hook/UserAuthorization";
import { getCookie } from "../functions/functions";

export class BucketData {
  async getBuckets(search) {
    const get = await axiosHTTP.get("/v1/user-dealer-link-bucket/", {
      params: { company: getCookie("company"), search: search },
    });
    return get.data;
  }

  async getBucketUsers(id) {
    const get = await axiosHTTP.get("/v1/bucket-user/", {
      params: { bucket: id },
    });
    return get.data;
  }

  async checkUserInBucket(id) {
    const get = await axiosHTTP.get("/v1/bucket-user/", {
      params: { user: id },
    });
    return get.data;
  }

  async postBucket(display) {
    const get = await axiosHTTP.post(
      "/v1/create/bucket/",
      JSON.stringify({ is_active: true, display: display })
    );
    return get.data;
  }

  async putBucket(display, active, user, id) {
    const get = await axiosHTTP.post(
      "/v1/update/bucket/",
      JSON.stringify({
        id: id,
        user_id: user,
        display: display,
        is_active: active,
      })
    );
    return get.data;
  }

  async deleteBucketUser(id) {
    const get = await axiosHTTP.delete(
      `/v1/bucket-user/${id}/`,
      JSON.stringify({ id: id })
    );
    return get.data;
  }

  async postBucketUser(bucket, user) {
    const post = await axiosHTTP.post(
      `/v1/bucket-user/`,
      JSON.stringify({ bucket: bucket, user: user })
    );
    return post.data;
  }
}
