import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Page404 from "../CustomComponents/Error/Page404";

const LeaderboardDetails = lazy(() =>
  import("../components/Reports/LeaderboardDetails")
);
const QuizByTranscription = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/Courses/QuizByTranscription/QuizByTranscription"
  )
);
const MenuOpCodeSettings = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/MenuOpCodes/MenuOpCodeSettings/MenuOpCodeSettings"
  )
);
const MenuOpCodes = lazy(() =>
  import("../components/ Settings/DealerSettings/MenuOpCodes/MenuOpCodes")
);
const TodoTemplatesAddEdit = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/TodoTemplates/TodoTemplatesAddEdit/TodoTemplatesAddEdit"
  )
);
const TodoTemplates = lazy(() =>
  import("../components/ Settings/GlobalSettings/TodoTemplates/TodoTemplates")
);
const PerformanceTrendTech = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceTrendTech/PerformanceTrendTech"
  )
);
const ServiceTrendAdvisor = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceTrendAdvisor/ServiceTrendAdvisor"
  )
);
const PerformanceReportTechSummary = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceReportTechSummary/PerformanceReportTechSummary"
  )
);
const PerformanceReportTechnician = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceReportTechnician/PerformanceReportTechnician"
  )
);
const PerformanceReportAdvisor = lazy(() =>
  import(
    "../components/Reports/Performance/PerformanceReportAdvisor/PerformanceReportAdvisor"
  )
);
const AllTodo = lazy(() => import("../components/Actions/AllTodo/AllTodo"));
const AssignTodo = lazy(() =>
  import("../components/Actions/AssignTodo/AssignTodo")
);
const MessageLog = lazy(() =>
  import("../components/ Settings/GlobalSettings/MessageLog/MessageLog")
);
const ServiceRankingSummaryReportByTech = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceRankingSummaryReportByTech/ServiceRankingSummaryReportByTech"
  )
);
const EnterprisePremSumByTechnicianReport = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePremSumByTechnicianReport/EnterprisePremSumByTechnicianReport"
  )
);
const SurveyResult = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/Survey/SurveyResult/SurveyResult"
  )
);
const SurveyResultDetail = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/Survey/SurveyResult/SurveyResultDetail/SurveyResultDetail"
  )
);
const SurveyDetail = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/Survey/SurveyList/SurveyDetail/SurveyDetail"
  )
);
const SurveyList = lazy(() =>
  import("../components/ Settings/GlobalSettings/Survey/SurveyList/SurveyList")
);
const SurveyAssign = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/Survey/SurveyAssign/SurveyAssign"
  )
);
const SurveyAssignEdit = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/Survey/SurveyAssign/SurveyAssignEdit/SurveyAssignEdit"
  )
);
const GoalModulePage = lazy(() =>
  import("../components/ Settings/DealerSettings/GoalModule/GoalModulePage")
);
const WhiteLabelDetail = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/WhiteLabel/WhiteLabelSettings/WhiteLabelDetail"
  )
);
const WhiteLabel = lazy(() =>
  import("../components/ Settings/GlobalSettings/WhiteLabel/WhiteLabel")
);
const FolderDefault = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/FoldersForm/FolderDefault/FolderDefault"
  )
);
const Folders = lazy(() =>
  import("../components/ Settings/DealerSettings/FoldersForm/Folders")
);
const FolderDetail = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/FoldersForm/FolderDetail/FolderDetail"
  )
);
const DashboardPage = lazy(() =>
  import("../components/DashboardPage/DashboardPage")
);
const MessagesPage = lazy(() =>
  import("../components/MessagesPage/MessagesPage")
);
const FormSearchPage = lazy(() =>
  import("../components/Actions/FormSearchPage/FormSearchPage")
);
const FormUploadPage = lazy(() =>
  import("../components/Actions/FormSearchPage/FormUploadPage/FormUploadPage")
);
const TasksPage = lazy(() =>
  import("../components/Actions/TasksPage/TasksPage")
);
const VideoLibraryPage = lazy(() =>
  import("../components/Actions/VideoLibraryPage/VideoLibraryPage")
);
const TaskView = lazy(() =>
  import("../components/Actions/TasksPage/TaskView/TaskView")
);
const StructureAddEdit = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/EnterpriseReportingStructure/StructureAddEdit/StructureAddEdit"
  )
);
const EnterpriseReportingStructure = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/EnterpriseReportingStructure/EnterpriseReportingStructure"
  )
);
const CoursesAssigned = lazy(() =>
  import("../components/Reports/CoursesAssigned/CoursesAssigned")
);
const ToDealer = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/EmployeesPage/EmployeeNew/AddToDealer/ToDealer"
  )
);
const ROsPage = lazy(() => import("../components/Reports/ROsPage/ROsPage"));
const OpenRoSummary = lazy(() =>
  import("../components/Reports/OpenRoSummary/OpenRoSummary")
);

const TasksProgressReport = lazy(() =>
  import("../components/Reports/TasksProgressReport/TasksProgressReport")
);
const DealershipsPage = lazy(() =>
  import("../components/ Settings/GlobalSettings/Dealerships/DealershipsPage")
);
const DealershipsSettingsPage = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/Dealerships/DealershipsSettingsPage/DealershipsSettingsPage"
  )
);
const Integrations = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/Dealerships/DealershipsSettingsPage/Integrations/Integrations"
  )
);
const ImageSearch = lazy(() =>
  import("../components/ Settings/GlobalSettings/ImageSearch/ImageSearch")
);
const ImageUpload = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/ImageSearch/ImageUpload/ImageUpload"
  )
);
const ListSearch = lazy(() =>
  import("../components/ Settings/GlobalSettings/ListsSearch/ListSearch")
);
const NewItem = lazy(() =>
  import("../components/ Settings/GlobalSettings/ListsSearch/NewItem/NewItem")
);
const NotificationNew = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/Notifications/NotificationNew/NotificationNew"
  )
);
const Notifications = lazy(() =>
  import("../components/ Settings/GlobalSettings/Notifications/Notifications")
);
const AssignNewTask = lazy(() =>
  import("../components/ Settings/DealerSettings/AssignNewTask/AssignNewTask")
);
const AssignTaskSchedule = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/AssignTaskSchedule/AssignTaskSchedule"
  )
);
const BucketsPage = lazy(() =>
  import("../components/ Settings/DealerSettings/BucketsPage/BucketsPage")
);
const NewBuckets = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/BucketsPage/NewBuckets/NewBuckets"
  )
);
const EmployeesPage = lazy(() =>
  import("../components/ Settings/DealerSettings/EmployeesPage/EmployeesPage")
);
const EmployeeNew = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/EmployeesPage/EmployeeNew/EmployeeNew"
  )
);
const MessagesChannel = lazy(() =>
  import("../components/ Settings/DealerSettings/MessageChannel/MessageChannel")
);
const MessagesChannelNew = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/MessageChannel/MessagesChannelNew/MessagesChannelNew"
  )
);
const ScorecardPage = lazy(() =>
  import("../components/ Settings/DealerSettings/ScorecardPage/ScorecardPage")
);
const NewScorecard = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/ScorecardPage/NewScorecard/NewScorecard"
  )
);
const TaskSchedules = lazy(() =>
  import("../components/ Settings/DealerSettings/TaskSchedules/TaskSchedules")
);
const TaskScheduleNew = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/TaskSchedules/TaskScheduleNew/TaskScheduleNew"
  )
);

const VideoLibrary = lazy(() =>
  import("../components/ Settings/DealerSettings/VideoLibrary/VideoLibrary")
);
const VideoLibraryNew = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/VideoLibrary/VideoLibraryNew/VideoLibraryNew"
  )
);
const AssignCourses = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/Courses/AssignCourses/AssignCourses"
  )
);
const Instructors = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/Courses/Instructors/Instructors"
  )
);
const InstructorNew = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/Courses/Instructors/InstructorNew/InstructorNew"
  )
);
const MediaCourses = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/Courses/MediaCourses/MediaCourses"
  )
);
const MediaNew = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/Courses/MediaCourses/MediaNew/MediaNew"
  )
);
const Courses = lazy(() =>
  import("../components/ Settings/DealerSettings/Courses/Courses/Courses")
);
const CourseNew = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/Courses/Courses/CourseNew/CourseNew"
  )
);
const Quizzes = lazy(() =>
  import("../components/ Settings/DealerSettings/Courses/Quizzes/Quizzes")
);
const QuizNew = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/Courses/Quizzes/QuizNew/QuizNew"
  )
);
const NewReminderCall = lazy(() =>
  import("../components/ReminderPage/NewReminderCall/NewReminderCall")
);
const ViewReminderCall = lazy(() =>
  import("../components/ReminderPage/ViewReminderCall/ViewReminderCall")
);
const ProfilePage = lazy(() =>
  import("../components/User/ProfilePage/ProfilePage")
);
const IntegrationsUserNew = lazy(() =>
  import(
    "../components/User/ProfilePage/IntegrationsUserNew/IntegrationsUserNew"
  )
);
const NotificationsUserNew = lazy(() =>
  import(
    "../components/User/ProfilePage/NotificationsUserNew/NotificationsUserNew"
  )
);
const ScorecardPreview = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/ScorecardPage/NewScorecard/ScorecardPreview"
  )
);
const ScorecardNewDetails = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/ScorecardPage/NewScorecard/NewDetails/ScorecardNewDetails"
  )
);
const ScheduleDetail = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/TaskSchedules/ScheduleDetail/ScheduleDetail"
  )
);
const PartsReplenishmentReport = lazy(() =>
  import(
    "../components/Reports/PartsReplenishmentReport/PartsReplenishmentReport"
  )
);
const ServiceReportByAdvisor = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceReportByAdvisor/ServiceReportByAdvisor"
  )
);
const ServiceReportByOpCode = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceReportByOpCode/ServiceReportByOpCode"
  )
);
const ServiceGrossProfitReport = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceGrossProfitReport/ServiceGrossProfitReport"
  )
);
const ServiceRankingSummaryReport = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceRankingSummaryReport/ServiceRankingSummaryReport"
  )
);
const Parts = lazy(() =>
  import("../components/ Settings/DealerSettings/PartsPage/Parts")
);
const PremiumServices = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/PremiumServices/PremiumServices"
  )
);
const PartsDetail = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/PartsPage/PartsDetail/PartsDetail"
  )
);
const PremiumServicesDetail = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/PremiumServices/PremiumServicesDetail/PremiumServicesDetail"
  )
);
const OpCodeDetail = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/PremiumServices/OpCodeDetail/OpCodeDetail"
  )
);
const CourseView = lazy(() =>
  import("../components/ Settings/DealerSettings/Courses/CourseView/CourseView")
);
const OpCodesUtilization = lazy(() =>
  import("../components/Reports/OpCodesUtilization/OpCodesUtilization")
);
const ServiceReportByTechnician = lazy(() =>
  import(
    "../components/Reports/Premium/ServiceReportByTechnician/ServiceReportByTechnician"
  )
);
const PagesDescriptions = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/PagesDescriptions/PagesDescriptions"
  )
);
const PageDescriptionEdit = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/PagesDescriptions/PageDescriptionEdit/PageDescriptionEdit"
  )
);
const GeneralCustomizeMenu = lazy(() =>
  import(
    "../components/ Settings/GlobalSettings/GeneralCustomizeMenu/GeneralCustomizeMenu"
  )
);
const EnterprisePremiumReport = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePremiumReport/EnterprisePremiumReport"
  )
);
const EnterprisePerformanceReport = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePerformanceReport/EnterprisePerformanceReport"
  )
);
const EmailTemplates = lazy(() =>
  import("../components/ Settings/DealerSettings/EmailTemplates/EmailTemplates")
);
const Templates = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/EmailTemplates/TemplatesAddEdit/Templates"
  )
);

const ImageTemplate = lazy(() =>
  import("../components/ Settings/DealerSettings/ImageTemplate/ImageTemplate")
);
const ReportsSchedule = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/ReportsSchedule/ReportsSchedule"
  )
);
const AddReportsSchedule = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/ReportsSchedule/AddReportsSchedule/AddReportsSchedule"
  )
);
const EnterprisePremiumGross = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePremiumGross/EnterprisePremiumGross"
  )
);
const EnterprisePremiumSummaryReport = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePremiumSummaryReport/EnterprisePremiumSummaryReport"
  )
);

const EnterprisePremSumByAdvisorReport = lazy(() =>
  import(
    "../components/Reports/Enterprise/EnterprisePremSumByAdvisorReport/EnterprisePremSumByAdvisorReport"
  )
);

const CoursesAssignedDetail = lazy(() =>
  import(
    "../components/Reports/CoursesAssigned/CoursesAssignedDetail/CoursesAssignedDetail"
  )
);
const TeamDealer = lazy(() =>
  import("../components/ Settings/DealerSettings/TeamDealer/TeamDealer")
);

const TeamDealerDetail = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/TeamDealer/TeamDealerDetail/TeamDealerDetail"
  )
);

const EventSchedules = lazy(() =>
  import("../components/ Settings/DealerSettings/EventSchedules/EventSchedules")
);

const EventSchedulesDetail = lazy(() =>
  import(
    "../components/ Settings/DealerSettings/EventSchedules/EventSchedulesDetail/EventSchedulesDetail"
  )
);

const Routs = ({ quote, permission }) => {
  return (
    <Routes>
      {/* dashboard */}
      <>
        <Route
          path="/accounts/dashboard"
          element={<DashboardPage quote={quote} />}
        />
      </>
      <>
        <Route
          path="/courses/course_view/:courseId/"
          element={<CourseView />}
        />
      </>
      {/* message center */}
      <>
        <Route path="/message_center" element={<MessagesPage />} />
      </>
      {/* actions page */}
      <>
        <Route path="/common/form_search" element={<FormSearchPage />} />
        <Route path="/common/form/:formId" element={<FormUploadPage />} />
      </>
      <>
        <Route path="/tasks/task_view/:taskId" element={<TaskView />} />
        <Route path="/tasks/task_search" element={<TasksPage />} />
        <Route
          path="/tasks/scorecard_preview/:scorecardPreviewId"
          element={<ScorecardPreview />}
        />
      </>
      <>
        <Route path="/tasks/todo_search" element={<AllTodo />} />
        <Route path="/tasks/todo/:todoId" element={<AssignTodo />} />
      </>
      <>
        <Route
          path="/common/video_library_play"
          element={<VideoLibraryPage />}
        />
      </>
      {/* reporting */}
      <Route path="/reporting/report_ro_search" element={<ROsPage />} />
      <Route
        path="/reporting/report_open_ro_summary"
        element={<OpenRoSummary />}
      />
      <Route
        path="/reporting/report_op_codes_utilized"
        element={<OpCodesUtilization />}
      />
      <Route
        path="/reporting/report_parts_replenishment"
        element={<PartsReplenishmentReport />}
      />
      <Route
        path="/reporting/leaderboard_details"
        element={<LeaderboardDetails />}
      />
      {/* premium */}
      <>
        <Route
          path="/reporting/report_premium_svcs_by_advisor"
          element={<ServiceReportByAdvisor />}
        />
        <Route
          path="/reporting/report_premium_svcs_by_op_code"
          element={<ServiceReportByOpCode />}
        />
        <Route
          path="/reporting/report_premium_svcs_by_technician"
          element={<ServiceReportByTechnician />}
        />
        <Route
          path="/reporting/report_premium_svcs_gross_profit"
          element={<ServiceGrossProfitReport />}
        />
        <Route
          path="/reporting/report_premium_svcs_ranking_summary"
          element={<ServiceRankingSummaryReport />}
        />
        <Route
          path="/reporting/report_premium_svcs_ranking_summary_technician"
          element={<ServiceRankingSummaryReportByTech />}
        />
        <Route
          path="/reporting/premium_service_trend_advisor"
          element={<ServiceTrendAdvisor />}
        />
      </>
      {/* // */}
      {/* performance */}
      <>
        <Route
          path="/reporting/report_svc_adv_performance_closed_ros"
          element={<PerformanceReportAdvisor />}
        />
        <Route
          path="/reporting/report_tech_performance_closed_ros"
          element={<PerformanceReportTechnician />}
        />
        <Route
          path="/reporting/report_tech_performance_summary"
          element={<PerformanceReportTechSummary />}
        />
        <Route
          path="/reporting/performance_trend_report_technician"
          element={<PerformanceTrendTech />}
        />
      </>
      {/* // */}
      <Route path="/report_progress_report" element={<TasksProgressReport />} />
      <Route path="/report_courses_assigned" element={<CoursesAssigned />} />
      {/* Enterprise */}
      <>
        <Route
          path="/enterprise/premium_report"
          element={<EnterprisePremiumReport />}
        />
        <Route
          path="/enterprise/enterprise_premium_summary"
          element={<EnterprisePremiumSummaryReport />}
        />
        <Route
          path="/enterprise/performance_report"
          element={<EnterprisePerformanceReport />}
        />
        <Route
          path="/enterprise/enterprise_premium_gross_profit"
          element={<EnterprisePremiumGross />}
        />
        <Route
          path="/enterprise/enterprise_premium_summary_advisor"
          element={<EnterprisePremSumByAdvisorReport />}
        />
        <Route
          path="/enterprise/enterprise_premium_summary_technician"
          element={<EnterprisePremSumByTechnicianReport />}
        />
      </>
      {/* // */}
      {(permission?.is_staff ||
        permission?.is_reseller ||
        permission?.is_admin) && (
        <>
          {/* dealerSettings */}
          <>
            <Route path="/common/menu_op_codes" element={<MenuOpCodes />} />
            <Route
              path="/common/menu_op_code_settings/:codeId"
              element={<MenuOpCodeSettings />}
            />
          </>
          <>
            <Route
              path="/report_courses_assigned/detail/:courseId"
              element={<CoursesAssignedDetail />}
            />
          </>
          <>
            <Route path="/tasks/task/:taskId" element={<AssignNewTask />} />
          </>
          <>
            <Route
              path="/tasks/task_schedule_assign"
              element={<AssignTaskSchedule />}
            />
          </>
          <>
            <Route path="/tasks/bucket_search" element={<BucketsPage />} />
            <Route path="/tasks/bucket/:bucketId" element={<NewBuckets />} />
          </>
          {/* course */}
          <>
            <Route path="/courses/course_assign" element={<AssignCourses />} />
            <>
              <Route
                path="/courses/instructor_search"
                element={<Instructors />}
              />
              <Route
                path="/courses/instructor/:instructorId"
                element={<InstructorNew />}
              />
            </>
            <>
              <Route path="/courses/media_search" element={<MediaCourses />} />
              <Route path="/courses/media/:mediaId" element={<MediaNew />} />
            </>
            <>
              <Route path="/courses/course_search" element={<Courses />} />
              <Route
                path="/courses/course/:courseId/*"
                element={<CourseNew />}
              />
            </>
            <>
              <Route path="/courses/quiz_search" element={<Quizzes />} />
              <Route path="/courses/quiz/:quizId/*" element={<QuizNew />} />
            </>
            <>
              <Route
                path="/courses/quiz_by_transcription/:recordId"
                element={<QuizByTranscription />}
              />
            </>
          </>
          {/* // */}
          <>
            <Route path="/common/employee_search" element={<EmployeesPage />} />
            {(permission?.is_staff || permission?.is_reseller) && (
              <>
                <Route
                  path="/common/employee_new/*"
                  element={<EmployeeNew />}
                />
                <Route
                  path="/common/employee_new_dealer"
                  element={<ToDealer />}
                />
              </>
            )}
          </>
          <>
            <Route
              path="/common/message_group_search"
              element={<MessagesChannel />}
            />
            <Route
              path="/common/message_group/:messageChanelId"
              element={<MessagesChannelNew />}
            />
          </>
          <>
            <Route path="/common/parts_repl_search" element={<Parts />} />
            <Route path="/common/parts/:partsId" element={<PartsDetail />} />
          </>
          <>
            <Route
              path="/common/op_code_group_search"
              element={<PremiumServices />}
            />
            <Route
              path="/common/op_code_group/:premiumId"
              element={<PremiumServicesDetail />}
            />
            <Route path="/common/op_code/:opId" element={<OpCodeDetail />} />
          </>
          <>
            <Route path="/tasks/scorecard_search" element={<ScorecardPage />} />
            <Route
              path="/tasks/scorecard/:scorecardId"
              element={<NewScorecard />}
            />
            <Route
              path="/tasks/scorecard_detail/:scorecardDetailId"
              element={<ScorecardNewDetails />}
            />
          </>
          <>
            <Route
              path="/tasks/task_schedule_search"
              element={<TaskSchedules />}
            />
            <Route
              path="/tasks/task_schedule/:taskScheduleId"
              element={<TaskScheduleNew />}
            />
            <Route
              path="/tasks/task_schedule_detail/:scheduleDetailId"
              element={<ScheduleDetail />}
            />
          </>
          <>
            <Route path="/common/folders" element={<Folders />} />
            <Route
              path="/common/folder_info/:folderId"
              element={<FolderDetail />}
            />
            <Route
              path="/common/folder_default_info/:folderId"
              element={<FolderDefault />}
            />
          </>
          <>
            <Route path="/common/teams" element={<TeamDealer />} />
            <Route
              path="/common/team_info/:teamId"
              element={<TeamDealerDetail />}
            />
          </>
          <>
            <Route
              path="/common/event_schedules"
              element={<EventSchedules />}
            />
            <Route
              path="/common/event_schedules_detail/:eventId"
              element={<EventSchedulesDetail />}
            />
          </>
          <>
            <Route path="/video_library_search" element={<VideoLibrary />} />
            <Route
              path="/video_library/:videoLibraryId"
              element={<VideoLibraryNew />}
            />
          </>
          <>
            <Route
              path="/common/email_templates_search"
              element={<EmailTemplates />}
            />
            <Route
              path="/common/email_templates/:templateId"
              element={<Templates />}
            />
            <Route path="/common/image_templates" element={<ImageTemplate />} />
          </>
          <>
            <Route path="/common/goals" element={<GoalModulePage />} />
          </>
          <>
            <Route
              path="/common/reports_schedule_list"
              element={<ReportsSchedule />}
            />
            <Route
              path="/common/reports_schedule/:reportScheduleId"
              element={<AddReportsSchedule />}
            />
          </>
          {(permission?.is_staff || permission?.is_reseller) && (
            <>
              {/* globalSettings */}
              <>
                <Route
                  path="/common/general_customize_menu"
                  element={<GeneralCustomizeMenu />}
                />
              </>
              <>
                <Route path="/company_search" element={<DealershipsPage />} />
                <Route
                  path="/common/company/:dealerId"
                  element={<DealershipsSettingsPage />}
                />
                <Route
                  path="/common/company_integration/:integrationId"
                  element={<Integrations />}
                />
              </>
            </>
          )}
          {permission?.is_staff && (
            <>
              {/* globalSettings */}
              <>
                <Route
                  path="/common/reporting_structure"
                  element={<EnterpriseReportingStructure />}
                />
                <Route
                  path="/common/structure/:structureId"
                  element={<StructureAddEdit />}
                />
              </>
              <>
                <Route path="/image_search" element={<ImageSearch />} />
                <Route path="/image_new" element={<ImageUpload />} />
              </>
              <>
                <Route path="/listing_search" element={<ListSearch />} />
                <Route
                  path="/common/listing/:listingId"
                  element={<NewItem />}
                />
              </>
              <>
                <Route
                  path="/notification_search"
                  element={<Notifications />}
                />
                <Route
                  path="/common/notification/:notificationId"
                  element={<NotificationNew />}
                />
              </>
              <>
                <Route
                  path="/pages_descriptions"
                  element={<PagesDescriptions />}
                />
                <Route
                  path="/common/pages_descriptions/:pageId"
                  element={<PageDescriptionEdit />}
                />
              </>
              <>
                <Route path="/common/white_labels" element={<WhiteLabel />} />
                <Route
                  path="/common/white_label_info/:typeId"
                  element={<WhiteLabelDetail />}
                />
              </>
              <>
                <Route path="/survey/survey_search" element={<SurveyList />} />
                <Route
                  path="/survey/survey_detail/:surveyId"
                  element={<SurveyDetail />}
                />
              </>
              <>
                <Route
                  path="/survey/survey_result"
                  element={<SurveyResult />}
                />
                <Route
                  path="/survey/survey_result_detail/:userSurveyId"
                  element={<SurveyResultDetail />}
                />
              </>
              <>
                <Route
                  path="/survey/survey_assign"
                  element={<SurveyAssign />}
                />
                <Route
                  path="/survey/survey_assign_detail/:surveyAssignId"
                  element={<SurveyAssignEdit />}
                />
              </>
              <>
                <Route path="/log/message" element={<MessageLog />} />
              </>
              <>
                <Route path="/templates/todo" element={<TodoTemplates />} />
                <Route
                  path="/templates/todo_edit/:todoId"
                  element={<TodoTemplatesAddEdit />}
                />
              </>
            </>
          )}
        </>
      )}
      <>
        {/* call remainder */}
        <Route
          path="/common/call_reminder/:reminderId"
          element={<NewReminderCall />}
        />
        <Route
          path="/common/call_reminder_search"
          element={<ViewReminderCall />}
        />
      </>
      {/* user */}
      <>
        <Route path="/common/employee/:empID/*" element={<ProfilePage />} />
        <Route
          path="/common/employee_integration_edit/:employeeIntegrationId"
          element={<IntegrationsUserNew />}
        />
        <Route
          path="/common/employee_notification_edit/:employeeNotificationId"
          element={<NotificationsUserNew />}
        />
      </>
      <Route path="*" element={<Page404 error={""} />} />
      {/* On Testing */}
      {permission && permission.is_staff && <></>}
    </Routes>
  );
};
export default Routs;
